import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/core/services';
import { CustomersService, DocumentsService, OrdersService } from '@app/services';
import { InvoicesService } from '@app/services/invoices.service';
import { Observable, lastValueFrom } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { DiscountComponent, PrintComponent, PermisoAdminComponent } from '@app/shared';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  orderId: number;
  tableId: number;
  tableText: string;
}

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  orden$: Observable<any>;
  invoice$: Observable<any>;
  dialog$: Observable<any>;
  customers$: Observable<any>;
  discount$!: Observable<any>;

  tableId: number = 0;
  orderId: number = 0;
  tableText: string = '';
  taxes: any[]=[];
  customers: any[]=[];
  detail: any[]=[];
  documents: any[]=[];
  currency: string = '';
  tipoPago: number = 0;
  imprimir: number =0;
  subTotal: number = 0;
  loading: boolean = false;
  pago: string = '0';
  pagoValue: number = 0;
  valTemp: number = 0;
  lastClick: number = 1;
  discAM: boolean = false;
  tipoDisc: number = 0;
  subTotalLine: number = 0;
  totalAllowDisc: number = 0;
  discAMPorc: number = 0;
  companyId: string = '';
  documentId: number = 0;

  constructor(    
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private ordersService: OrdersService,
    private invoicesService: InvoicesService,
    private stickerDialog: MatDialog,
    private authService: AuthService,
    private customersService: CustomersService,
    private documentsService: DocumentsService,
    private dialogRef: MatDialogRef<InvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.currency = this.authService.getUserProfile().currency;
    this.discAMPorc = this.authService.getUserProfile().adultoMayor/100;
    this.companyId = this.authService.getUserProfile().companyId;
    this.orderId = this.data.orderId;
    this.tableId = this.data.tableId;

    this.loadOrder(this.orderId, this.tableId);

    this.customers$ = this.invoiceForm.get('taxId').valueChanges.pipe(
      startWith(''),
      map((x: any) => {if (x.length >= 3) {return x;} else {return '';}}),
      debounceTime(400),
      switchMap(val => {
        if (val.length < 3) {return [];}
        return this.filter(val || '')
      }) 
    )
  }

  filter(val: string): Observable<any[]> {
    this.loading = true;
    return this.customersService.getCustomersTAX(this.companyId, val).pipe(
       map((res: any) => {
        this.loading = false;
        if (res.Status == 200){
          if (res.Data.length == 1){
            this.invoiceForm.patchValue({nombre: res.Data[0].NAME, direccion: res.Data[0].ADDRESS});
            return res.Data;
          } 
          if (res.Data.length > 1) {
            this.invoiceForm.patchValue({nombre: '', direccion: ''});
            return res.Data;
          }
          if (res.Data.length == 0){
            return [];
          }
        }
        this.invoiceForm.patchValue({nombre: '', direccion: ''});
        return [];
       })
     );
  }

  invoiceForm = this.fb.group({
    ordenId: '',
    totalTax: 0,
    invoiceId: 0,
    totalAmount: 0,
    tableNo: ['',[Validators.required]],
    status: '',
    orderDate: '',
    totalDiscount: 0,
    totalDisc: 0,
    totalDiscAM: 0,
    sequence: '',
    tiempoReal: '',
    taxId:'',
    nombre:'',
    direccion:'',
    propina:'',
    autorizacion: ['',[Validators.maxLength(12), Validators.minLength(4)]],
    ldigits: ['',[Validators.maxLength(4), Validators.minLength(4)]],
    tipoPago: '',
    detail: this.fb.array([])
  });

  get fOrden(){ 
    return this.invoiceForm.controls;
  }

  loadOrder(orderId:any, tableId:any){
    this.orden$ = this.ordersService.getOrder(orderId, tableId).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.invoiceForm.patchValue({
            ordenId: res.Data.orderId,
            totalTax: res.Data.totalTax,
            totalAmount: res.Data.totalAmount,
            tableNo: res.Data.tableNo,
            status: res.Data.status,
            orderDate: res.Data.orderDate,
            totalDiscount: res.Data.totalDiscount,
            totalDisc: 0,
            totalDiscAM: 0,
            sequence: res.Data.sequence,
            tiempoReal: res.Data.tiempoReal
          })
          this.fDetalle().clear();
          this.subTotalLine = 0;
          this.subTotal = +res.Data.totalAmount - +res.Data.totalTax;
          this.taxes = res.Data.taxes;
          this.tableText = res.Data.tableNo;
          this.detail = res.Data.detail;
          this.setDetalle(res.Data.detail);
          return res.Data;
        }
      }),
      switchMap(x => this.documentsService.getDocumentsType(this.companyId, 'Ventas').pipe(
        map((res: any) => {
          if (res.Status == 200){
            this.documents = res.Data;
            return res.Data;
          }
        })
      )),
    );
  }

  fDetalle() {
    return this.invoiceForm.get('detail') as FormArray;
  }

  setDetalle(lineas: any){
    const formArray = <any>[];
    lineas.forEach((det: any) => {
        this.fDetalle().push(this.fb.group({
          lineNo: det.lineNo,
          productId: det.productId,
          qty: det.qty,
          unitPrice: det.unitPrice,
          amount: det.amount,
          taxId: det.taxId,
          status: det.status,
          product: det.product,
          itemNuevo: det.itemNuevo,
          tax: det.tax,
          allowDisc: det.allowDisc
          })
        );
        this.subTotalLine = this.subTotalLine + det.amount;
        if (det.allowDisc) { this.totalAllowDisc += det.amount };
    });
  }

  onSubmit(){
    if (this.documentId == 0){
      this._snackBar.open('Debe seleccionar un tipo de documento válido', 'Cancelar', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 4000,
        panelClass: ['cancel']
      });
      return;
    }
    if (this.invoiceForm.value.taxId == ''){
      this._snackBar.open('Debe ingresar un cliente válido', 'Cancelar', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 4000,
        panelClass: ['cancel']
      });
      return;
    }
    var detalle = [];
    this.fDetalle().value.forEach((res: any) => {
      let data = {
        productId: res.productId,
        qty: res.qty,
        unitPrice: res.unitPrice,
        taxId: res.taxId,
        comments: '',
        status: res.status,
        newItem: res.itemNuevo,
        amount: res.amount
      }
      detalle.push(data);
    });
    let frmData = {
      companyId: this.companyId,
      documentId: this.documentId,
      taxId: this.invoiceForm.value.taxId,
      nombre: this.invoiceForm.value.nombre,
      direccion: this.invoiceForm.value.direccion,
      orderId: this.orderId,
      totalTax: this.invoiceForm.value.totalTax,
      totalAmount: this.invoiceForm.value.totalAmount,
      totalDiscount: this.invoiceForm.value.totalDiscount,
      autorizacion: this.invoiceForm.value.autorizacion,
      ldigits: this.invoiceForm.value.ldigits,
      tip: (this.invoiceForm.value.propina == '' ? 0 : this.invoiceForm.value.propina),
      tipoPago: this.tipoPago,
      tableId: this.tableId,
	    comment: "",
      detail: detalle,
      taxes: this.taxes
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width ='100%';
    dialogConfig.minWidth = '100%';
    dialogConfig.maxWidth = '100%';
    dialogConfig.height ='100%';
    dialogConfig.minHeight ='100%';
    let exito: string = 'Factura emitida con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    this.invoice$ = this.invoicesService.postInvoice(frmData).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.invoiceForm.patchValue({
            invoiceId: res.InvoiceId
          });
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
          dialogConfig.data = {orderId: res.InvoiceId, recibo: 0};
          this.stickerDialog.open(PrintComponent, dialogConfig);
          this.imprimir = 1;
          this.dialogRef.close({result: 200});
        } else if (res.Status == 401) {
          this._snackBar.open('Autorización de documentos vencida', 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
        return res.Data;
      })
    );
  }

  setDiscVal(){
    if (this.discAM){
      this.tipoDisc = 0;
      this.invoiceForm.patchValue({totalDisc: 0});
    }
    this.recalcularDisc();
  }

  async recalcularDisc(){
    var newDato = false;
    if (this.tipoDisc == 1 || this.tipoDisc == 2){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.data = {orderId: this.orderId};
      dialogConfig.width = '400px';
      dialogConfig.maxWidth = '400px';
      dialogConfig.height = '270px';
      const dialogRef = this.stickerDialog.open(PermisoAdminComponent, dialogConfig);
      await lastValueFrom(dialogRef.afterClosed().pipe(
        map((res: any) => {
          if(res != undefined){
            if(res.isAdmin == 1){
              if (this.tipoDisc == 1){
                this.discAM = false;
                this.invoiceForm.patchValue({totalDiscAM: 0});
                if (this.invoiceForm.value.totalDisc > 0){
                  if (this.totalAllowDisc >= Number(this.invoiceForm.value.totalDisc)) {
                    this.invoiceForm.patchValue({totalDiscount: Number(this.invoiceForm.value.totalDisc)});
                    newDato = true;
                  }
                }
              }
              if (this.tipoDisc == 2){
                this.discAM = false;
                this.invoiceForm.patchValue({totalDiscAM: 0});
                if (this.invoiceForm.value.totalDisc > 0){
                  if (this.totalAllowDisc >= Number(this.totalAllowDisc*(this.invoiceForm.value.totalDisc/100))) {
                    this.invoiceForm.patchValue({totalDiscount: Number(this.totalAllowDisc*(this.invoiceForm.value.totalDisc/100))});
                    newDato = true;
                  }
                }
              }
            }
          }
        })
      ));
    }
    
    if (this.discAM){
      this.tipoDisc = 0;
      this.invoiceForm.patchValue({totalDisc: 0});
      if (this.invoiceForm.value.totalDiscAM > 0) {
        if (this.totalAllowDisc >= Number(this.invoiceForm.value.totalDiscAM*this.discAMPorc)) {
          this.invoiceForm.patchValue({totalDiscount: Number(this.invoiceForm.value.totalDiscAM*this.discAMPorc)});
          newDato = true;
        }
      }
    }

    if (this.invoiceForm.value.totalDiscount > 0 && newDato){
      this.discount$ = this.ordersService.postDiscount(this.data.orderId, this.invoiceForm.value.totalDiscount).pipe(
        map((res : any)=>{
          if(res.Status == 200){
            this.invoiceForm.patchValue({totalAmount: res.TotalAmount, totalTax: res.Data.map(x => x.taxAmount).reduce((accumulator, currentValue) => accumulator + currentValue, 0)});
            this.subTotal = res.Data.map(x => x.baseAmount).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            this.taxes = res.Data;
            return res.Data;
          }
        })
      );
    }
  }

  minus(){
    this.valTemp = (this.valTemp == 0 ? Number(this.pago) : this.valTemp - Number(this.pago));
    this.pago = '0';
    this.pagoValue = 0;
    this.lastClick = 0;
  }

  add(){
    this.valTemp = this.valTemp + Number(this.pago);
    this.pago = '0';
    this.pagoValue = 0;
    this.lastClick = 1;
  }

  result(){
    if (this.lastClick == 1) { this.valTemp = this.valTemp + Number(this.pago); }
    if (this.lastClick == 0) { this.valTemp = this.valTemp - Number(this.pago); }
    this.pago = this.valTemp.toString();
    this.pagoValue = Number(this.valTemp);
    this.valTemp = 0;
    this.lastClick = 1;
  }

  addDot(){
    if (this.pago.indexOf('.') < 0){
      this.pago = this.pago + '.';
      this.pagoValue = Number(this.pago);
    }
  }
  
  addDigit(val: number){
    if (this.pago == '0') { this.pago = ''; this.pagoValue = 0; }
    this.pago = this.pago.toString() + val.toString();
    this.pagoValue = Number(this.pago);
  }

  closedMod(){
    this.dialogRef.close({res: 0});
  }
}
