import { Injectable } from '@angular/core';
import { Router } from '@angular/router';  
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';  
import { SpinnerComponent } from '@shared/spinner/spinner.component';
import { AuthService } from '@app/core/services';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  lTexto: string = 'Cargando...';
  pTexto: string = 'Procesando...';
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  start(tMessage: string='C'): MatDialogRef<SpinnerComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {message: (tMessage == 'C' ? this.lTexto : this.pTexto)};
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "loading-dialog";
    const dialogRef = this.dialog.open(SpinnerComponent, dialogConfig);
    return dialogRef;  
  };  

  stop(ref:MatDialogRef<SpinnerComponent>){  
      ref.close();  
  } 
}
