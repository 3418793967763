import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CompaniesService, ProductsService } from '@app/services';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CustomCantComponent } from '@app/shared';
import { AuthService } from '@app/core/services';

@Component({
  selector: 'app-product-chip',
  templateUrl: './product-chip.component.html',
  styleUrls: ['./product-chip.component.scss']
})
export class ProductchipComponent implements OnInit {
  @Output() product = new EventEmitter<any>();
  dialog$: Observable<any>;
  productos$: Observable<any>;

  productos: any[]=[];

  cantidad: any[]=[1,2,3,4,5,6,7,8,9,];
  cantSel: number = 1;
  cantPer: number = 10;

  color1: string= '#32a852';
  color2: string= '#329ea8';
  companyId: string = '';

  constructor(
    private productsService: ProductsService,
    private stickerDialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private companyService: CompaniesService
    ) {
      this.matIconRegistry.addSvgIcon(
        "robot",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/images/robot.svg")
      );
     }

  ngOnInit(): void {
    this.companyId = this.authService.getUserProfile().companyId;
    this.loadProductos();
  }

  loadProductos (){
    this.productos$ = this.productsService.getActiveProducts(this.companyId).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.productos = res.Data;
        }
      }),
      switchMap((x: any) => x = this.companyService.getActiveCategories(this.companyId)),
        map((res: any) => {
          if (res.Status == 200){
            let informacion: any[]=[];
            res.Data.forEach(e => {
              informacion.push({
                categoria: e.DESCRIPTION,
                color: e.COLOR,
                productos: this.productos.filter(x => x.CATEGORIA == e.DESCRIPTION)
              })
            });
            return informacion;
          }
        }
      ),
    );
  }

  getDisabled(inv:any, qty:any){
    if(inv == true && qty == 0){
      return true;
    }else{
      return false;
    }
  }

  agregarProducto(producto:any){
    producto.CANTIDAD = this.cantSel;
    if(producto.INVENTORY == true && producto.QTY != 0){
    }else if(producto.INVENTORY == false){
      this.product.emit(producto);
    }
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width ='385px';
    dialogConfig.minWidth = '385px';
    dialogConfig.maxWidth = '385px';
    dialogConfig.height ='250px';
    dialogConfig.minHeight ='250px';
    const dialogRef = this.stickerDialog.open(CustomCantComponent, dialogConfig);
    this.dialog$ = dialogRef.afterClosed().pipe(
      map((res: any) => {
        if(res != undefined){
          this.cantSel = res.res;
        }
      })
    );
  }
  
}
