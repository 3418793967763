import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services';
import { SpinnerService } from '@app/shared';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userName: string = '';
  password: string = '';
  error: string = '';

  returnUrl: string;
  validCode: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {
    // redirect to home if already logged in
    if (this.authService.currentUserValue) { 
      this.router.navigate(['/']);
    }
   }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  loginUser(){
    this.validCode = 0;
    this.error = '';
    var spinnerRef = this.spinnerService.start();
    this.authService.login(this.userName, this.password)
      .pipe(first())
      .subscribe(
          (data: any) => {
            this.spinnerService.stop(spinnerRef);
            if (data.Status == 200 && data.Data.length > 0){
              this.router.navigate([this.returnUrl]);
              return;
            } 
            if (data.Data.length == 0) {
              this.validCode = 1;
              this.error = 'Datos inválidos';
              return;
            }
          },
          error => {
            this.spinnerService.stop(spinnerRef);
            this.error = error.Message;
          });
  }

}
