import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  readonly apiURL = environment.apiUrl;
  constructor(private http: HttpClient) { }
  
  postInvoice(frmData:any){
    return this.http.post(this.apiURL + 'invoice', frmData)
                    .pipe(catchError(this.errorHandler));
  }

  getInvoice(invoiceId:any){
    return this.http.get(this.apiURL + 'invoice/'+invoiceId)
                    .pipe(catchError(this.errorHandler));
  }

  getInvoices(texto: string, offset: number, limit: number, startDate: string, endDate: string){
    return this.http.get(this.apiURL + 'invoices/' + texto + '/' + offset + '/' + limit + '/' + startDate + '/' + endDate)
                    .pipe(catchError(this.errorHandler));
  }

  updateInvoice(invoideId:number, frmData:any){
    return this.http.put(this.apiURL + 'invoice/'+invoideId, frmData)
                    .pipe(catchError(this.errorHandler));
  }

  printInvoice(invoiceId: any){
    return this.http.post(this.apiURL + 'print/invoice', {invoiceId: invoiceId})
                    .pipe(catchError(this.errorHandler));
  }
  
  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
}
