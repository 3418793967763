<div class="container">
    <div class="label-hora">Hora {{hora | date:'shortTime'}}</div>
    <ng-container *ngIf="cocina$ | async"></ng-container>
    <div class="main-content">
        <div class="order-detail" *ngFor="let data of orders">
            <!-- <span class="label">Orden</span> {{data.ORDER_ID}}
            <br> -->
            <span class="label">Mesa - </span><span class="data-order">{{data.ITABLE}}</span>
            <br>
            <span class="label">Orden - </span><span class="data-order">{{data.SEQUENCE.toString().padStart(5, '0')}}</span>
            <br>
            <span class="label">Hora - </span><span class="data-order">{{data.ORDER_DATE}}</span>
            <br>
            <span class="label">Cliente - </span><span class="data-order">{{data.CUSTOMER_NAME}}</span>
            <br>
            <div class="spacer-h"></div>
            <ng-container *ngFor="let item of cocina | groupByCat:data.ORDER_ID">
                <div class="detail-w">
                    <span class="data-order">{{item.CANTIDAD + '  ' + item.PROD}}</span>
                    <div class="" *ngIf="item.COMMENTS">
                        <span class="nota">{{item.COMMENTS}}</span>
                    </div>
                </div>
            </ng-container>
            <div class="spacer-h"></div>
            <div class="spacer"></div>
            <br>
        </div>      
    </div>
</div>