import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvoicesService } from '@app/services';
import { PrintComponent, SpinnerService } from '@app/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoicedataComponent } from '@app/modules';
import { AuthService } from '@app/core/services';

@Component({
  selector: 'app-invoiceslist',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})
export class InvoiceslistComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;

  invoices$: Observable<any>;
  dialog$: Observable<any>;

  user: any;
  currency: string = '';

  lengthTabla: number = -1;
  pageSize: number = 5;
  _page: number = 0;
  texto: string = "";

  startDate: Date;
  endDate: Date;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  displayedColumns: string[] = ['INVOICE_NUMBER', 'TAX_ID', 'NAME', 'DATE', 'TOTAL_AMOUNT', 'actions'];

  constructor(
    private invoicesService: InvoicesService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private stickerDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserProfile();
    this.currency = this.user.currency;
    this.loadData(0,'0');
  }

  loadData(curPage:number, texto:string){
    let sDate, eDate: string;
    if (this.startDate != null) {
      sDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+1).toString().padStart(2, '0') + '-' + (this.startDate.getDate()).toString().padStart(2, '0');
    } else {
      sDate = '0';
    }
    if (this.endDate != null) {
      eDate = this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+1).toString().padStart(2, '0') + '-' + (this.endDate.getDate()).toString().padStart(2, '0');
    } else {
      eDate = '0';
    }
    var spinnerRef = this.spinnerService.start();
    this.invoices$ = this.invoicesService.getInvoices(texto, curPage, this.pageSize, sDate, eDate).pipe(
      map((res:any)=>{
        this.spinnerService.stop(spinnerRef);
        if(res.Status == 200){
          this.dataSource.data = res.Data;
          this.dataSource.sort = this.sort;
          this.lengthTabla = res.Data[0].TOTAL;
        }
        else {
          this.dataSource.data = null;
          this.dataSource.sort = this.sort;
          this.lengthTabla = 0;
        }
      })
    )
  }

  goToPage(page: number, elements: number){
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    if(this.texto == ''){
      var texto = '0';
    }else{
      var texto = this.texto;
    }
    this.loadData((this._page == 0 ? 0 : this._page*this.pageSize),texto);
  }

  printInvoice(invoiceId:number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {orderId: invoiceId, recibo: 0};
    dialogConfig.width ='100%';
    dialogConfig.minWidth = '100%';
    dialogConfig.maxWidth = '100%';
    dialogConfig.height ='100%';
    dialogConfig.minHeight ='100%';
    this.stickerDialog.open(PrintComponent, dialogConfig);
  }

  updateInvoice(invoiceId: string){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {invoiceId: invoiceId};
    dialogConfig.width ='420px';
    dialogConfig.height ='310px';
    let dialogRef = this.stickerDialog.open(InvoicedataComponent, dialogConfig);
    this.dialog$ = dialogRef.afterClosed().pipe(
      map((res: any) => {
        if (res != undefined){
          let data = this.dataSource.data.filter(x=>x.INVOICE_ID == invoiceId);
          data[0].TAX_ID = res.taxID;
          data[0].NAME = res.nombre;
        }
      })
    );
  }

  searchData(){
    this.loadData(0, (this.texto == '' ? '0' : this.texto));
  }

  refreshSearch(){
    this.startDate = undefined;
    this.endDate = undefined;
    this.texto = '';
    this.loadData(0, '0');
  }
}
