
<div class="container">
    <div class="row">
        <div class="col-md-12 text-center mt-4">
            <h2 >Ingrese Cantidad</h2>
        </div>
    </div>
    <div class="row justify-content-center">
        <mat-form-field class="cant-form me-1" appearance="outline">
            <input #cant matInput maxlength="2" class="input-nblink" [(ngModel)]="cantPer" (keyup)="keyup($event)" (keydown)="keydown($event)" (change)="valueChange($event)" (keypress)="numberOnly($event)" autocomplete="off">
        </mat-form-field>
        <div class="col-auto updown-h">
            <div class="row">
                <button mat-icon-button (click)="keyupClick()"><mat-icon>expand_less</mat-icon></button>
            </div>
            <div class="row">
                <button mat-icon-button (click)="keydownClick()" ><mat-icon>expand_more</mat-icon></button>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <button mat-flat-button color="accent" (click)="setCantidad()">Guardar</button>
        </div>
    </div>
</div>


