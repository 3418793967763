import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@app/services';
import { Observable, Subject, timer } from 'rxjs';
import { map, retry, share, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cocina',
  templateUrl: './cocina.component.html',
  styleUrls: ['./cocina.component.scss']
})
export class CocinaComponent implements OnInit {
  private stopPolling = new Subject();
  cocina$: Observable<any>;
  pendientes: any[]=[];
  cocina: any[]=[];
  detail: any[]=[];
  orders: any=[];
  
  order: number = 0;
  table: string = '';
  hora: Date;
  constructor(
    private global: GlobalService
  ) {
    setInterval(() => {
      this.hora = new Date();
    }, 1);
   }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    // this.cocina$ = timer(1, 10000).pipe(
    //   switchMap(() => this.global.GetCocina().pipe(
    //     map((res: any) => {
    //       if (res.Status == 200){
    //         this.cocina = [];
    //         this.orders = [];
    //         if (res.Data.length > 0){
    //           this.cocina = res.Data;
    //           this.orders = [...new Map(this.cocina.map(item =>
    //             [item['ORDER_ID'], item])).values()];
    //           this.detail = this.cocina.filter(x => x.ORDER_ID == this.order);
    //         }
    //         return res.Data;
    //       }
    //     })
    //     )
    //   ),
    //   retry(),
    //   share(),
    //   takeUntil(this.stopPolling)
    // );
  }

  ngOnDestroy() {
    this.stopPolling.next('');
  }

}
