import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@app/core/services';
import { CustomersService, InvoicesService } from '@app/services';
import { SpinnerService } from '@app/shared';
import { Observable } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';

export interface updateData {
  invoiceId: number;
}

@Component({
  selector: 'app-invoice-data',
  templateUrl: './invoice-data.component.html',
  styleUrls: ['./invoice-data.component.scss']
})
export class InvoicedataComponent implements OnInit {
  invoice$: Observable<any>
  customers$: Observable<any>;
  customers: any[]=[];
  invoiceId: number;
  loading: boolean = false;

  companyId: string = '';

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private invoiceService: InvoicesService,
    private customersService: CustomersService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<InvoicedataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: updateData
  ) { }

  ngOnInit(): void {
    this.invoiceId = this.data.invoiceId;
    this.companyId = this.authService.getUserProfile().companyId;

    this.customers$ = this.invoiceForm.get('rtn').valueChanges.pipe(
      startWith(''),
      map((x: any) => {if (x.length >= 3) {return x;} else {return '';}}),
      debounceTime(400),
      switchMap(val => {
        if (val.length < 3) {return [];}
        return this.filter(val || '')
      }) 
    )
  }

  filter(val: string): Observable<any[]> {
    this.loading = true;
    return this.customersService.getCustomersTAX(this.companyId, val).pipe(
       map((res: any) => {
        this.loading = false;
        if (res.Status == 200){
          if (res.Data.length == 1){
            this.invoiceForm.patchValue({nombre: res.Data[0].NAME, direccion: res.Data[0].ADDRESS});
            return res.Data;
          } 
          if (res.Data.length > 1) {
            this.invoiceForm.patchValue({nombre: '', direccion: ''});
            return res.Data;
          }
          if (res.Data.length == 0){
            return [];
          }
        }
        this.invoiceForm.patchValue({nombre: '', direccion: ''});
        return [];
       })
     );
  }

  invoiceForm = this.fb.group({
    invoiceId: [''],
    rtn: [''],
    nombre: [''],
    direccion: [''],
  });

  onSubmit(){
    let frmData = {
      rtn: this.invoiceForm.get('rtn').value,
      nombre: this.invoiceForm.get('nombre').value,
      direccion: this.invoiceForm.get('direccion').value
    }
    let exito: string = 'Factura actualizada con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.invoice$ = this.invoiceService.updateInvoice(this.invoiceId, frmData).pipe(
      map((res:any)=>{
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
          this.dialogRef.close(frmData);
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    )
  }
}
