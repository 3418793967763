import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@modules/authentication/auth.guard';

import { CocinaComponent, EntregasComponent, DespachoComponent, CompanyComponent, OrderslistComponent, LoginComponent, MainComponent, WelcomeComponent, CierreComponent, PrintInvoiceComponent, DetailComponent, UserslistComponent, UserComponent, ReportsComponent, ProductsComponent, IndicadoresComponent, InvoiceslistComponent, InvoicedataComponent, OrdersComponent, InvoiceComponent, CategoriesComponent, InventoryComponent, CustomersComponent } from '@modules/index';
import { ProductchipComponent, PermisoAdminComponent, PrintComponent, DiscountComponent, CustomCantComponent, SpinnerComponent, SearchComponent } from '@shared/index';
const routes: Routes = [
  {
    path: '', component: MainComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/welcome', pathMatch: 'full'},
      { path: 'welcome', component: WelcomeComponent, canActivate: [AuthGuard] },
      { path: 'company', component: CompanyComponent, canActivate: [AuthGuard] },
      { path: 'orders/list', component: OrderslistComponent, canActivate: [AuthGuard] },
      { path: 'cierre', component: CierreComponent, canActivate: [AuthGuard] },
      { path: 'user/:userId', component: UserComponent, canActivate: [AuthGuard] },
      { path: 'users/list', component: UserslistComponent, canActivate: [AuthGuard] },
      { path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard] },
      { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard] },
      { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },
      { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
      // { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
      { path: 'invoices', component: InvoiceslistComponent, canActivate: [AuthGuard] },
      { path: 'report/sales/:tipo', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'report/product/:tipo', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'report/inventory/:tipo', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'report/customer/:tipo', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'report/taxes/:tipo', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'report/register/:tipo', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'indicadores', component: IndicadoresComponent, canActivate: [AuthGuard] },
      // { path: 'gasto/:gastoId', component: GastoComponent, canActivate: [AuthGuard] },
      // { path: 'gastos/list', component: GastolistComponent, canActivate: [AuthGuard] },
      { path: 'invoices/list', component: InvoiceslistComponent, canActivate: [AuthGuard] }
    ]
  },
  { 
    path: 'orders', 
    component: OrdersComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'cocina',
    component: CocinaComponent
  },
  {
    path: 'entregas',
    component: EntregasComponent
  },
  {
    path: 'despacho',
    component: DespachoComponent
  },
  {
    path: 'login',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
  CocinaComponent,
  DespachoComponent,
  CompanyComponent,
  EntregasComponent,
  LoginComponent,
  WelcomeComponent,
  CierreComponent,
  MainComponent,
  OrdersComponent,
  OrderslistComponent,
  ProductsComponent,
  InvoiceComponent,
  PermisoAdminComponent,
  PrintInvoiceComponent,
  DetailComponent,
  PrintComponent,
  UserslistComponent,
  UserComponent,
  DiscountComponent,
  CustomCantComponent,
  ReportsComponent,
  IndicadoresComponent,
  InvoiceslistComponent,
  InvoicedataComponent,
  ProductchipComponent,
  SpinnerComponent,
  CategoriesComponent,
  InventoryComponent,
  SearchComponent,
  CustomersComponent
]