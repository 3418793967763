<div class="container">
    <div class="row mb-4 align-items-center">
        <div class="col-md-7">
            <div class="header">
                Reportes
            </div>
        </div>
        <div class="col-md-4">
            <div class="row">
                <form [formGroup]="reportForm">
                    <mat-form-field appearance="standard" class="col-md-12">
                        <mat-label>Buscar Factura</mat-label>
                        <input matInput formControlName="rtn" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getInvoice($event.option.value)" [displayWith]="displayFn">
                            <mat-option class="mat-invoices" *ngFor="let invoice of invoices" [value]="invoice">
                                <div>
                                    <b>RTN:{{invoice.RTN}}</b>
                                </div>
                                <div>
                                    <b>NOMBRE:{{invoice.NAME}}</b>
                                </div>
                                <div>
                                    NUMERO: {{invoice.NUMBER}}
                                </div>
                                <div>
                                    FECHA: {{invoice.DATE |date: 'shortDate'}}
                                </div>
                           </mat-option>
                       </mat-autocomplete>
                    </mat-form-field>                   
                </form>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <mat-form-field appearance="legacy">
                <mat-label>Seleccione Fecha</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="dateCierre" (dateChange)="changeDateP($event.value)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-9">
            <!-- <button mat-flat-button (click)="exportexcel()" class="exportar" color="primary">Excel</button> -->
            <button mat-flat-button (click)="loadData(0)" class="btn-tipo mx-2" [ngClass]="{'btn-tipo-activo': tipo == 0}"><mat-icon matTooltip="Productos">category</mat-icon></button>
            <button mat-flat-button (click)="loadData(1)" class="btn-tipo mx-2" [ngClass]="{'btn-tipo-activo': tipo == 1}"><mat-icon matTooltip="Facturas">price_check</mat-icon></button>
            <button mat-flat-button (click)="loadData(2)" class="btn-tipo mx-2" [ngClass]="{'btn-tipo-activo': tipo == 2}"><mat-icon matTooltip="Ordenes">list_alt</mat-icon></button>

        </div>
    </div>
    <ng-container *ngIf="cierre$ | async"></ng-container>
    <div class="mat-elevation-z8">
        <section class="sticky-mnu">
            <table mat-table [dataSource]="dataSource" id="excel-table" matSort [trackBy]="trackRow">
                <ng-container [matColumnDef]="hd.codigo" *ngFor="let hd of headers">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-md text-center">{{hd.header}}</th>
                    <td mat-cell *matCellDef="let row" class="col-md">{{ convertirTexto(hd.codigo, row[hd.codigo])}}</td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </section>
        <mat-toolbar class="col-back">
            <div style="margin-left:auto"></div>
            <button mat-icon-button color="primary" class="me-3" matTooltip="Exportar a XLSX" (click)="exportexcel()"><mat-icon class="material-icons-outlined">file_download</mat-icon></button>     
            <mat-paginator 
                [length]="length"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="goToPage($event.pageIndex, $event.pageSize)"  
                showFirstLastButtons>
            </mat-paginator>
        </mat-toolbar>
    </div>
</div>