<ng-container *ngIf="user$ | async"></ng-container>
<div class="container">
    <mat-card>
        <mat-card-title class="title">
            <div class="card-icon ms-3">
                <mat-icon>
                    person
                </mat-icon>
            </div>
            <span class="text-title">Usuario</span>
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="userForm">
                <div class="row">
                    <mat-form-field class="col-md-6" appearance="outline">
                        <mat-label>Usuario</mat-label>
                        <input  matInput formControlName="usuario">
                        <mat-error *ngIf="fUser.usuario.invalid && fUser.usuario.touched">
                            {{ 'Error: ' + getErrorMessage('usuario', 'Usuario', 3, 50, 0, 0)}}
                        </mat-error>
                    </mat-form-field>
                    <div class="col-md-6" *ngIf="usuarioId != 0">
                        <mat-slide-toggle formControlName="status">
                            {{fUser.status.value == false ? 'Inactivo' : 'Activo'}}
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-6" appearance="outline">
                        <mat-label>Nombre</mat-label>
                        <input  matInput formControlName="nombre">
                    </mat-form-field>
                    <mat-form-field class="col-md-6" appearance="outline">
                        <mat-label>Apellido</mat-label>
                        <input  matInput formControlName="apellido">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-6" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" [required]="reqPswd" formControlName="password">
                        <mat-error *ngIf="fUser.password.invalid && fUser.password.touched">
                            {{ 'Error: ' + getErrorMessage('password', 'Password', 3, 50, 0, 0)}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-6" appearance="outline">
                        <mat-label>Confirmar Password</mat-label>
                        <input matInput type="password" [required]="reqPswd" formControlName="confPassword">
                        <mat-error *ngIf="fUser.confPassword.invalid && fUser.confPassword.touched">
                            {{ 'Error: ' + getErrorMessage('password', 'Password', 3, 50, 0, 0)}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-6" appearance="outline">
                        <mat-label>Rol</mat-label>
                        <mat-select formControlName="rolId">
                          <mat-option *ngFor="let rol of roles$ | async; let i=index" [value]="rol.ROL_ID" >
                            {{rol.DESCRIPCION}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="fUser.rolId.invalid && fUser.rolId.touched">
                            {{ 'Error: ' + getErrorMessage('rolId', 'Rol', 3, 50, 0, 0)}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </form>
            <div class="row justify-content-end">
                <ng-container *ngIf="userNew$ | async"></ng-container>
                <div class="col-md-auto me-2">
                    <button mat-button matTooltip="Nuevo Usuario" color="accent" (click)="newUser()">
                        <mat-icon>person_add</mat-icon>
                        Usuario
                    </button>
                </div>
                <div class="col-md-auto">
                    <button mat-flat-button color="accent" class="w-100" (click)="onSubmit()">
                      Guardar
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
