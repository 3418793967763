<div class="container-fluid px-5">
    <div class="row justify-content-center">
        <div class="card-title mt-2 px-0 col-md-10 w-100">
            <mat-icon class="icon-title">
                supervisor_account
            </mat-icon>
            <span class="text-title">Clientes</span>
        </div>
        <mat-card class="mt-0 col-md-10 w-100">
            <mat-card-content>
                <div class="row">
                    <div class="col-md-6 col-lg-5 content-refresh">
                        <mat-form-field appearance="outline">
                            <mat-label>Buscar</mat-label>
                            <input matInput placeholder="Buscar" [(ngModel)]="searchText" name="buscar">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 col-lg-auto">
                        <button mat-flat-button color="primary" class="h-40" (click)="loadCustomers(0)">
                            <mat-icon class="btn-search" matSuffix>search</mat-icon>
                            Buscar
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="customer$ | async"></ng-container>
                <ng-container *ngIf="delCustomer$ | async"></ng-container>
                <ng-container *ngIf="length > 0; else noCust">
                    <mat-card class="card-table py-0">
                        <mat-table matSort [dataSource]="dataSource">
                            <ng-container matColumnDef="NAME">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell"> CLIENTE </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.NAME}} </mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="TAX_ID">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell"> IMPUESTO ID </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.TAX_ID}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="EMAIL">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell"> CORREO </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.EMAIL}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="STATUS">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell"> ESTATUS </mat-header-cell>
                                <mat-cell *matCellDef="let row"><span [ngClass]="{'active': row.STATUS == 'Activo', 'inactive': row.STATUS == 'Inactivo'}">{{row.STATUS}}</span></mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="ACTIONS">
                                <mat-header-cell *matHeaderCellDef class="title-cell">ACCIONES</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <button mat-icon-button matTooltip="Ver Cliente" class="icon-view" (click)="changeSelected(row)"><mat-icon>dvr</mat-icon></button>
                                    <button *ngIf="row.STATUS == 'Activo'" matTooltip="Desactivar" mat-icon-button (click)="delCustomer(row.CUSTOMER_ID)" ><mat-icon color="warn" class="icon-usuario">delete_forever</mat-icon></button>
                                </mat-cell>
                            </ng-container> 
                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <mat-paginator #paginator class="mat-paginator-sticky"
                            [length]="length"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="goToPage($event.pageIndex, $event.pageSize)">
                        </mat-paginator>
                    </mat-card>
                </ng-container>
                <ng-template #noCust>
                    <ng-container *ngIf="length == 0">
                        <div class="noData text-center">No se han ingresado clientes <br> ingrese la información del cliente en el formulario que aparece abajo</div>
                    </ng-container>
                </ng-template>
                <form [formGroup]="customerForm" class="p-4 border">
                    <div class="row">
                        <div class="col-md-8 col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label #cliente>Cliente</mat-label>
                                <input matInput placeholder="Nombre del Cliente" name="cliente" formControlName="name" [errorStateMatcher]="confirmValidParentMatcher" required max="150" min="3">
                                <mat-error *ngIf="cust.name.invalid && cust.name.touched">
                                    {{ 'Error: ' + getErrorMessage(customerForm, 'name', cliente, 3, 150, 0, 0)}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-lg-2 my-0" *ngIf="cust.customerId.value != ''">
                            <mat-checkbox formControlName="status">Estatus {{cust.status.value == true ? 'Activo' : 'Inactivo'}}</mat-checkbox>
                        </div>
                    </div>                        
                    <div class="row">
                        <div class="col-md-4 col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label #tax>Impuesto Id</mat-label>
                                <input matInput placeholder="Impuesto Id" name="taxId" formControlName="taxId" [errorStateMatcher]="confirmValidParentMatcher" maxlength="50" minlength="1" required>
                                <mat-error *ngIf="cust.taxId.invalid && cust.taxId.touched">
                                    {{ 'Error: ' + getErrorMessage(customerForm, 'taxId', tax, 1, 50, 0, 0)}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-8 col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label #address>Direccion</mat-label>
                                <input matInput placeholder="Dirección" name="address" formControlName="address" maxlength="200" minlength="3">
                                <mat-error *ngIf="cust.address.invalid && cust.address.touched">
                                    {{ 'Error: ' + getErrorMessage(customerForm, 'address', address, 3, 200, 0, 0)}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-8 col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label #email>Correo electrónico</mat-label>
                                <input matInput placeholder="Correo electrónico" name="email" formControlName="email" maxlength="150" minlength="3">
                                <mat-error *ngIf="cust.email.invalid && cust.email.touched">
                                    {{ 'Error: ' + getErrorMessage(customerForm, 'email', email, 3, 150, 0, 0)}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <button mat-flat-button class="btn" id="save" type="submit" color="primary" text-center w-100 (click)="save()">Guardar</button>
                <button mat-flat-button class="btn ms-3" id="cancel" type="button" color="accent" text-center w-100 (click)="clear()">{{ cust.customerId.value == '' ? 'Nuevo' : 'Cancelar'}}</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>