import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  readonly apiURL = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getProduct(prodId: string){
    return this.http.get(this.apiURL + 'product/'+prodId)
                    .pipe(catchError(this.errorHandler));
  }

  getActiveProducts(companyId: string){
    return this.http.get(this.apiURL + 'products/' + companyId)
                    .pipe(catchError(this.errorHandler));
  }

  getProducts(companyId: string, texto:any, offset:any, limit:any){
    return this.http.get(this.apiURL + 'products/' + companyId + '/' + texto + '/' + offset + '/' + limit + '/0')
                    .pipe(catchError(this.errorHandler));
  }

  postProduct(productId: any, prod: any){
    return this.http.post(this.apiURL + 'product/' + productId, prod)
                    .pipe(catchError(this.errorHandler));
  }

  uploadImage(productId: any, formData: any){
    return this.http.post(this.apiURL + 'product/image/'+productId, formData)
                    .pipe(catchError(this.errorHandler));
  }
  
  delProduct(productId: number, frmData: any){
    return this.http.delete(this.apiURL + 'product/'+ productId, frmData)
                    .pipe(catchError(this.errorHandler));
  }
  
  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
}
