<ng-container *ngIf="dialog$ | async"></ng-container>
<ng-container *ngIf="inventory$ | async"></ng-container>
<div class="container-fluid px-5">
    <div class="row justify-content-center">
        <div class="card-title mt-2 px-0 col-md-10 w-100">
            <mat-icon class="icon-title">
                inventory_2
            </mat-icon>
            <span class="text-title">Inventarios</span>
        </div>
        <mat-card class="mt-0 col-md-10 w-100">
            <mat-card-content>
                <div class="row">
                    <div class="col-md-4 col-lg-4 content-refresh">
                        <mat-form-field appearance="outline">
                            <mat-label>Buscar</mat-label>
                            <input matInput placeholder="Buscar" [(ngModel)]="searchText" name="buscar">
                        </mat-form-field>
                    </div>
                    <div class="col-md-5 col-lg-2">
                        <mat-form-field appearance="outline" appDateFormat>
                            <mat-label>Enter a date range</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate placeholder="Start date" [(ngModel)]="sDate">
                                <input matEndDate placeholder="End date" [(ngModel)]="fDate">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                    </div>
                    <div class="col-md-3 col-lg-auto">
                        <button mat-flat-button color="primary" class="h-40" (click)="searchData()">
                            <mat-icon class="btn-search" matSuffix>search</mat-icon>
                            Buscar
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="length > 0; else noInv">
                    <mat-card class="card-table py-0">
                        <mat-table matSort [dataSource]="dataSource">
                            <ng-container matColumnDef="LOCATION" sticky>
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> LOCALIDAD </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell"> {{row.LOCATION}} </mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="PRODUCT" sticky>
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> PRODUCTO </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell"> {{row.PRODUCT}} </mat-cell>
                            </ng-container>
    
                            <ng-container matColumnDef="RACK" sticky>
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> UBICACION </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell"> {{row.RACK}} </mat-cell>
                            </ng-container>
    
                            <ng-container matColumnDef="TYPE_OP">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> OPERACION </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell"> {{getType(row.TYPE_OP)}} </mat-cell>
                            </ng-container>
    
                            <ng-container matColumnDef="IN_OUT">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> TIPO </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell"><span class="chip-type" [ngClass]="{'active': row.IN_OUT == true, 'inactive': row.IN_OUT == false}"> {{row.IN_OUT == false ? 'Salida' : 'Ingreso'}} </span></mat-cell>
                            </ng-container>
    
                            <ng-container matColumnDef="QTY">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> CANTIDAD </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell r-text"> {{row.QTY | number:'1.4-4'}} </mat-cell>
                            </ng-container>
    
                            <ng-container matColumnDef="UNIT_COST">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> COSTO UNITARIO </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell r-text"> {{currency + ' ' + (row.UNIT_COST | number:'1.4-4')}} </mat-cell>
                            </ng-container>
    
                            <ng-container matColumnDef="TOTAL_COST">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> COSTO TOTAL </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell r-text"> {{currency + ' ' + (row.TOTAL_COST | number:'1.4-4')}} </mat-cell>
                            </ng-container>
    
                            <ng-container matColumnDef="UNIT_PRICE">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> PRECIO UNITARIO </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell r-text"> {{currency + ' ' + (row.UNIT_PRICE | number:'1.2-2')}} </mat-cell>
                            </ng-container>
    
                            <ng-container matColumnDef="TRANS_DATE">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell w-date"> FECHA / HORA </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="content-cell w-date"> {{row.TRANS_DATE | date:'dd/MM/yyyy h:mm a':'UTC+0'}} </mat-cell>
                            </ng-container>
                        
                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <mat-paginator #paginator class="mat-paginator-sticky"
                            [length]="length"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="goToPage($event.pageIndex, $event.pageSize)">
                        </mat-paginator>
                    </mat-card>
                </ng-container>
                <ng-template #noInv>
                    <ng-container *ngIf="length == 0 && searchText == '' && iDate == '0' && eDate == '0'">
                        <div class="no-data text-center">No existen transacciones de inventario en este momento</div>
                    </ng-container>
                    <ng-container *ngIf="length == 0 && (searchText != '' || iDate != '0')">
                        <div class="no-data text-center">No existen transacciones de inventario según el criterio de busqueda ingresado</div>
                    </ng-container>
                </ng-template>
                <mat-tab-group dynamicHeight mat-align-tabs="start">
                    <mat-tab label="Ajustes" class="d-flex justify-content-start">
                        <form [formGroup]="invForm">
                            <div class="row">
                                <mat-form-field appearance="outline" class="col-md-8 col-lg-4">
                                    <mat-label>Producto</mat-label>
                                    <input matInput placeholder="Producto" formControlName="productName" required readonly>
                                    <button type="button" mat-icon-button matSuffix (click)="searchProduct()">
                                        <mat-icon class="icon-pointer">search</mat-icon>
                                    </button>
                                    <mat-error *ngIf="inv.productName.errors?.required">Error: El producto es requerido</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="outline" class="col-md-8 col-lg-4">
                                    <mat-label #loc>Localidad</mat-label>
                                    <mat-select formControlName="locationId" [errorStateMatcher]="confirmValidParentMatcher" required>
                                      <mat-option *ngFor="let loc of locs$ | async; let i = index" [value]="loc.LOCATION_ID">
                                        {{loc.DESCRIPTION}}
                                      </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="inv.locationId.invalid && inv.locationId.touched">
                                        {{ 'Error: ' + getErrorMessage(invForm, 'locationId', loc, 0, 0, 0, 0)}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="outline" class="col-md-8 col-lg-4">
                                    <mat-label #rack>Ubicación</mat-label>
                                    <input matInput placeholder="Ubicación" formControlName="rackId" [errorStateMatcher]="confirmValidParentMatcher" minlength="2" maxlength="100" required>
                                    <mat-error *ngIf="inv.rackId.invalid && inv.rackId.touched">
                                        {{ 'Error: ' + getErrorMessage(invForm, 'rackId', rack, 2, 100, 0, 0)}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="outline" class="col-md-7 col-lg-3">
                                    <mat-label #ope>Tipo de Operación</mat-label>
                                    <mat-select formControlName="typeOp" [errorStateMatcher]="confirmValidParentMatcher" required>
                                      <mat-option *ngFor="let type of types; let i = index" [value]="type.id" [disabled]="type.status">
                                        {{type.description}}
                                      </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="inv.typeOp.invalid && inv.typeOp.touched">
                                        {{ 'Error: ' + getErrorMessage(invForm, 'typeOp', ope, 0, 0, 0, 0)}}</mat-error>
                                </mat-form-field>
                                
                                <mat-slide-toggle formControlName="inOut" color="primary" class="col-md-5 col-lg-2 mt-2">{{inv.inOut.value == false ? 'Salida' : 'Ingreso'}}</mat-slide-toggle>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="outline" class="col-md-6 col-lg-3">
                                    <mat-label #qty>Cantidad</mat-label>
                                    <input matInput placeholder="Cantidad" formControlName="qty" [errorStateMatcher]="confirmValidParentMatcher" required>
                                    <mat-error *ngIf="inv.qty.invalid && inv.qty.touched">
                                        {{ 'Error: ' + getErrorMessage(invForm, 'qty', qty, 0, 0, 0, 0)}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-md-6 col-lg-3" *ngIf="inv.inOut.value == true">
                                    <mat-label #costo>Costo Unitario</mat-label>
                                    <input matInput placeholder="Costo Unitario" formControlName="unitCost" [errorStateMatcher]="confirmValidParentMatcher" required>
                                    <mat-error *ngIf="inv.unitCost.invalid && inv.unitCost.touched">
                                        {{ 'Error: ' + getErrorMessage(invForm, 'unitCost', costo, 0, 0, 0, 0)}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row mb-3">
                                <mat-form-field appearance="outline" class="col-md-6 col-lg-2">
                                    <mat-label #fecha>Fecha Transacción</mat-label>
                                    <input matInput name="dateIni" formControlName="transDate" [min]="minDate" [max]="maxDate" [matDatepicker]="initPicker" required>
                                    <mat-datepicker-toggle matSuffix [for]="initPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #initPicker></mat-datepicker>
                                    <mat-error *ngIf="inv.transDate.invalid && inv.transDate.touched">
                                        {{ 'Error: ' + getErrorMessage(invForm, 'transDate', fecha, 0, 0, 0, 0)}}</mat-error>
                                </mat-form-field>
                            </div>
                        </form>
                        <button mat-flat-button class="btn me-3" id="save" type="submit" color="primary" text-center w-100 (click)="saveAdj()" *ngIf="inv.transactionId.value == ''">Guardar</button>
                        <button mat-flat-button class="btn" id="cancel" type="button" color="accent" text-center w-100 (click)="clearAdj()">Cancelar</button>
                    </mat-tab>
                    <mat-tab label="Costos" class="d-flex justify-content-start">
                        <div class="container">
                            <div class="row mt-2">
                                <mat-form-field appearance="outline" class="col-md-6 col-lg-4" appYearMonthFormat>
                                    <mat-label>Fecha Transacción</mat-label>
                                    <input matInput name="costoDate" [formControl]="costoDt" [min]="minDate" [max]="maxDate" [matDatepicker]="initPickerCostos">
                                    <mat-datepicker-toggle matSuffix [for]="initPickerCostos"></mat-datepicker-toggle>
                                    <mat-datepicker #initPickerCostos
                                                    startView="multi-year"
                                                    (monthSelected)="setMonthAndYear($event, initPickerCostos)"
                                                    panelClass="costos-picker"></mat-datepicker>
                                    <!-- <mat-error *ngIf="inv.transDate.invalid && inv.transDate.touched">
                                        {{ 'Error: ' + getErrorMessage(invForm, 'transDate', fecha, 0, 0, 0, 0)}}</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <div class="col-md-auto">
                                    <button mat-flat-button id="save" type="submit" color="primary" text-center (click)="saveAdj()">Generar Costos</button>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
</div>