<ng-container *ngIf="discount$ | async"></ng-container>
<h2>Ingrese Descuento</h2>
<div class="row h-50">
    <mat-radio-group
      [(ngModel)]="descuento">
      <mat-radio-button class="example-radio-button" *ngFor="let discount of discounts" [value]="discount">
        {{discount}}
      </mat-radio-button>
    </mat-radio-group>
    <mat-form-field appearance="outline">
        <input matInput type="text" [(ngModel)]="discount">
    </mat-form-field>
</div>
<div class="row">
    <button class="col-md-12" mat-flat-button color="accent" (click)="aplicarDescuento()">Aplicar</button>
</div>