import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByCat'
})
export class GroupByCatPipe implements PipeTransform {

  transform(items: Array<any>, category: string): Array<any> {
    return items.filter(item => item.ORDER_ID === category);
}

}
