import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdersService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export interface discountData {
  orderId: string;
  tipo: number;
  det: any;
}
@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent implements OnInit {
  descuento: string = '';
  discount$!: Observable<any>;
  discounts: string[] = ['Porcentaje', 'Monto'];
  discount: number = 0;
  constructor(
    private ordersService: OrdersService,
    private dialogRef: MatDialogRef<DiscountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: discountData
  ) { }

  ngOnInit(): void {
  }

  aplicarDescuento(){
    // var desc = 0;
    // if(this.data.tipo == 0){
    // var lineaNo = this.data.det.lineNo;
    // if(this.descuento == 'Monto'){
    //   var discount = Number(this.discount);
    // }else{
    //   var discount = this.data.det.amount * (this.discount/100);
    // }
    // this.discount$ = this.ordersService.PostDiscountLine(this.data.orderId, lineaNo, discount).pipe(
    //   map((res : any)=>{
    //       if(res.Status == 200){
    //         this.dialogRef.close({res: 200});
    //       }
    //     })
    //   )
    // }
    if(this.data.tipo == 1){
      console.log(this.descuento);
      var discount = this.discount;
      this.discount$ = this.ordersService.postDiscount(this.data.orderId, discount).pipe(
        map((res : any)=>{
          if(res.Status == 200){
            this.dialogRef.close({res: 200});
          }
        })
      )
    }
  }

}
