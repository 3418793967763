<div class="container-fluid desp-area">
    <ng-container *ngIf="despacho$ | async"></ng-container>
    <ng-container *ngIf="orden$ | async"></ng-container>
    <div class="row pt-4">
        <div class="col-md-6">
            <div class="position" [ngClass]="{'green': pos1 != ''}">{{(pos1 == '' ? '--' : 'Mesa - ' + pos1 + ' - 01')}}</div>
            <div class="position" [ngClass]="{'green': pos2 != ''}">{{(pos2 == '' ? '--' : 'Mesa - ' + pos2 + ' - 02')}}</div>
            <div class="position" [ngClass]="{'green': pos3 != ''}">{{(pos3 == '' ? '--' : 'Mesa - ' + pos3 + ' - 03')}}</div>
            <div class="position" [ngClass]="{'green': pos4 != ''}">{{(pos4 == '' ? '--' : 'Mesa - ' + pos4 + ' - 04')}}</div>
        </div>
        <div class="col-md-6">
            <div class=" order-card">
                <div class="row">
                    <div class="col label">Cliente - {{customer}}</div>
                </div>
                <div class="row">
                    <div class="col label">Mesa - {{table}}</div>
                </div>
                <div class="row">
                    <div class="col label">Orden - {{orden.toString().padStart(5, '0')}}</div>
                </div>
                <div class="spacer-h"></div>
                <ng-container *ngFor="let item of orderDet">
                    <div class="detail-w">
                        <span class="data-order">{{item.QTY + '  ' + item.PROD}}</span>
                        <div class="" *ngIf="item.NOTA">
                            <span class="nota">{{item.NOTA}}</span>
                        </div>
                    </div>
                </ng-container>
                <div class="spacer-h"></div>
            </div>
            <div class="row justify-content-md-center">
                <button mat-flat-button color="accent" class="col-md-8 btn-despacho" (click)="despacho()">
                    Despachado
                </button>
            </div>
        </div>
    </div>
</div>