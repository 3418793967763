import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { throwError} from 'rxjs'; 
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/core/services';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  readonly apiURL = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getSearch(tipo: string, companyId: string, texto: string, offset: number, limit: number, actives: number){
    if(tipo == 'products'){
      return this.http.get<any>(this.apiURL + 'products/'+companyId+'/'+texto+'/'+offset+'/'+limit+'/'+actives)
                      .pipe(catchError(this.errorHandler));
    } 
  }

  delItem(obj: string, id: string, formData: any){
    if(obj == 'dummy'){
      return this.http.put(this.apiURL + '/dummy/' + id, formData)
                      .pipe(catchError(this.errorHandler));
    } else {
      return this.http.put(this.apiURL + '/dummy/' + id, formData)
                      .pipe(catchError(this.errorHandler));
    }
  }

  errorHandler(error: any){
    return throwError(error || 'Server Error');
  }
}
