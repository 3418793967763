import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  readonly apiURL = environment.apiUrl;
  // private product$ = new BehaviorSubject<any>({});
  private order$ = new BehaviorSubject<any>({});
  private table$ = new BehaviorSubject<any>({});
  private user$ = new BehaviorSubject<any>({});
  // selectedProduct$ = this.product$.asObservable();
  selectedOrder$ = this.order$.asObservable();
  selectedTable$ = this.table$.asObservable();
  selectedUser$ = this.user$.asObservable();

  constructor(private http: HttpClient) { }

  // setProduct(product: any) {
  //   this.product$.next(product);
  // }

  setOrder(order: any) {
    this.order$.next(order);
  }

  setTable(table: any) {
    this.table$.next(table);
  }

  setUser(user: any) {
    this.user$.next(user);
  }

  getAdmin(frmData:any){
    return this.http.post(this.apiURL + 'user/admin', frmData)
                    .pipe(catchError(this.errorHandler));
  }

  printOrder(order: any){
    return this.http.post(this.apiURL + 'print', {orderId: order})
                    .pipe(catchError(this.errorHandler));
  }

  // getTaxes(){
  //   return this.http.get(this.apiURL + 'taxes')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // getCategories(){
  //   return this.http.get(this.apiURL + 'cats')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetProductsABC(){
  //   return this.http.get(this.apiURL + 'products')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetCocina(){
  //   return this.http.get(this.apiURL + 'cocina')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetCocinaFin(){
  //   return this.http.get(this.apiURL + 'cocina/fin')
  //                   .pipe(catchError(this.errorHandler));
  // }

  GetDespachos(){
    return this.http.get(this.apiURL + 'despacho')
                    .pipe(catchError(this.errorHandler));
  }

  // GetPendings(){
  //   return this.http.get(this.apiURL + 'pendings')
  //                   .pipe(catchError(this.errorHandler));
  // }

  GetCierre(tipo:number, fechaIni: string, fechaFin:string, limit: number, offset: number){
    return this.http.get(this.apiURL + 'cierre/' +tipo +'/' + fechaIni+ '/' + fechaFin +'/' + limit + '/' + offset)
                    .pipe(catchError(this.errorHandler));
  }

  // GetPendientesPago(){
  //   return this.http.get(this.apiURL + 'pendpago')
  //                   .pipe(catchError(this.errorHandler));
  // }

  GetRobot(){
    return this.http.get(this.apiURL + 'robot')
                    .pipe(catchError(this.errorHandler));
  }

  // GetBloque(bloque: any){
  //   return this.http.get(this.apiURL + 'bloque/'+bloque)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetOrdenMesa(mesa: any){
  //   return this.http.get(this.apiURL + 'ordenes/mesa/'+mesa)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // PostEntregaMesa(orderId: any, lineNo: any){
  //   return this.http.post(this.apiURL + 'entrega/mesa/' + orderId + '/' + lineNo, '')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // PostInvoice(orderId){
  //   return this.http.post(this.apiURL + 'invoice/' + orderId, '')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // CancelOrder(orderId){
  //   return this.http.post(this.apiURL + 'cancel/order/' + orderId, '')
  //                   .pipe(catchError(this.errorHandler));
  // }


  

 
  
  // PostDespacho(order, dataform){
  //   return this.http.post(this.apiURL + 'despacho/' + order, dataform)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // PutProducto(prodId, prod){
  //   return this.http.put(this.apiURL + 'product/'+prodId, prod)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // PostLogin(data: any){
  //   return this.http.post(this.apiURL + 'login', data)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // PostPayOrder(dataForm: any){
  //   return this.http.post(this.apiURL + 'order/payment', dataForm)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // PostPayOrderCash(dataForm: any){
  //   return this.http.post(this.apiURL + 'order/payment/cash', dataForm)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetInvoice(order:any){
  //   return this.http.get(this.apiURL + 'invoice/gen/'+order)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // RemoveLine(orderId: string, lineNo: number){
  //   return this.http.put(this.apiURL + 'order/del/line/' + orderId + '/' + lineNo, '')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GenRecibo(order: any){
  //   return this.http.post(this.apiURL + 'order/gen/'+ order, '')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // MergeOrder(order: number, table: number){
  //   return this.http.post(this.apiURL + 'order/merge/'+ order + '/' + table, '')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetOrder(order:any){
  //   return this.http.get(this.apiURL + 'order/data/'+order)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetReportInvoice(rtn:any){
  //   return this.http.get(this.apiURL + 'report/invoices/' + rtn)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetReportTable(tipo:any, fechaIni:any, fechaFin:any){
  //   return this.http.get(this.apiURL + 'report/table/'+tipo+'/' + fechaIni + '/' + fechaFin)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetReportProducts(tipo:any, fechaIni:any, fechaFin:any){
  //   return this.http.get(this.apiURL + 'report/products/' + tipo +'/' + fechaIni + '/' + fechaFin)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetReportProductsLess(fechaIni:any, fechaFin:any){
  //   return this.http.get(this.apiURL + 'report/product/less/' + fechaIni + '/' + fechaFin)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetReportCustomer(tipo:any, fechaIni:any, fechaFin:any){
  //   return this.http.get(this.apiURL + 'report/customer/' + tipo +'/' + fechaIni + '/' + fechaFin)
  //                   .pipe(catchError(this.errorHandler));
  // }

  // PostDiscount(orderId: string, discount: number, tip: number){
  //   return this.http.post(this.apiURL + 'order/discount/' + orderId + '/' + discount + '/' + tip, '')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetKpiSales(){
  //   return this.http.get(this.apiURL + 'kpi/sales')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetKpiTaxes(){
  //   return this.http.get(this.apiURL + 'kpi/taxes')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetKpiCustomers(){
  //   return this.http.get(this.apiURL + 'kpi/customers')
  //                   .pipe(catchError(this.errorHandler));
  // }

  // GetKpiProducts(){
  //   return this.http.get(this.apiURL + 'kpi/products')
  //                   .pipe(catchError(this.errorHandler));
  // }

  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
}
