import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TablesService {
  readonly apiURL = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getTables(companyId: string, locationId: string){
    return this.http.get(this.apiURL + 'tables/' + companyId + '/' + locationId)
                    .pipe(catchError(this.errorHandler));
  }
  
  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
}
