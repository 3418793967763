import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/core/services';
import { CustomersService, GlobalService } from '@app/services';
import { Observable } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

export interface DialogData {
  orderID: string;
}

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss']
})
export class PagoComponent implements OnInit {
  loading: boolean =false;
  invoice$: Observable<any>;
  customer$: Observable<any>;
  ordLoc: string = '';
  CustomerId: number = 0;
  taxId: string ='';
  Name: string ='';
  Address: string ='';
  Auth: string ='';

  companyId: string = '';
  constructor(
    private customersService: CustomersService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<PagoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.ordLoc = this.data.orderID;
    this.companyId = this.authService.getUserProfile().companyId;
  }

  cancelClick(){
    this.dialogRef.close();
  }

  getCustomer(){
    this.customer$ = this.customersService.getCustomersTAX(this.companyId, this.taxId).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.CustomerId = 0;
          if (res.Data.length > 0){
            this.Name = res.Data[0].NAME;
            this.Address = res.Data[0].ADDRESS;
            this.CustomerId = res.Data[0].CUSTOMER_ID;
          }
        }
      })
    );
  }

  procesarPago(){
    // this.loading = true;
    // let generada: number = 0;
    // let dataForm = {
    //   OrderId: this.ordLoc,
    //   Authorization: (this.Auth == undefined ? '' : this.Auth),
    //   RTN: this.RTN,
    //   Address: this.Address,
    //   Name: this.Name,
    //   CustomerId: this.CustomerId
    // }
    // let pago: number = 0;
    // this.invoice$ = this.global.PostPayOrderCash(dataForm).pipe(
    //   map((res: any) => {
    //     if (res.Status == 200){
    //       pago = 1;
    //       return pago;
    //     }
    //   }),
    //   switchMap(val => this.global.PostInvoice(this.ordLoc)),
    //   map((inv: any) =>{
    //     if (inv.Status == 200){
    //       generada = 1;
    //     }
    //     return generada;
    //   }),
    //   delay(3000),
    //   map(_ => {
    //     this.loading = false;
    //     if (generada == 1){
    //       this.dialogRef.close(generada);
    //     }
    //   })
    // );
  }

}
