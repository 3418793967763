<ng-container *ngIf="invoices$ | async"></ng-container>
<ng-container *ngIf="dialog$ | async"></ng-container>
<div class="container">
    <div class="row mb-4 align-items-center">
        <div class="col-md-4">
            <div class="header">
                Facturas
            </div>
        </div>
        <div class="col-md-8 content-refresh">
            <div class="row">
                <mat-form-field class="col-md-3 mt-3 pe-2 w-search" appearance="outline">
                    <mat-label>Buscar</mat-label>
                    <input matInput placeholder="Buscar" [(ngModel)]="texto" name="buscar">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-3 mt-3">
                    <mat-label>Rango emisión</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate placeholder="Fecha Inicial" [(ngModel)]="startDate">
                      <input matEndDate placeholder="Fecha Final" [(ngModel)]="endDate">
                    </mat-date-range-input>
                    <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <button mat-button color="primary" class="mx-2 h-button col-md-auto" (click)="searchData()">
                    <mat-icon class="btn-search" matSuffix>search</mat-icon>
                    Buscar
                </button>
                <button mat-icon-button color="primary" matTooltip="Actualizar" class="h-refresh col-md-auto" (click)="refreshSearch()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <mat-card class="card-table py-0">
        <mat-table matSort [dataSource]="dataSource">
            <ng-container matColumnDef="INVOICE_NUMBER">
                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-3 title-cell"> FACTURA </mat-header-cell>
                <mat-cell *matCellDef="let row" class="col-md-3"> {{row.INVOICE_NUMBER}} </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="TAX_ID">
                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-2 title-cell"> RTN </mat-header-cell>
                <mat-cell *matCellDef="let row" class="col-md-2"> {{row.TAX_ID}} </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="NAME">
                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-2 title-cell"> NOMBRE </mat-header-cell>
                <mat-cell *matCellDef="let row" class="col-md-2"> {{row.NAME}} </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="DATE">
                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-2 title-cell"> FECHA </mat-header-cell>
                <mat-cell *matCellDef="let row" class="col-md-2"> {{row.DATE | date: 'MMM d, y'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="TOTAL_AMOUNT">
                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-1 title-cell"> TOTAL </mat-header-cell>
                <mat-cell *matCellDef="let row" class="col-md-1 d-flex flex-row-reverse"> {{currency + ' ' + (row.TOTAL_AMOUNT | number:'1.2-2')}} </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="col-md-2 title-cell">ACCIONES</mat-header-cell>
                <mat-cell *matCellDef="let row" class="col-md-2">
                    <button mat-icon-button matTooltip="Imprimir Factura" (click)="printInvoice(row.INVOICE_ID)"><mat-icon>print</mat-icon></button>
                    <button mat-icon-button matTooltip="Editar RTN" *ngIf="row.DIFERENCIA >= -4" (click)="updateInvoice(row.INVOICE_ID)"><mat-icon>edit_note</mat-icon></button>
                </mat-cell>
            </ng-container> 
            <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div class="col-md-12 no-data my-5" *ngIf="lengthTabla == 0">No existen registros en su búsqueda<br/>cambie los datos de su filtro</div>
        <mat-paginator #paginator class="col-md-12 mat-paginator-sticky"
            [length]="lengthTabla"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="goToPage($event.pageIndex, $event.pageSize)">
        </mat-paginator>
    </mat-card>
</div>  