<ng-container *ngIf="reports$ | async"></ng-container>
<div class="container-fluid px-5">
    <div class="row mb-4 align-items-center">
        <div class="col-md-7">
            <div class="header">
                Reportes
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Seleccione período</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Fecha Inicial">
                  <input matEndDate formControlName="end" placeholder="Fecha Final">
                </mat-date-range-input>
                <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <button mat-button color="primary" class="mx-2 col-md-auto" (click)="loadDailySales(0)">
            <mat-icon class="btn-search" matSuffix>search</mat-icon>
            Buscar
        </button>
    </div>
    <div class="row" *ngIf="tipoRpt == 1">
        <mat-card class="card-table py-0 my-3">
            <mat-table [dataSource]="dataSource" id="excel-table" matSort [trackBy]="trackRow">
                <ng-container *ngFor="let hd of headers; let i = index">
                    <ng-container [matColumnDef]="hd.codigo" *ngIf="i < 5" sticky>
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="title-cell header-cell col-md text-center">{{hd.header}}</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="col-md content-cell">{{ convertirTexto(hd.codigo, row[hd.codigo])}}</mat-cell>
                    </ng-container>
    
                    <ng-container [matColumnDef]="hd.codigo" *ngIf="i >= 5">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="title-cell header-cell col-md text-center">{{hd.header}}</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="col-md content-cell">{{ convertirTexto(hd.codigo, row[hd.codigo])}}</mat-cell>
                    </ng-container>
                </ng-container>
            
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div class="col-md-12 no-data my-5" *ngIf="length == 0">No existen registros en su búsqueda<br/>cambie los datos de su filtro</div>
            <mat-toolbar class="mat-paginator-sticky">
                <div style="margin-left:auto"></div>
                <button mat-icon-button color="primary" class="me-3" matTooltip="Exportar a XLSX" (click)="exportexcel()"><mat-icon class="material-icons-outlined">file_download</mat-icon></button>  
                <mat-paginator #paginator
                    [length]="length"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="goToPage($event.pageIndex, $event.pageSize)"
                    showFirstLastButtons>
                </mat-paginator>
            </mat-toolbar>
        </mat-card>
    </div>
</div>