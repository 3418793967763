import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompaniesService, DocumentsService } from '@app/services';
import { Observable } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from '@app/shared';
import { AuthService } from '@app/core/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfirmValidParentMatcher } from '@app/validators';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  dataSourceTaxes = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;

  doctos$: Observable<any>;
  delDocumento$: Observable<any>;

  lengthDocto: number = -1;
  pageSizeDocto: number = 5;
  _pageDocto: number = 0;
  pageSizeOptionsDocto: number[] = [5, 10, 25, 50, 100];
  displayedColumns: string[] = ['DESCRIPTION', 'LOCATION', 'TYPE_ID', 'STATUS', 'ACTIONS'];
  types: string[] = ['Ventas', 'Compras', 'Inventario'];

  taxes$: Observable<any>;
  delTax$: Observable<any>;

  lengthTaxes: number = -1;
  pageSizeTaxes: number = 5;
  _pageTaxes: number = 0;
  pageSizeOptionsTaxes: number[] = [5, 10, 25, 50, 100];
  displayedColumnsTaxes: string[] = ['DESCRIPTION', 'AMOUNT', 'TAX_INCLUDED', 'STATUS', 'ACTIONS'];
  
  company$: Observable<any>;
  locations$: Observable<any>;
  companyId: string = '';
  minDate: Date;
  invalidDateIni: number = 0;
  invalidDateFin: number = 0;

  locationsDoctos: any[]=[];
  locations: any[]=[];
  length: number = -1;
  pageSize: number = 5;
  _page: number = 0;
  
  confirmValidParentMatcher = new ConfirmValidParentMatcher();
  
  constructor(
    private companiesService: CompaniesService,
    private documentService: DocumentsService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private spinnerService: SpinnerService
  ) { }

  companyForm = this.fb.group({
    CompanyId: [''],
    Name: [''],
    BusinessName: [''],
    Address: [''],
    Email: [''],
    Phone: [''],
    Tax: [''],
    Currency: [''],
    PrinterMesero: [''],
    PrinterCocina: [''],
    AdultoMayor: ['']
  });

  locationForm = this.fb.group({
    LocationId: [''],
    Description: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]],
    Address: ['', [Validators.required, Validators.maxLength(150), Validators.minLength(3)]],
    Status: [true],
  });

  doctosForm = this.fb.group({
    DocumentId: [''],
    Description: ['', [Validators.required, Validators.maxLength(45), Validators.minLength(3)]],
    Prefix: [''],
    Suffix: [''],
    NextNumber: ['', [Validators.required, Validators.max(999999999), Validators.min(1)]],
    StartNumber: [''],
    EndNumber: [''],
    Digits: [''],
    Type: ['', [Validators.required]],
    Auth: [''],
    StartDate: [null],
    EndDate: [null],
    Status: [true],
    CompanyId: [''],
    LocationId: ['', [Validators.required]]
  });

  taxesForm = this.fb.group({
    TaxId: [''],
    Description: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]],
    Amount: ['', [Validators.required, Validators.max(100), Validators.min(1)]],
    TaxIncluded: [''],
    Status: [true],
    CompanyId: ['']
  })

  get location(){
    return this.locationForm.controls;
  }

  get documentos(){
    return this.doctosForm.controls;
  }

  get taxes(){
    return this.taxesForm.controls;
  }

  ngOnInit(): void {
    this.minDate = new Date(2021, 8, 1);
    this.companyId = this.authService.getCompanyId();

    var spinnerRef = this.spinnerService.start();
    this.company$ = this.companiesService.getCompany(this.companyId).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          if (res.Data.length > 0){
            this.companyForm.patchValue({
              CompanyId: res.Data[0].COMPANY_ID,
              Name: res.Data[0].NAME,
              BusinessName: res.Data[0].BUSINESS_NAME,
              Address: res.Data[0].ADDRESS,
              Email: res.Data[0].EMAIL,
              Phone: res.Data[0].PHONE,
              Tax: res.Data[0].TAX_ID,
              Currency: res.Data[0].CURRENCY,
              PrinterMesero: res.Data[0].PRINTER_MESERO,
              PrinterCocina: res.Data[0].PRINTER_COCINA,
              AdultoMayor: res.Data[0].ADULTO_MAYOR
            });
          }
          return res.Data;
        }
      })
    );
  }

  updateData(){
    let formData = {
      companyId: this.companyForm.value.CompanyId,
      name: this.companyForm.value.Name,
      bname: this.companyForm.value.BusinessName,
      address: this.companyForm.value.Address,
      email: this.companyForm.value.Email,
      phone: this.companyForm.value.Phone,
      taxId: this.companyForm.value.Tax,
      currency: this.companyForm.value.Currency,
      printerMesero: this.companyForm.value.PrinterMesero,
      printerCocina: this.companyForm.value.PrinterCocina,
      adultoMayor: this.companyForm.value.AdultoMayor
    }
    let exito: string = 'Compañía actualizada con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.company$ = this.companiesService.setCompany(formData).pipe(
      delay(3000),
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
        return res.Data;
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    );
  }

  clearLocation(){
    this.locationForm.reset({LocationId: '', Description: '', Address: '', Status: true});
    this.locations.forEach(x => {
      x.SEL = 0;
    });
  }

  saveLocation(){
    this.locationForm.markAllAsTouched();
    if(!this.locationForm.valid) { return; }
    let formData = {
      locationId: (this.locationForm.value.LocationId == '' ? 0 : this.locationForm.value.LocationId),
      description: this.locationForm.value.Description,
      address: this.locationForm.value.Address,
      status: this.locationForm.value.Status,
      companyId: this.companyId
    }
    let exito: string = 'Localidad actualizada con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.locations.forEach(x => {
      x.SEL = 0;
    });
    this.locations$ = this.companiesService.setLocation(formData).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          if(this.locationForm.value.LocationId == ''){
            this.locations.push(res.Data);
            this.length = this.locations.length;
            this.locationForm.patchValue({LocationId: res.Data.LOCATION_ID});
          } else {
            let result = this.locations.filter(x => x.LOCATION_ID == this.locationForm.value.LocationId);
            if(result.length > 0){
              result[0].DESCRIPTION = res.Data.DESCRIPTION;
              result[0].ADDRESS = res.Data.ADDRESS;
              result[0].STATUS = res.Data.STATUS;
            }
          }
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
        return res.Data;
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    );
  }

  changeSelected(loc: any){
    this.locations.forEach(x => {
      x.SEL = 0;
    });
    loc.SEL = 1;
    this.locationForm.patchValue({
      LocationId: loc.LOCATION_ID,
      Description: loc.DESCRIPTION,
      Address: loc.ADDRESS,
      Status: loc.STATUS
    });
  }

  loadLocations(curPage: number){
    let spinnerRef = this.spinnerService.start();
    this.locations$ = this.companiesService.getLocations(this.companyId, curPage, this.pageSize).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.locations = res.Data;
          if(res.Data.length > 0){
            this.length = res.Data[0].TOTAL;
          } else {
            this.length = 0;
          }
          return res.Data;
        }
      }),
      catchError(err => {
        console.log(err);
        return err;
      })
    );
  }

  goToPage(page: number, elements: number){
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    this.loadLocations((this._page == 0 ? 0 : this._page*this.pageSize));
  }

  delDocumento(row: any){

  }

  saveDocumento(){
    this.doctosForm.markAllAsTouched();
    if(!this.doctosForm.valid) { return; }
    let sDate = new Date(this.doctosForm.value.StartDate); 
    let iDate = (this.doctosForm.value.StartDate == null ? '' : sDate.getFullYear() + '-' + (sDate.getMonth()+1).toString().padStart(2, '0') + '-' + (sDate.getDate()).toString().padStart(2, '0'));
    let eDate = new Date(this.doctosForm.value.EndDate);
    let fDate = (this.doctosForm.value.EndDate == null ? '' : eDate.getFullYear() + '-' + (eDate.getMonth()+1).toString().padStart(2, '0') + '-' + (eDate.getDate()).toString().padStart(2, '0'));

    let formData = {
      documentId: (this.doctosForm.value.DocumentId == '' ? 0 : this.doctosForm.value.DocumentId),
      locationId: this.doctosForm.value.LocationId,
      companyId: this.companyId,
      typeId: this.doctosForm.value.Type,
      description: this.doctosForm.value.Description,
      prefix: this.doctosForm.value.Prefix,
      suffix: this.doctosForm.value.Suffix,
      nextNumber: this.doctosForm.value.NextNumber,
      endNumber: this.doctosForm.value.EndNumber,
      startNumber: this.doctosForm.value.StartNumber,
      digits: this.doctosForm.value.Digits,
      status: this.doctosForm.value.Status,
      auth: this.doctosForm.value.Auth,
      startDate: iDate,
      endDate: fDate
    }
    let exito: string = 'Documento actualizado con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.doctos$ = this.documentService.setDocto(formData).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          if(this.doctosForm.value.DocumentId == ''){
            this.lengthDocto = this.lengthDocto+1;
            this.doctosForm.patchValue({DocumentId: res.Data.DOCUMENT_ID});
            let dt: any = this.dataSource.data;
            dt.push(res.Data);
            this.dataSource.data = null;
            this.dataSource.data = dt;
            this.dataSource.sort = this.sort;
          } else {
            let dt: any = this.dataSource.data;
            let result = dt.filter(x => x.DOCUMENT_ID == this.doctosForm.value.DocumentId);
            if(result.length > 0){
              result[0].DESCRIPTION = res.Data.DESCRIPTION;
              result[0].LOCATION = res.Data.LOCATION;
              result[0].TYPE_ID = res.Data.TYPE_ID;
              result[0].STATUS = res.Data.STATUS;
            }
          }
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
        return res.Data;
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    );
  }

  changeSelectedDocto(docto: any){
    this.doctosForm.patchValue({
      DocumentId: docto.DOCUMENT_ID,
      Description: docto.DESCRIPTION,
      Prefix: docto.PREFIX,
      Suffix: docto.SUFFIX,
      NextNumber: docto.NEXT_NUMBER,
      EndNumber: docto.END_NUMBER,
      StartNumber: docto.START_NUMBER,
      Digits: docto.DIGITS,
      Type: docto.TYPE_ID,
      Auth: docto.AUTH,
      StartDate: docto.START_DATE,
      EndDate: docto.END_DATE,
      Status: docto.STATUS,
      CompanyId: docto.COMPANY_ID,
      LocationId: docto.LOCATION_ID
    });
  }

  onChangeTab(event: any){
    if (event.index == 1){
      this._page = 0;
      this.loadLocations(this._page);
    }
    if (event.index == 2){
      this._pageDocto = 0;
      this.loadDoctos(this._pageDocto);
    }
    if (event.index == 3){
      this._pageTaxes = 0;
      this.loadTaxes(this._pageTaxes);
    }
  }

  clearDocumento(){
    this.doctosForm.reset({DocumentId: '', Description: '', Prefix: '', Suffix: '', NextNumber: '', EndNumber: '', StartNumber: '', Digits: '', Type: '', Auth: '', StartDate: null, EndDate: null, Status: true, CompanyId: '', LocationId: ''});
  }

  loadDoctos(curPage: number){
    var spinnerRef = this.spinnerService.start();
    this.doctos$ = this.documentService.getDoctos(this.companyId, curPage, this.pageSize).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.dataSource.data = res.Data;
          this.dataSource.sort = this.sort;
          if(res.Data.length > 0){
            this.lengthDocto = res.Data[0].TOTAL;
          } else {
            this.lengthDocto = 0;
          }
          return res.Data;
        }
      }),
      switchMap(x => this.companiesService.getActiveLocations(this.companyId).pipe(
        map((res: any) => {
          if (res.Status == 200){
            this.locationsDoctos = res.Data;
            return res.Data;
          }
        })
      )),
      catchError(err => {
        console.log(err);
        return err;
      })
    );
  }

  goToPageDocto(page: number, elements: number){
    if (this.pageSizeDocto != elements){
      this.pageSizeDocto = elements;
    } 
    this._pageDocto = page;
    this.loadDoctos((this._pageDocto == 0 ? 0 : this._pageDocto*this.pageSizeDocto));
  }

  delTax(row: any){

  }

  saveTax(){
    this.taxesForm.markAllAsTouched();
    if(!this.taxesForm.valid) { return; }
    let formData = {
      taxId: (this.taxesForm.value.TaxId == '' ? 0 : this.taxesForm.value.TaxId),
      description: this.taxesForm.value.Description,
      amount: this.taxesForm.value.Amount,
      taxIncluded: this.taxesForm.value.TaxIncluded,
      status: this.taxesForm.value.Status,
      companyId: this.companyId
    }
    let exito: string = 'Documento actualizado con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.taxes$ = this.companiesService.setTax(formData).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          if(this.taxesForm.value.TaxId == ''){
            this.lengthTaxes = this.lengthTaxes+1;
            this.taxesForm.patchValue({TaxId: res.Data.TAX_ID});
            let dt: any = this.dataSourceTaxes.data;
            dt.push(res.Data);
            this.dataSourceTaxes.data = null;
            this.dataSourceTaxes.data = dt;
            this.dataSourceTaxes.sort = this.sort;
          } else {
            let dt: any = this.dataSourceTaxes.data;
            let result = dt.filter(x => x.TAX_ID == this.taxesForm.value.TaxId);
            if(result.length > 0){
              result[0].DESCRIPTION = res.Data.DESCRIPTION;
              result[0].AMOUNT = res.Data.AMOUNT;
              result[0].TAX_INCLUDED = res.Data.TAX_INCLUDED;
              result[0].STATUS = res.Data.STATUS;
            }
          }
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
        return res.Data;
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    );
  }

  changeSelectedTax(tax: any){
    this.taxesForm.patchValue({
      TaxId: tax.TAX_ID,
      Description: tax.DESCRIPTION,
      Amount: tax.AMOUNT,
      TaxIncluded: tax.TAX_INCLUDED,
      Status: tax.STATUS
    });
  }

  clearTax(){
    this.taxesForm.reset({TaxId: '', Description: '', Amount: '', TaxIncluded: '', Status: true, CompanyId: ''});
  }

  loadTaxes(curPage: number){
    var spinnerRef = this.spinnerService.start();
    this.taxes$ = this.companiesService.getTaxes(this.companyId, curPage, this.pageSizeTaxes).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.dataSourceTaxes.data = res.Data;
          this.dataSourceTaxes.sort = this.sort;
          if(res.Data.length > 0){
            this.lengthTaxes = res.Data[0].TOTAL;
          } else {
            this.lengthTaxes = 0;
          }
          return res.Data;
        }
      }),
      catchError(err => {
        console.log(err);
        return err;
      })
    );
  }

  goToPageTaxes(page: number, elements: number){
    if (this.pageSizeTaxes != elements){
      this.pageSizeTaxes = elements;
    } 
    this._pageTaxes = page;
    this.loadTaxes((this._pageTaxes == 0 ? 0 : this._pageTaxes*this.pageSizeTaxes));
  }

  getErrorMessage(frm: FormGroup, component: string, lbl: HTMLElement, minlength: number, maxlength: number, min: number, max: number){
    let label: string = lbl.innerText;
    let requerido = ' es requerido';
    let minLen = 'Largo mínimo requerido ';
    let maxLen = 'Largo máximo requerido ';
    let minVal = 'Valor mínimo requerido ';
    let maxVal = 'Valor máximo requerido ';
    let invAddr = 'La dirección no es válida';
    let email = 'Formato de correo electrónico inválido';
    return frm.controls[component].hasError('required') ? label + requerido: 
      frm.controls[component].hasError('minlength') ? minLen + minlength:
        frm.controls[component].hasError('maxlength') ? maxLen + maxlength:
          frm.controls[component].hasError('min') ? minVal + min:
            frm.controls[component].hasError('max') ? maxVal + max:
              frm.controls[component].hasError('email') ? email:
                frm.controls[component].hasError('invalidAddress') ? invAddr:
                  '';
  }

}