import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  readonly apiURL = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getCompany(companyId: string){
    return this.http.get(this.apiURL + 'company/'+companyId)
                    .pipe(catchError(this.errorHandler));
  }

  setCompany(form){
    return this.http.post(this.apiURL + 'company', form)
                    .pipe(catchError(this.errorHandler));
  }

  getLocations(companyId: string, offset: number, limit: number){
    return this.http.get(this.apiURL + 'locations/'+companyId+'/'+offset+'/'+limit)
                    .pipe(catchError(this.errorHandler));
  }

  getActiveLocations(companyId: string){
    return this.http.get(this.apiURL + 'locations/'+companyId)
                    .pipe(catchError(this.errorHandler));
  }

  setLocation(form){
    return this.http.post(this.apiURL + 'location', form)
                    .pipe(catchError(this.errorHandler));
  }

  getTaxes(companyId: string, offset: number, limit: number){
    return this.http.get(this.apiURL + 'taxes/'+companyId+'/'+offset+'/'+limit)
                    .pipe(catchError(this.errorHandler));
  }

  getActiveTaxes(companyId: string){
    return this.http.get(this.apiURL + 'taxes/'+companyId)
                    .pipe(catchError(this.errorHandler));
  }

  setTax(form){
    return this.http.post(this.apiURL + 'tax', form)
                    .pipe(catchError(this.errorHandler));
  }

  getCategories(companyId: string, offset: number, limit: number){
    return this.http.get(this.apiURL + 'categories/'+companyId+'/'+offset+'/'+limit)
                    .pipe(catchError(this.errorHandler));
  }

  getActiveCategories(companyId: string){
    return this.http.get(this.apiURL + 'categories/'+companyId)
                    .pipe(catchError(this.errorHandler));
  }
  
  postCategory(form){
    return this.http.post(this.apiURL + 'category', form)
                    .pipe(catchError(this.errorHandler));
  }

  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
}
