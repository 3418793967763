import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmValidParentMatcher } from '@app/validators';
import { CompaniesService, GlobalService, ProductsService } from '@app/services';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '@app/shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@app/core/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-product',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;

  products$: Observable<any>;
  delProduct$: Observable<any>;
  
  abc$: Observable<any>;
  product$: Observable<any>;

  image$: Observable<any>;

  cats: any[]=[];
  taxes: any[]=[];

  inventValue: boolean = false;
  busqueda: string = '';
  productId: number;
  fileName: string = '';
  fileString: any;
  file: any;
  companyId: string= '';
  iTabs: number = 0;

  length: number = -1;
  pageSize: number = 5;
  _page: number = 0;
  texto: string = "";
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  displayedColumns: string[] = ['NAME', 'IMPUESTO', 'CATEGORIA', 'STATUS', 'ACTIONS'];

  readonly imgPath = environment.apiUrl;

  confirmValidParentMatcher = new ConfirmValidParentMatcher();

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private productsService: ProductsService,
    private companyService: CompaniesService,
    private spinnerService: SpinnerService,
    private productService: ProductsService,
    private authService: AuthService
  ) { }

  get f(){
    return this.productForm.controls;
  }

  get fImage(){
    return this.imageForm.controls;
  }

  productForm = this.fb.group({
    productId: [''],
    name: ['', [Validators.required, Validators.maxLength(70), Validators.minLength(3)]],
    description: ['', [Validators.maxLength(120), Validators.minLength(3)]],
    obs: ['', [Validators.maxLength(500), Validators.minLength(3)]],
    unitPrice: [0, [Validators.required]],
    categoryId: ['', [Validators.required]],
    taxId: ['', [Validators.required]],
    inventory: [false],
    variable: [''],
    imagen: [''],
    status: [true],
    mesero: [false],
    souvenier: [false]
  });

  imageForm = this.fb.group({
    Imagen: ['', Validators.required]
  });

  ngOnInit(): void {
    this.companyId = this.authService.getUserProfile().companyId;

    this._page = 0;
    this.loadData(this._page, '0');
    this.abc$ = this.companyService.getActiveCategories(this.companyId).pipe(
      map((res: any) => {
          if (res.Status == 200){
            this.cats = res.Data;
          }
      }),
      switchMap((x: any) => x = this.companyService.getActiveTaxes(this.companyId)),
        map((res: any) => {
          if (res.Status == 200){
            this.taxes = res.Data;
          }
        }
      )
    );
  }

  selectProduct(prodId: number){
    this.iTabs = 0;
    this.productId = prodId;
    this.showData(prodId);
  }

  onChangeTab(event: any){
    this.iTabs = event.index;
    if (event.index == 0){
      this._page = 0;
      this.loadData(this._page, '0');
    }
  }

  loadData(curPage:number, texto:string){
    var spinnerRef = this.spinnerService.start();
    this.products$ = this.productService.getProducts(this.companyId, texto, curPage, this.pageSize).pipe(
      map((res:any)=>{
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.dataSource.data = res.Data;
          this.dataSource.sort = this.sort;
          this.length = res.Data[0].TOTAL;
        } else {
          this.length = 0;
          this.dataSource.data = null;
        }
      })
    );
  }

  goToPage(page: number, elements: number){
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    this.loadData((this._page == 0 ? 0 : this._page*this.pageSize), '0');
  }

  searchData(){
    this._page = 0;
    if(this.texto != ''){
      this.loadData(this._page, this.texto);
    }else{
      this.loadData(this._page, '0');
    }
  }

  refreshSearch(){
    this._page = 0;
    this.texto = '';
    this.loadData(this._page, '0');
  }

  delProducto(productoId: number){
    this.delProduct$ = this.productService.delProduct(productoId, {status: 0}).pipe(
      map((res:any)=>{
        if (res.Status = 200){
          var producto = this.dataSource.data.filter(x => x.PRODUCT_ID == productoId);
          if (producto.length >0){
            producto[0].STATUS = 'Inactivo';
          }
        }
      })
    )
  }

  showData(item: any){
    this.fileString = undefined;
    this.productForm.reset({ productId: '', name: '', description: '', obs: '', unitPrice: 0, categoryId: '', taxId: '', inventory: false, variable: '', imagen: '', status: true, souvenier: false, mesero: false});
    this.imageForm.reset();
    var spinnerRef = this.spinnerService.start();
    this.product$ = this.productsService.getProduct(item).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          if (res.Data.length > 0){
            let dataProd = res.Data[0];
            this.productForm.setValue({
              productId: dataProd.PRODUCT_ID,
              name: dataProd.NAME,
              description: dataProd.SHORT_DESCRIP,
              obs: dataProd.OBS,
              unitPrice: dataProd.UNIT_PRICE,
              categoryId: dataProd.CATEGORY_ID,
              taxId: dataProd.TAX_ID,
              status: dataProd.STATUS,
              souvenier: dataProd.SOUVENIER,
              mesero: dataProd.MESERO,
              inventory: dataProd.INVENTORY,
              variable: dataProd.VARIABLE,
              imagen: dataProd.IMG_PATH
            });
          }
          return res.Data;
        }
      }),
      catchError(err => {
        return throwError(err || err.Message);
      })
    );
  }

  newProduct(){
    this.imageForm.reset();
    this.fileString = undefined;
    this.productForm.reset({ productId: '0', name: '', description: '', obs: '', unitPrice: 0, categoryId: '', taxId: '', inventory: false, variable: '', imagen: '', status: true, souvenier: false, mesero: false });
  }

  onSubmit(){
    this.productForm.markAllAsTouched();
    if(!this.productForm.valid) { return; }
    var prodId= '';
    if(this.productForm.value.productId == ''){
      prodId = '0';
    }else{
      prodId = this.productForm.value.productId;
    }
    let formData = {
      name: this.productForm.value.name,
      description: (this.productForm.value.description == null ? '' : this.productForm.value.description),
      obs: (this.productForm.value.obs == null ? '' : this.productForm.value.obs),
      unitPrice: Number(this.productForm.value.unitPrice),
      inventory: this.productForm.value.inventory,
      taxId: this.productForm.value.taxId,
      variable: (this.productForm.value.variable == null ? '' : this.productForm.value.variable),
      catId: this.productForm.value.categoryId,
      status: this.productForm.value.status,
      mesero: this.productForm.value.mesero,
      souvenier: this.productForm.value.souvenier,
      companyId: this.companyId
    }
    let exito: string = 'Producto ' + (this.productForm.value.productId == '' ? 'creado' : 'actualizado') + ' con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.products$ = this.productsService.postProduct(prodId, formData).pipe(
      delay(1000),
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.productForm.patchValue({productId: res.Data})
          this.onSubmitImage(res.Data);
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    );
  }

  onSubmitImage(prodId){
    if (this.file == undefined) { return; }
    const formData: FormData = new FormData();
    formData.append('image', this.file);
    let type: string ='';
    if (this.fileString.toString().indexOf('data:image/') >= 0){
      type = this.fileString.toString().substring(11,15);
    }
    if (type === 'jpeg' || type === 'jpg;'){
      type = '.jpg';
    }
    if (type === 'png;'){
      type = '.png';
    }
    this.image$ = this.productsService.uploadImage(prodId, formData).pipe(
      map((response: any) =>  {
        if (response.Status == 200){
          console.log('OK');
        }
      }),
      catchError(err => { 
        return throwError(err || err.message);
      })
    );
  }

  onSearchImage(){
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      this.file = fileUpload.files[0];
      if (this.file === undefined) {return;}
      this.fileName = this.file['name'];
      if (this.file['type'] != "image/png" && this.file['type'] != "image/jpg" && this.file['type'] != "image/jpeg") { 
        // this.openDialog($localize`:@@business.businesstextpopup:`, $localize`:@@profile.fileextension:`, false, true, false);
        return; 
      }
      
      const reader: FileReader = new FileReader();
      reader.onload = (event: Event) => {
        if (this.file['size'] > 900000){
          // this.openDialog($localize`:@@business.businesstextpopup:`, $localize`:@@profile.filemaximun:`, false, true, false);
          return;
        }
        this.fileString = reader.result;
      }
      reader.readAsDataURL(fileUpload.files[0]);
    };
    fileUpload.click();
  }

  getErrorMessage(frm: FormGroup, component: string, lbl: HTMLElement, minlength: number, maxlength: number, min: number, max: number){
    let label: string = lbl.innerText;
    let requerido = ' es requerido';
    let minLen = 'Largo mínimo requerido ';
    let maxLen = 'Largo máximo requerido ';
    let minVal = 'Valor mínimo requerido ';
    let maxVal = 'Valor máximo requerido ';
    let invAddr = 'La dirección no es válida';
    let email = 'Formato de correo electrónico inválido';
    return frm.controls[component].hasError('required') ? label + requerido: 
      frm.controls[component].hasError('minlength') ? minLen + minlength:
        frm.controls[component].hasError('maxlength') ? maxLen + maxlength:
          frm.controls[component].hasError('min') ? minVal + min:
            frm.controls[component].hasError('max') ? maxVal + max:
              frm.controls[component].hasError('email') ? email:
                frm.controls[component].hasError('invalidAddress') ? invAddr:
                  '';
  }
  
}