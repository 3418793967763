import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  readonly apiURL = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getCustomersTAX(companyId: string, taxId: string) {
    return this.http.get(this.apiURL + 'customers/' + companyId + '/' + taxId)
                    .pipe(map(results => results), catchError(this.errorHandler));
  }

  getCustomer(customerId: string){
    return this.http.get(this.apiURL + 'customer/'+customerId)
                    .pipe(catchError(this.errorHandler));
  }

  getActiveCustomers(companyId: string){
    return this.http.get(this.apiURL + 'customers/' + companyId)
                    .pipe(catchError(this.errorHandler));
  }

  getCustomers(companyId: string, texto:any, offset:any, limit:any){
    return this.http.get(this.apiURL + 'customers/' + companyId + '/' + texto + '/' + offset + '/' + limit + '/0')
                    .pipe(catchError(this.errorHandler));
  }

  postCustomer(customerId: any, data: any){
    return this.http.post(this.apiURL + 'customer/' + customerId, data)
                    .pipe(catchError(this.errorHandler));
  }
  
  delCustomer(customerId: number, data: any){
    return this.http.delete(this.apiURL + 'customer/'+ customerId, data)
                    .pipe(catchError(this.errorHandler));
  }

  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
  
}
