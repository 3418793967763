<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-12 card-spinner">
            <div class="card-loading">
                <svg width="298" height="275" viewBox="0 0 597 550" fill="none" class="svg-log" xmlns="http://www.w3.org/2000/svg">
                    <path d="M199.112 143C149.576 143 125 187.555 125 210C125 232.445 149.576 277 199.112 277C273.224 277 322.376 143 396.488 143C446.024 143 470.6 187.555 470.6 210C470.6 232.445 446.024 277 396.488 277C322.376 277 273.224 143 199.112 143Z" stroke="#01524d" stroke-width="16" stroke-linecap="round" stroke-dasharray="287.38 225.8" class="svg-elem-1"></path>
                    <path d="M219 351C223.167 366.667 243.3 398.5 290.5 400.5C337.7 402.5 367.5 368.333 376.5 351" stroke="#01524d" stroke-width="20" stroke-linecap="round" class="svg-elem-2"></path>
                    <path d="M581.5 323C581.5 389.456 564.235 441.101 522.855 476.618C480.94 512.594 411.485 534.5 301.5 534.5C218.516 534.5 146.34 518.868 95.4126 486.547C45.3279 454.761 15 406.449 15 337.5C15 256.912 53.9673 176.012 110.305 114.987C166.858 53.7308 238.527 15 301.5 15C363.931 15 433.934 57.6441 489.268 119.73C544.465 181.662 581.5 259.002 581.5 323Z" stroke="#01524d" stroke-width="30" class="svg-elem-3"></path>
                </svg>
            </div>
            <div class="spinner-message">{{data.message}}</div>
        </div>
    </div>
</div>