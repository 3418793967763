import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  // private currentUserTknSubject: BehaviorSubject<any>;
  // private currentAccessTknSubject: BehaviorSubject<any>;
  // private currentRefreshTknSubject: BehaviorSubject<any>;

  public currentUser: Observable<any>;
  // public currentTkn: Observable<any>;
  // public currentAccessTkn: Observable<any>;
  // public currentRefresh: Observable<any>;

  readonly apiURL = environment.apiUrl;
  constructor(
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('IBRUNCH_USS')));
    this.currentUser = this.currentUserSubject.asObservable();

    // this.currentUserTknSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('IBRUNCH_TKN')));
    // this.currentTkn = this.currentUserTknSubject.asObservable();

    // this.currentAccessTknSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('IBRUNCH_ACT')));
    // this.currentAccessTkn = this.currentAccessTknSubject.asObservable();

    // this.currentRefreshTknSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('IBRUNCH_REF')));
    // this.currentRefresh = this.currentRefreshTknSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  // public get currentUserTknValue(): any {
  //   return this.currentUserTknSubject.value;
  // }

  // public get currentAccessValue(): any{
  //   return this.currentAccessTknSubject.value;
  // }

  // public get currentRefreshValue(): any{
  //   return this.currentRefreshTknSubject.value;
  // }

  getUserProfile() {
    if (sessionStorage.getItem('IBRUNCH_USS') != null) {
      var user = JSON.parse(sessionStorage.getItem('IBRUNCH_USS'));
      return user;
    }
    return null;
  }
  setUserProfile(userProfile) {
    sessionStorage.setItem('IBRUNCH_USS', JSON.stringify(userProfile));
    this.currentUserSubject.next(userProfile.UsuarioId);
  }
  getRolId(){
    if (sessionStorage.getItem('IBRUNCH_USS') != null) {
      var user = JSON.parse(sessionStorage.getItem('IBRUNCH_USS'));
      return user.rolId;
    }
    return null;
  }
  getCompanyId(){
    if (sessionStorage.getItem('IBRUNCH_USS') != null) {
      var user = JSON.parse(sessionStorage.getItem('IBRUNCH_USS'));
      return user.companyId;
    }
    return null;
  }
  logout() {
    this.currentUserSubject.next(null);
    sessionStorage.removeItem('IBRUNCH_USS');
    // sessionStorage.removeItem('IBRUNCH_TKN');
    // sessionStorage.removeItem('IBRUNCH_ACT');
    // sessionStorage.removeItem('IBRUNCH_REF');
    window.localStorage.setItem('CREDENTIALS_FLUSH', 'tknAndon');
    window.localStorage.removeItem('CREDENTIALS_FLUSH');
  }

  login(userName: string, password: string) {
    return this.http.post<any>(this.apiURL + 'login', { "userName": userName, "password": password })
      .pipe(
          map((user: any) => {
              if (user.Status == 200) {
                  // store user details in local storage to keep user logged in
                  // sessionStorage.setItem('IBRUNCH_TKN', JSON.stringify(user.token));
                  // this.currentUserTknSubject.next(user.token);
                  // sessionStorage.setItem('IBRUNCH_ACT', JSON.stringify(user.access));
                  // this.currentAccessTknSubject.next(user.access);
                  // sessionStorage.setItem('IBRUNCH_REF', JSON.stringify(user.refresh));
                  // this.currentRefreshTknSubject.next(user.refresh);
                  if (user.Data.length > 0){
                    sessionStorage.setItem('IBRUNCH_USS', JSON.stringify(user.Data[0]));
                    this.currentUserSubject.next(user.Data[0]);
                  }
              }
              return user;
          }),
          catchError(this.errorHandler)
      );
  }
  
  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
}
