import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompaniesService, InventoryService } from '@app/services';
import { Observable } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchComponent, SpinnerService } from '@app/shared';
import { AuthService } from '@app/core/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfirmValidParentMatcher } from '@app/validators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit{
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;

  dialog$!: Observable<any>;
  inventory$: Observable<any>;
  locs$: Observable<any>;

  companyId: string = '';
  userId: string = '';

  length: number = -1;
  pageSize: number = 5;
  _page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  displayedColumns: string[] = ['LOCATION', 'PRODUCT', 'RACK', 'TYPE_OP', 'IN_OUT', 'QTY', 'UNIT_COST', 'TOTAL_COST', 'UNIT_PRICE', 'TRANS_DATE'];
  types: any[]= [{id: 'A', description: 'Ajustes', status: false}, {id: 'O', description: 'Pedido', status: true}, {id: 'E', description: 'Compras', status: true}, {id: 'I', description: 'Facturas', status: true}];
  minDate: Date = new Date();
  maxDate: Date = new Date();

  searchText: string = '';
  iDate: string = '0';
  eDate: string = '0';
  currency: string = '';

  sDate = moment(null);
  fDate = moment(null);
  costoDt = new FormControl(moment());

  confirmValidParentMatcher = new ConfirmValidParentMatcher();

  constructor(
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private inventoryServices: InventoryService,
    private companiesService: CompaniesService,
    private spinnerService: SpinnerService
  ) { }

  invForm = this.fb.group({
    transactionId: [''],
    locationId: ['', [Validators.required]],
    productId: ['', [Validators.required]],
    productName: [''],
    rackId: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    unitCost: [0],
    totalCost: [0],
    unitPrice: [0],
    qty: [0, [Validators.required, Validators.min(1)]],
    typeOp: ['', [Validators.required]],
    inOut: [false],
    transDate: [new Date(), [Validators.required]]
  });

  get inv(){
    return this.invForm.controls;
  }

  ngOnInit(): void {
    this.companyId = this.authService.getCompanyId();
    this.userId = this.authService.getUserProfile().userId;
    this.currency = this.authService.getUserProfile().currency;
    this.minDate.setMonth(this.minDate.getMonth()-1);
    this.maxDate.setDate(this.maxDate.getDate()+1);

    this.loadLocations();

    this._page = 0;
    this.loadInventory(this._page);
  }

  loadLocations(){
    var spinnerRef = this.spinnerService.start();
    this.locs$ = this.companiesService.getActiveLocations(this.companyId).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          return res.Data;
        }
      }),
      catchError(err => {
        console.log(err);
        return err;
      })
    );
  }

  searchData(){
    this.iDate = '0';
    this.eDate = '0';
    if (this.sDate.isValid() && this.fDate.isValid()){
      this.iDate = this.sDate.toDate().getFullYear() + '-' + (this.sDate.toDate().getMonth()+1).toString().padStart(2, '0') + '-' + this.sDate.toDate().getDate().toString().padStart(2, '0');
      this.eDate = this.fDate.toDate().getFullYear() + '-' + (this.fDate.toDate().getMonth()+1).toString().padStart(2, '0') + '-' + this.fDate.toDate().getDate().toString().padStart(2, '0');
    }
    this._page = 0;
    this.loadInventory(this._page);
  }

  getType(type: string){
    return this.types.find(x => x.id == type).description;
  }

  loadInventory(curPage: number){
    let spinnerRef = this.spinnerService.start();
    this.inventory$ = this.inventoryServices.getInventory(this.companyId, (this.searchText == '' ? '0' : this.searchText), this.iDate, this.eDate, curPage, this.pageSize).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.dataSource.data = res.Data;
          this.dataSource.sort = this.sort;
          if(res.Data.length > 0){
            this.length = res.Data[0].TOTAL;
          } else {
            this.length = 0;
          }
          return res.Data;
        }
        if (res.Status == 401){
          this.length = 0;
          this.dataSource.data = null;
          return res.Data;
        }
      }),
      catchError(err => {
        console.log(err);
        return err;
      })
    );
  }

  goToPage(page: number, elements: number){
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    this.loadInventory((this._page == 0 ? 0 : this._page*this.pageSize));
  }

  searchProduct(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "800px";
    dialogConfig.maxWidth = "800px";
    dialogConfig.height = "500px";
    dialogConfig.data = {cols: ['ACTIONS', 'NAME', 'IMPUESTO', 'CATEGORIA', 'STATUS'], titles: ['ACCIONES', 'NOMBRE', 'IMPUESTO', 'CATEGORIA', 'ESTATUS'], objeto: 'products', delete: false};
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
    this.dialog$ = dialogRef.afterClosed().pipe(
      map((res: any) => {
        if (res != undefined){
          this.invForm.patchValue({productId: res.PRODUCT_ID, productName: res.NAME});
        }
      })
    );
  }

  saveAdj(){
    this.invForm.markAllAsTouched();
    if(!this.invForm.valid) { return; }

    let formData = {
      transactionId: (this.invForm.value.transactionId == '' ? '0' : this.invForm.value.transactionId),
      locationId: this.invForm.value.locationId,
      productId: this.invForm.value.productId,
      rackId: this.invForm.value.rackId,
      unitCost: Number(Number(this.invForm.value.unitCost).toFixed(4)),
      unitPrice: Number(Number(this.invForm.value.unitPrice).toFixed(4)),
      qty: Number(Number(this.invForm.value.qty).toFixed(4)),
      totalCost: Number((Number(this.invForm.value.unitCost)*Number(this.invForm.value.qty)).toFixed(4)),
      typeOp: this.invForm.value.typeOp,
      inOut: this.invForm.value.inOut,
      transDate: this.invForm.value.transDate,
      userId: this.userId,
      companyId: this.companyId
    }
    let exito: string = 'Transacción ' + (this.invForm.value.transactionId == '' ? 'creada' : 'actualizado') + ' con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.inventory$ = this.inventoryServices.postAdjustment(formData).pipe(
      delay(1000),
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.invForm.patchValue({transactionId: res.Data})
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    );
  }

  clearAdj(){
    this.invForm.reset({transactionId: '', locationId: '', productId: '', productName: '', rackId: '', unitCost: 0, totalCost: 0, unitPrice: 0, qty: 0, typeOp: '', inOut: false, transDate: new Date()});
  }
  
  setMonthAndYear(normalizedMonthAndYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.costoDt.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.costoDt.setValue(ctrlValue);
    datepicker.close();
  }

  getErrorMessage(frm: FormGroup, component: string, lbl: HTMLElement, minlength: number, maxlength: number, min: number, max: number){
    let label: string = lbl.innerText;
    let requerido = ' es requerido';
    let minLen = 'Largo mínimo requerido ';
    let maxLen = 'Largo máximo requerido ';
    let minVal = 'Valor mínimo requerido ';
    let maxVal = 'Valor máximo requerido ';
    let invAddr = 'La dirección no es válida';
    let email = 'Formato de correo electrónico inválido';
    return frm.controls[component].hasError('required') ? label + requerido: 
      frm.controls[component].hasError('minlength') ? minLen + minlength:
        frm.controls[component].hasError('maxlength') ? maxLen + maxlength:
          frm.controls[component].hasError('min') ? minVal + min:
            frm.controls[component].hasError('max') ? maxVal + max:
              frm.controls[component].hasError('email') ? email:
                frm.controls[component].hasError('invalidAddress') ? invAddr:
                  frm.controls[component].hasError('matDatepickerMin') ? 'fecha incorrecta':
                    frm.controls[component].hasError('matDatepickerMax') ? 'fecha incorrecta':
                      '';
  }

}
