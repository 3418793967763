import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  readonly apiURL = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getDoctos(companyId: string, offset: number, limit: number){
    return this.http.get(this.apiURL + 'documents/'+companyId+'/'+offset+'/'+limit)
                    .pipe(catchError(this.errorHandler));
  }

  setDocto(form){
    return this.http.post(this.apiURL + 'document', form)
                    .pipe(catchError(this.errorHandler));
  }
  
  getDocumentsType(companyId: string, type: string){
    return this.http.get(this.apiURL + 'documents/'+companyId+'/'+type)
                    .pipe(catchError(this.errorHandler));
  }

  errorHandler(error) {
    return throwError(error || 'Server Error');
  }

}