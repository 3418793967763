<div class="container">
    <div class="login-frm py-3 px-5">
        <div class="row mb-2">
            <div class="col-md-12">
                <img class="img-02" src="/assets/images/logo.svg">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="title">
                    Inicio de Sesión
                </div>
            </div>
        </div>
        <form>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="email">
                        <mat-label>Usuario</mat-label>
                        <input matInput placeholder="Correo" name="user" [(ngModel)]="userName" cdkFocusInitial>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="email">
                        <mat-label>Contraseña</mat-label>
                        <input matInput placeholder="Contraseña" type="password" name="codigo" [(ngModel)]="password">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-label *ngIf="validCode == 1" class="error">{{error}}</mat-label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button mat-flat-button class="login-btn" id="save" color="primary" type="submit" text-center w-100 (click)="loginUser()">Iniciar Sesión</button>
                </div>
            </div>
        </form>
    </div>
</div>