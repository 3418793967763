<div class="example-loading-shade" *ngIf="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<h1 mat-dialog-title>Información de pago</h1>
<ng-container *ngIf="customer$ | async"></ng-container>
<ng-container *ngIf="invoice$ | async"></ng-container>
<div mat-dialog-content>
    <div class="row">
        <mat-form-field appearance="outline">
            <mat-label>Tax Id:</mat-label>
            <input matInput [(ngModel)]="taxId" (focusout)="getCustomer()">
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field appearance="outline">
            <mat-label>Nombre:</mat-label>
            <input matInput [(ngModel)]="Name">
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field appearance="outline">
            <mat-label>Dirección:</mat-label>
            <input matInput [(ngModel)]="Address">
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field appearance="outline">
            <mat-label>Autorización:</mat-label>
            <input matInput [(ngModel)]="Auth">
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-flat-button (click)="cancelClick()" color="primary">Cancelar</button>
    <button mat-flat-button cdkFocusInitial (click)="procesarPago()" color="accent">Procesar</button>
</div>