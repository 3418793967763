<ng-container *ngIf="kpiSales$ | async"></ng-container>
<ng-container *ngIf="kpiTaxes$ | async"></ng-container>
<ng-container *ngIf="kpiCustomer$ | async"></ng-container>
<ng-container *ngIf="kpiProducts$ | async"></ng-container>

<div class="container">
    <div class="row mt-4">
        <div class="col-md-6">
            <canvasjs-chart *ngIf="showKpiSales == 1" [options]="chartKpiSales" [styles]="{width: '100%', height:'350px'}"></canvasjs-chart>  
        </div>
        <div class="col-md-6">
            <canvasjs-chart *ngIf="showKpiTaxes == 1" [options]="chartKpiTaxes" [styles]="{width: '100%', height:'350px'}"></canvasjs-chart>  
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-6">
            <canvasjs-chart *ngIf="showKpiCustomer == 1" [options]="chartKpiCustomer" [styles]="{width: '100%', height:'350px'}"></canvasjs-chart>  
        </div>
        <div class="col-md-6">
            <canvasjs-chart *ngIf="showKpiProducts == 1" [options]="chartKpiProducts" [styles]="{width: '100%', height:'350px'}"></canvasjs-chart>  
        </div>
    </div>
</div>