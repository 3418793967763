import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-entregas',
  templateUrl: './entregas.component.html',
  styleUrls: ['./entregas.component.scss']
})
export class EntregasComponent implements OnInit {
  entrega$: Observable<any>;
  entregado$: Observable<any>;

  order: number = 0;
  order2: number = 0;
  order3: number = 0;
  order4: number = 0;

  orden: number = 0;
  orden2: number = 0;
  orden3: number = 0;
  orden4: number = 0;

  cust: string = '';
  cust2: string = '';
  cust3: string = '';
  cust4: string = '';

  table: string;
  table2: string;
  table3: string;
  table4: string;

  lstOrder: number = 0;
  lstTable: string = '';
  constructor(
    private global: GlobalService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    // setInterval(() => { 
    //   this.entrega$ = this.global.GetCocinaFin().pipe(
    //     map((res: any) => {
    //       if (res.Status == 200){
    //         if (res.Data.length > 0){
    //           this.order = res.Data[0].ORDER_ID;
    //           this.orden = res.Data[0].SEQUENCE;
    //           this.table = res.Data[0].ITABLE;
    //           this.cust = res.Data[0].CUSTOMER_NAME;
    //           this.order2 = 0;
    //           this.table2 = '';
    //           if(res.Data.length > 1){
    //             this.order2 = res.Data[1].ORDER_ID;
    //             this.orden2 = res.Data[1].SEQUENCE;
    //             this.table2 = res.Data[1].ITABLE;
    //             this.cust2 = res.Data[1].CUSTOMER_NAME;
    //           }
    //           this.order3 = 0;
    //           this.table3 = '';
    //           if(res.Data.length > 2){
    //             this.order3 = res.Data[2].ORDER_ID;
    //             this.orden3 = res.Data[2].SEQUENCE;
    //             this.table3 = res.Data[2].ITABLE;
    //             this.cust3 = res.Data[2].CUSTOMER_NAME;
    //           }
    //           this.order4 = 0;
    //           this.table4 = '';
    //           if(res.Data.length > 3){
    //             this.order4 = res.Data[3].ORDER_ID;
    //             this.orden4 = res.Data[3].SEQUENCE;
    //             this.table4 = res.Data[3].ITABLE;
    //             this.cust4 = res.Data[3].CUSTOMER_NAME;
    //           }
    //         } else {
    //           this.order = 0;
    //           this.orden = 0;
    //           this.table = '';
    //           this.cust = '';
    //           this.order2 = 0;
    //           this.orden2 = 0;
    //           this.table2 = '';
    //           this.cust2 = '';
    //           this.order3 = 0;
    //           this.orden3 = 0;
    //           this.table3 = '';
    //           this.cust3 = '';
    //           this.order4 = 0;
    //           this.orden4 = 0;
    //           this.table4 = '';
    //           this.cust4 = '';
    //         }
    //         return res.Data;
    //       }
    //     })
    //   );
    // }, 10000);
  }

  setEntrega(ordId, table){
    // this.lstOrder = ordId;
    // this.lstTable = table;
    // this.entregado$ = this.global.PostDespacho(ordId, {status: 'C'}).pipe(
    //   map((res: any) => {
    //     if (res.Status == 200){
    //       return res.Data;
    //     }
    //   })
    // );
  }

}
