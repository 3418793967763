import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GlobalService, ReportsService } from '@app/services';
import { PrintComponent, SpinnerService } from '@app/shared';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import { DatePipe, formatNumber } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/services';
import * as moment from 'moment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  result : any[]=[];
  reports$: Observable<any>;

  tipoRpt: number = 0;
  ventasTotales: number = 0;
  descuentos: number = 0;
  impuestos: number = 0;
  ventasNetas: number = 0;

  companyId: number = 0;
  locationId: number = 0;

  pageSize: number = 5;
  length: number = 0;
  _page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  
  currency: string = '';

  headers: any[] = [
    {codigo:'ORDER_ID', header: 'Order No.'}, 
    {codigo:'INVOICE_NUMBER', header: 'Factura'}, 
    {codigo:'NAME', header: 'Cliente'}, 
    {codigo:'TAX_ID', header: 'RTN'},
    {codigo:'DOCUMENTO', header:'Documento'}, 
    {codigo:'TOTAL_DISCOUNT', header: 'Descuento'}, 
    {codigo:'TIP', header: 'TIP'},
    {codigo:'TOTAL_TAX', header: 'Impuesto'}, 
    {codigo:'TOTAL_AMOUNT', header: 'Total'},
    {codigo:'DOCTO_DATE', header: 'Fecha'},
    {codigo:'PAYMENT_TYPE', header: 'Pago'},
    {codigo:'AUTH', header: 'Autorización'},
    {codigo:'DIGITS', header: '# Tarjeta'}];

  displayedColumns: any[] = this.headers.map(x => x.codigo);
  dataSource = new MatTableDataSource<any>(this.result);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private global: GlobalService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private stickerDialog: MatDialog,
    private spinnerService: SpinnerService,
    private reportsService: ReportsService,
    private authService: AuthService
  ) { }

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  
  ngOnInit(): void {
    this.currency = this.authService.getUserProfile().currency;
    this.companyId = this.authService.getUserProfile().companyId;
    this.locationId = this.authService.getUserProfile().locationId;
    this.tipoRpt = Number(this.route.snapshot.params.tipo);
    switch (this.tipoRpt) {
      case 1:
        this.loadDailySales(0);
        break;
    
      default:
        break;
    }
  }

  loadDailySales(curPage: number){
    const startValue: Date | null = this.range.get('start')?.value;
    const endValue: Date | null = this.range.get('end')?.value;

    const startMoment: moment.Moment | null = startValue ? moment(startValue) : null;
    const endMoment: moment.Moment | null = endValue ? moment(endValue) : null;

    var spinnerRef = this.spinnerService.start();
    this.reports$ = this.reportsService.getDailySales(this.companyId, (startMoment == null ? '0' : startMoment.format('YYYY-MM-DD')), (endMoment == null ? '0' : endMoment.format('YYYY-MM-DD')), this.pageSize, curPage).pipe(
      map((res:any)=>{
        this.spinnerService.stop(spinnerRef);
        if(res.Status == 200){
          this.dataSource.data = res.Data;
          this.dataSource.sort = this.sort;
          this.length = res.Data[0].TOTAL;
        }
        else {
          this.dataSource.data = null;
          this.dataSource.sort = this.sort;
          this.length = 0;
        }
      })
    )
  }

  goToPage(page: number, elements: number){
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    this.loadDailySales((this._page == 0 ? 0 : this._page*this.pageSize));
  }

  exportexcel(): void {    
    let dateApp: Date;
    let year = dateApp.getFullYear().toString();
    let month = (dateApp.getMonth()+1).toString().padStart(2, '0'); 
    let day = dateApp.getDate().toString().padStart(2, '0');
    let hr = dateApp.getHours().toString().padStart(2, '0');
    let min = dateApp.getMinutes().toString().padStart(2, '0');
    let valFecha = year + '-' + month + '-' + day + '-' + hr + '-' + min;

    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');
 
    /* save to file */  
    XLSX.writeFile(wb, "rpt-"+valFecha+".xlsx");
  }

  trackRow(index: number, item: any){
    return item;
  }

  convertirTexto(tipo:any, texto:any){
    if(tipo == 'STATUS'){
      if(texto == 0){
        return "Pendiente";
      }
      if(texto == 1){
        return "En Proceso";
      }
      if(texto == 2){
        return "Despachada";
      }
      if(texto == 3){
        return "Impresa";
      }
      if(texto == 4){
        return "Cancelada";
      }
      if(texto == 5){
        return "Finalizada";
      }
    }
    if(tipo == "INVENTORY"){
      if(texto == true){
        return 'SI';
      }else{
        return 'NO';
      }
    }
  if(tipo == "DATE" || tipo == 'ORDER_DATE'){
    const datepipe: DatePipe = new DatePipe('en-US');
    let formattedDate = datepipe.transform(texto, 'dd MMM yyyy');
    return formattedDate;
  }
  if(tipo == "PAYMENT_TYPE"){
    if(texto == false){
      return 'Efectivo';
    }else{
      return 'Tarjeta';
    }
  }
  if(tipo == "TOTAL_TAX" || tipo == "TIP" || tipo == "TOTAL_AMOUNT" || tipo == "TOTAL_DISCOUNT" || tipo =="UNIT_PRICE"){
    return formatNumber(texto, 'en-US', '1.2-2');
  }
  if(tipo == "TIEMPO_REAL"){
    var seconds = parseInt(texto);
    var hour = Math.floor(seconds / 3600);
    hour = (hour < 10)? 0 + hour : hour;
    var minute = Math.floor((seconds / 60) % 60);
    minute = (minute < 10)? 0 + minute : minute;
    var second = seconds % 60;
    second = (second < 10)? 0 + second : second;
    return hour + 'hr ' + minute + 'min ';
  }
    return texto
  }
}
