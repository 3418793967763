<div mat-dialog-content class="p-3">
    <div class="row">
        <div class="col-md-12 mx-2 mb-4 mt-0 title">
            Datos de Factura
        </div>
    </div>
    <form [formGroup]="invoiceForm">
        <div class="row">
            <mat-form-field class="col-md-12" appearance="outline">
                <mat-label>RTN:</mat-label>
                <input #rtnVal matInput formControlName="rtn" [matAutocomplete]="auto">
                <mat-spinner matSuffix *ngIf="loading" [diameter]="24" class="spinner-text"></mat-spinner>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option class="pb-2" *ngFor="let customer of customers$ | async" [value]="customer.RTN">
                        <div>RTN: {{customer.RTN}}<br>NOMBRE: {{customer.NAME}}</div>
                        <div class="line-option"></div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col-md-12" appearance="outline">
                <mat-label>Nombre:</mat-label>
                <input matInput formControlName="nombre">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col-md-12" appearance="outline">
                <mat-label>Dirección:</mat-label>
                <input matInput formControlName="direccion">
            </mat-form-field>
        </div>
    </form>
    <ng-container *ngIf="invoice$ | async"></ng-container>
</div>
<div mat-dialog-actions class="row">
    <div class="col-md-12 pe-2 d-flex justify-content-end">
        <button class="" mat-flat-button color="accent" (click)="onSubmit()">Actualizar</button>
    </div>
</div>
