<ng-container *ngIf="monitor$ | async"></ng-container>
<mat-sidenav-container class="sidenav-container" autosize>
    <mat-sidenav #drawer class="sidenav" fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
        <div class="loc-logo">
          <mat-toolbar class="main-logo p-0">
            <img class="img-02" src="/assets/images/logo.svg">
          </mat-toolbar>    
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <mat-list>
                        <ng-container *ngIf="accesos$ | async"></ng-container>   
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" [routerLink]="['/welcome']">
                            <a [routerLink]="['/welcome']" class="value-submenu-item" (click)="(screenWidth < 500) ? drawer.toggle() : ''">Welcome</a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" *ngIf="getAccesos(1)" routerLinkActive="submenu-active" [routerLink]="['/company']">
                            <a [routerLink]="['/company']" class="value-submenu-item" (click)="(screenWidth < 500) ? drawer.toggle() : ''">Compañía</a>
                        </mat-list-item>

                        <mat-list-item class="submenu-item item-user" (click)="expandendG = !expandendG" *ngIf="getAccesos(8)">
                            <a routerLinkActive="submenu-active" class="value-submenu-item spacer" (click)="(screenWidth < 500) ? drawer.toggle() : ''">Compras</a>
                            <mat-icon *ngIf="expandendG == false" class="icon-expand">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="expandendG" class="icon-expand">arrow_drop_up</mat-icon>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" *ngIf="expandendG" [routerLink]="['/gasto/0']">
                            <a [routerLink]="['/gasto/0']" routerLinkActive="submenu-active" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Proveedores
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" *ngIf="expandendG" [routerLink]="['/gastos/list']">
                            <a [routerLink]="['/gastos/list']" routerLinkActive="submenu-active" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Compras
                            </a>
                        </mat-list-item>
                        
                        <mat-list-item class="submenu-item item-user" (click)="expandendS = !expandendS" *ngIf="getAccesos(3)">
                            <a class="value-submenu-item spacer" (click)="(screenWidth < 500) ? drawer.toggle() : ''">Ventas</a>
                            <mat-icon *ngIf="!expandendS" class="icon-expand">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="expandendS" class="icon-expand">arrow_drop_up</mat-icon>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" [routerLink]="['/customers']" *ngIf="expandendS">
                            <a [routerLink]="['/customers']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Clientes
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" *ngIf="expandendS" routerLinkActive="submenu-active" [routerLink]="['/orders']" [routerLinkActiveOptions]="{ exact: true }">
                            <a [routerLink]="['/orders']" class="value-submenu-item ps-3" (click)="drawer.toggle()">
                                POS
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" [routerLink]="['/invoices']" *ngIf="expandendS">
                            <a [routerLink]="['/invoices']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Facturas
                            </a>
                        </mat-list-item>
                        <!-- <mat-list-item class="submenu-item" routerLinkActive="submenu-active" [routerLink]="['/orders']" [routerLinkActiveOptions]="{ exact: true }" *ngIf="expandendS">
                            <a [routerLink]="['/orders']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Ordenes
                            </a>
                        </mat-list-item> -->
                        <mat-list-item class="submenu-item item-user" (click)="expandendP = !expandendP" *ngIf="getAccesos(2)">
                            <a class="value-submenu-item spacer" (click)="(screenWidth < 500) ? drawer.toggle() : ''">Inventarios</a>
                            <mat-icon *ngIf="!expandendP" class="icon-expand">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="expandendP" class="icon-expand">arrow_drop_up</mat-icon>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" [routerLink]="['/categories']" *ngIf="expandendP">
                            <a [routerLink]="['/categories']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Categorías
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" [routerLink]="['/products']" *ngIf="expandendP">
                            <a [routerLink]="['/products']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Productos
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" [routerLink]="['/inventory']" *ngIf="expandendP">
                            <a [routerLink]="['/inventory']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Transacciones
                            </a>
                        </mat-list-item>
                        
                        <mat-list-item class="submenu-item item-user" (click)="expandendR = !expandendR" *ngIf="getAccesos(5)">
                            <a routerLinkActive="submenu-active" class="value-submenu-item spacer" (click)="(screenWidth < 500) ? drawer.toggle() : ''">Reportes</a>
                            <mat-icon *ngIf="expandendR == false" class="icon-expand">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="expandendR" class="icon-expand">arrow_drop_up</mat-icon>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" *ngIf="expandendR" [routerLink]="['/report/sales/1']">
                            <a [routerLink]="['/report/sales/1']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Resumen de Ventas
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" *ngIf="expandendR" [routerLink]="['/report/product/2']">
                            <a [routerLink]="['/report/product/2']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Ventas por producto
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" *ngIf="expandendR" [routerLink]="['/report/inventory/3']">
                            <a [routerLink]="['/report/inventory/3']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Inventario
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" *ngIf="expandendR" [routerLink]="['/report/customer/4']">
                            <a [routerLink]="['/report/customer/4']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Ventas por cliente
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" *ngIf="expandendR" [routerLink]="['/report/taxes/5']">
                            <a [routerLink]="['/report/taxes/5']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Impuestos
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" *ngIf="expandendR" [routerLink]="['/report/register/6']">
                            <a [routerLink]="['/report/register/6']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Cierre de caja
                            </a>
                        </mat-list-item>

                        <mat-list-item class="submenu-item item-user" (click)="expandend = !expandend" *ngIf="getAccesos(6)">
                            <a routerLinkActive="submenu-active" class="value-submenu-item spacer" (click)="(screenWidth < 500) ? drawer.toggle() : ''">Usuarios</a>
                            <mat-icon *ngIf="expandend == false" class="icon-expand">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="expandend" class="icon-expand">arrow_drop_up</mat-icon>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" *ngIf="expandend" [routerLink]="['/user/', userId]">
                            <a [routerLink]="['/user/', userId]" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Nuevo Usuario
                            </a>
                        </mat-list-item>
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" *ngIf="expandend" [routerLink]="['/users/list']">
                            <a [routerLink]="['/users/list']" class="value-submenu-item ps-3" (click)="(screenWidth < 500) ? drawer.toggle() : ''">
                                Listado de Usuarios
                            </a>
                        </mat-list-item>
            
                        <mat-list-item class="submenu-item" routerLinkActive="submenu-active" *ngIf="getAccesos(7)" [routerLink]="['/indicadores']">
                            <a [routerLink]="['/indicadores']" class="value-submenu-item" (click)="(screenWidth < 500) ? drawer.toggle() : ''">Indicadores</a>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar class="mat-toolbar-main">
            <button
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <button
                type="button"
                mat-icon-button
                (click)="drawer.toggle()"
                *ngIf="!(isHandset$ | async)">
                <mat-icon>dehaze</mat-icon>
            </button>
            <span class="title-app">
                iBrunch
            </span>
            <span class="spacer"></span>
            <button mat-icon-button [matMenuTriggerFor]="userMenu">
                <mat-icon class="rounded circle">perm_identity</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu" xPosition="before">
                <!-- <button mat-menu-item [routerLink]="['/profile']"><mat-icon>perm_identity</mat-icon><ng-container>Profile</ng-container></button> -->
                <button mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon><ng-container>Logout</ng-container></button>
            </mat-menu>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>