import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-cant',
  templateUrl: './custom-cant.component.html',
  styleUrls: ['./custom-cant.component.scss']
})
export class CustomCantComponent implements OnInit {
  @ViewChild('cant') cant!: ElementRef;

  cantPer: number = 10;
  constructor(
    private dialogRef: MatDialogRef<CustomCantComponent>
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{ 
      this.cant.nativeElement.focus();
    },500);
  }

  setCantidad(){
    this.dialogRef.close({res: this.cantPer});
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
  valueChange(res: any){
    let val = res.target.value;
    if (val < 10) { 
      this.cantPer = 10;
    }
    if (val > 99){
      this.cantPer = 99;
    }
  }

  keyup(event: any){
    if (event.code == 'ArrowUp' && event.type == 'keyup') {
      this.cantPer += 1;
      if (this.cantPer > 99){
        this.cantPer = 99;
      }
    }
  }

  keydown(event: any){
    if (event.code == 'ArrowDown' && event.type == 'keydown') {
      this.cantPer -= 1;
      if (this.cantPer < 10) { 
        this.cantPer = 10;
      }
    }
  }

  keyupClick(){
    this.cantPer += 1;
    if (this.cantPer > 99){
      this.cantPer = 99;
    }
  }

  keydownClick(){
    this.cantPer -= 1;
    if (this.cantPer < 10) { 
      this.cantPer = 10;
    }
  }
}
