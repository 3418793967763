import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@app/services';
import { Observable, Subject, timer } from 'rxjs';
import { map, retry, share, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-despacho',
  templateUrl: './despacho.component.html',
  styleUrls: ['./despacho.component.scss']
})
export class DespachoComponent implements OnInit {
  despacho$: Observable<any>;
  orden$: Observable<any>;

  customer: string = '';
  order: string = '';
  orden: number = 0;
  table: string = '';
  pos1: string = '';
  pos2: string = '';
  pos3: string = '';
  pos4: string = '';

  orderDet: [] = [];
  constructor(
    private global: GlobalService
  ) { }

  ngOnInit(): void {
    this.getDespachos();
  }

  despacho(){
    // this.despacho$ = this.global.PostDespacho(this.order, {status: 'D'}).pipe(
    //   map((res: any) => {
    //     if (res.Status == 200){
    //       this.order = '';
    //       this.table = '';
    //       this.customer = '';
    //       this.orden = 0;
    //       this.orderDet = [];
    //     }
    //   })
    // );
  }

  getDespachos(){
    setInterval(() => { 
      this.orden$ = this.global.GetRobot().pipe(
          map((res: any) =>{
            if (res.Status == 200){
              this.pos1 = res.Data[0].TABLE_NO;
              this.pos2 = res.Data[1].TABLE_NO;
              this.pos3 = res.Data[2].TABLE_NO;
              this.pos4 = res.Data[3].TABLE_NO;
            }
        }),
        switchMap(() => this.global.GetDespachos().pipe(
          map((res: any) => {
            if (res.Status == 200){
              if (res.Data.length > 0){
                this.customer = res.Data[0].CUSTOMER;
                this.orden = res.Data[0].SEQUENCE;
                this.order = res.Data[0].ORDER_ID;
                this.table = res.Data[0].TABLE_NO;
                this.orderDet = res.Data;
              } else {
                this.customer = '';
                this.order = '';
                this.orden = 0;
                this.table = '';
                this.orderDet = [];
              }
              return res.Data;
            }
          })
        )),
      );
    }, 10000);
  }

}
