<div class="container">
    <ng-container *ngIf="users$ | async"></ng-container>
    <div class="row mb-4 align-items-center">
        <div class="col-md-6">
            <div class="header">
                Usuarios
            </div>
        </div>
        <div class="col-md-6 content-refresh">
            <mat-form-field class="pt-2 pe-2 w-search" appearance="standard">
                <mat-label>Buscar</mat-label>
                <input matInput placeholder="Buscar" [(ngModel)]="texto" name="buscar">
            </mat-form-field>
            <button mat-button color="accent" class="mx-2" (click)="searchData()">
                <mat-icon class="btn-search" matSuffix>search</mat-icon>
                Buscar
            </button>
            <button mat-button matTooltip="Nuevo Usuario" color="accent" class="me-2" routerLink="/user/0">
                <mat-icon>person_add</mat-icon>
                Usuario
            </button>
            <button mat-icon-button matTooltip="Actualizar" class="refresh" (click)="refreshSearch()">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </div>
    <mat-card>
        <section class="sticky-mnu">
            <ng-container *ngIf="delUser$ | async"></ng-container>
            <mat-table matSort [dataSource]="dataSource">
                <ng-container matColumnDef="usuario">
                    <mat-header-cell mat-header-cell *matHeaderCellDef class="col-md-3 title-cell"> USUARIO </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="col-md-3"> {{row.USERNAME}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="nombre">
                    <mat-header-cell mat-header-cell *matHeaderCellDef class="col-md-3 title-cell"> NOMBRE </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row" class="col-md-3"> {{row.NOMBRE}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="rol">
                    <mat-header-cell mat-header-cell *matHeaderCellDef class="col-md-2 title-cell"> ROL </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row" class="col-md-2"> {{row.DESCRIPCION}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="status">
                    <mat-header-cell mat-header-cell *matHeaderCellDef class="col-md-2 title-cell"> STATUS </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row" class="col-md-2"> {{row.STATUS == false ? 'Inactivo' : 'Activo'}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="actions">
                    <mat-header-cell mat-header-cell *matHeaderCellDef class="col-md-2 title-cell">ACCIONES</mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row" class="col-md-2">
                        <button mat-icon-button matTooltip="Ver Usuario" class="icon-view" routerLink="/user/{{row.USER_ID}}"><mat-icon>dvr</mat-icon></button>
                        <button *ngIf="row.STATUS == '1'" matTooltip="Desactivar" mat-icon-button (click)="delUsuario(row.USER_ID, 0)" ><mat-icon color="warn" class="icon-usuario">delete_forever</mat-icon></button>
                    </mat-cell>
                </ng-container> 
                <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </section>
        <mat-paginator #paginator class="col-md-12"
            [length]="lengthTabla"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="goToPage($event.pageIndex, $event.pageSize)">
        </mat-paginator>
    </mat-card>
</div>