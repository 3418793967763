import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.scss']
})
export class IndicadoresComponent implements OnInit {
  kpiSales$: Observable<any>;
  chartKpiSales: any;
  showKpiSales: number =0;

  kpiTaxes$: Observable<any>;
  chartKpiTaxes: any;
  showKpiTaxes: number =0;

  kpiCustomer$: Observable<any>;
  chartKpiCustomer: any;
  showKpiCustomer: number =0;

  kpiProducts$: Observable<any>;
  chartKpiProducts: any;
  showKpiProducts: number =0;

  constructor(
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.loadKpiSales();
    this.loadKpiTaxes();
    this.loadKpiCustomers();
    this.loadKpiProducts();
  }

  loadKpiSales(){
    // this.kpiSales$ = this.globalService.GetKpiSales().pipe(
    //   map((res:any)=>{
    //     if (res.Status =200){
    //       var dataArr = [];
    //       res.Data.forEach(e => {
    //         dataArr.push({
    //           y: e.TOTAL,
    //           label: this.getMes(e.MES)
    //         });
    //       });
    //       this.chartKpiSales = {
    //         animationEnabled: true,
    //         title: {
    //         text: "Total Ventas Por Mes"
    //         },
    //         data: [{
    //         type: "bar",
    //         startAngle: -90,
    //         indexLabel: "{y}",
    //         dataPoints: dataArr
    //         }]
    //       }
    //       this.showKpiSales = 1;
    //     }
    //   })
    // )
  }

  loadKpiTaxes(){
    // this.kpiTaxes$ = this.globalService.GetKpiTaxes().pipe(
    //   map((res:any)=>{
    //     var dataArr = [];
    //     if (res.Status = 200){
    //       res.Data.forEach(e => {
    //         var dataMontos = [];
    //         e.montos.forEach(x => {
    //           dataMontos.push({
    //             label: this.getMes(x.MES),
    //             y: x.TOTAL_TAX
    //           })
    //         });
    //         dataArr.push({
    //           type: "column",	
    //           name: e.taxDescripcion,
    //           legendText: e.taxDescripcion,
    //           showInLegend: true, 
    //           dataPoints: dataMontos
    //         })
    //       });
    //       this.chartKpiTaxes = {
    //         animationEnabled: true,
    //         title: {
    //           text: "Impuestos por mes"
    //         },
    //         axisX: {
    //           labelAngle: -90
    //         },
    //         axisY: {
    //           title: "Total Impuesto"
    //         },
    //         toolTip: {
    //           shared: true
    //         },
    //         legend:{
    //         cursor:"pointer",
    //           itemclick: function(e: any){
    //             if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
    //             e.dataSeries.visible = false;
    //             }
    //             else {
    //             e.dataSeries.visible = true;
    //             }
    //             e.chart.render();
    //           }
    //         },
    //         data: dataArr
    //       }	
    //       this.showKpiTaxes = 1;
    //     }
    //   })
    //   )
  }

  loadKpiCustomers(){
    // this.kpiCustomer$ = this.globalService.GetKpiCustomers().pipe(
    //   map((res:any)=>{
    //     if(res.Status = 200){
    //       var dataArr = [];
    //       res.Data.forEach(e => {
    //         dataArr.push({
    //           label: this.getMes(e.MES),
    //           y: e.CANTIDAD
    //         })
    //       });
    //       this.chartKpiCustomer = {
    //         animationEnabled: true,  
    //         title:{
    //           text: "Clientes Por Mes"
    //         },
    //         axisX: {
    //           title: "Meses"
    //         },
    //         axisY: { 
    //           title: "Cantidad"                   
    //         },
    //         toolTip: {
    //           shared: true
    //         },
    //         legend: {
    //           cursor:"pointer",
    //           itemclick: function(e) {
    //             if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible ){
    //             e.dataSeries.visible = false;
    //             } else {
    //             e.dataSeries.visible = true;
    //             }
    //             e.chart.render();
    //           }
    //         },
    //         data: [{        
    //           type: "spline",
    //           showInLegend: true,
    //           name: "Clientes",
    //           dataPoints: dataArr
    //         }]
    //       }
    //       this.showKpiCustomer = 1;	
    //     }
    //   })
    // )
  }

  loadKpiProducts(){
    // this.kpiProducts$ = this.globalService.GetKpiProducts().pipe(
    //   map((res:any)=>{
    //     var dataArr = [];
    //     if( res.Status = 200){
    //       res.Data.forEach(e => {
    //           dataArr.push({
    //             label: e.DESCRIPTION_ESP,
    //             y: e.CANTIDAD
    //           })
    //       });
    //       console.log(dataArr);
    //       this.chartKpiProducts = {
    //         title:{
    //           text: "Productos mas pedidos en el mes"  
    //         },
    //         animationEnabled: true,
    //         data: [{        
    //           type: "bar",
    //           indexLabel: "{y}",
    //           dataPoints: dataArr
    //         }]
    //       }	
    //       this.showKpiProducts = 1;
    //     }
    //   })
    // )
  }

  getMes(mes:any){
    if(mes == 1){
      return "Enero"
    }
    if(mes == 2){
      return "Febrero"
    }
    if(mes == 3){
      return "Marzo"
    }
    if(mes == 4){
      return "Abril"
    }
    if(mes == 5){
      return "Mayo"
    }
    if(mes == 6){
      return "Junio"
    }
    if(mes == 7){
      return "Julio"
    }
    if(mes == 8){
      return "Agosto"
    }
    if(mes == 9){
      return "Septiembre"
    }
    if(mes == 10){
      return "Octubre"
    }
    if(mes == 11){
      return "Noviembre"
    }
    if(mes == 12){
      return "Diciembre"
    }
  }

}
