<div class="example-loading-shade" *ngIf="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<h1 mat-dialog-title>Orden</h1>
<div mat-dialog-content class="card-detail">
    <ng-container *ngIf="delLine$ | async"></ng-container>
    <ng-container *ngIf="order$ | async"></ng-container>
    <div class="row" *ngFor="let order of orders; let i=index">
        <div class="col-md-1 pe-0 align-self-center">{{order.QTY}}</div>
        <div class="col-md-7 align-self-center">{{order.NAME_ESP}}</div>
        <div class="col-md-2 ps-0 align-self-center amount-right">{{order.AMOUNT | number:'.2'}}</div>
        <div class="col-md-auto align-self-center"><button mat-icon-button (click)="removeItem(i, order)"><mat-icon>remove_circle_outline</mat-icon></button></div>
    </div>
    <div class="row spacer color-spacer">
        <div class="col-md-5 total">Sub-Total: </div>
        <div class="col-md-7 amount-right total">{{totalTemp | number:'.2'}}</div>
    </div>
    <div class="row spacer">
        <div class="col-md-5 total">Descuento: </div>
        <div class="col-md-7 amount-right total">{{valDescuento | number:'.2'}}</div>
    </div>
    <div class="row spacer">
        <mat-radio-group aria-label="Select an option" class="col-md-7" [(ngModel)]="porc_desc">
            <mat-radio-button value="1">Adulto Mayor</mat-radio-button>
            <mat-radio-button value="2">% Desc</mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="legacy" (change)="updateTotal()" class="col-md-5">
            <mat-label>Descuento</mat-label>
            <input matInput class="right" [(ngModel)]="descuento">
        </mat-form-field>
    </div>
    <div class="row spacer">
        <div class="col-md-7 total">Impuesto: </div>
        <div class="col-md-5 amount-right total">{{totTax | number:'.2'}}</div>
    </div>
    <div class="row spacer">
        <div class="col-md-7 total align-self-center"><mat-checkbox [(ngModel)]="tipVal" (change)="updateTotal()"> Incluir TIP: </mat-checkbox></div>
        <div class="col-md-5 amount-right total">
            <!-- <mat-label>Tip</mat-label> -->
            
            {{tip | number:'.2'}}
            <!-- <input matInput class="right" [(ngModel)]="tip"> -->
        </div>
        <!-- <input matInput class="col-md-11 amount-right total" [(ngModel)]="tip"> -->
        <!-- <div class="col-md-11 amount-right total">{{tip | number:'.2'}}</div> -->
    </div>
    <div class="row spacer">
        <div class="col-md-1 total">Total: </div>
        <div class="col-md-11 amount-right total">{{totAmount | number:'.2'}}</div>
    </div>
</div>
<ng-container *ngIf="orderDisc$ | async"></ng-container>
<div mat-dialog-actions class="row align-items-end">
    <button mat-button (click)="onNoClick()" class="col-md-auto">Cancelar</button>
    <button mat-flat-button color="primary" class="col-md-auto" (click)="processDiscount()" cdkFocusInitial>Procesar</button>
</div>