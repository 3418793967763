import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OrdersService, TablesService, GlobalService } from '@app/services';
import { interval, Observable } from 'rxjs';
import { map, takeWhile, tap } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrintComponent, PermisoAdminComponent, SpinnerService } from '@app/shared';
import { InvoiceComponent } from '@app/modules';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/services';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  @ViewChild('scrollDiv') scrollDiv!: ElementRef;
  mesas$: Observable<any>;
  orden$: Observable<any>;
  ord$: Observable<any>;
  delLinea$: Observable<any>;
  dialog$!: Observable<any>;
  delOrden$!: Observable<any>;

  mesas: any[]=[];
  informacion: any[]=[];
  pendings: any[]=[];
  orderId: number = 0;
  mesaId: number = 0;
  mesaText: string = '00';
  total: number = 0;
  currency: string = '';
  companyId: string = '';
  locationId: string = '';

  constructor(
    private fb: FormBuilder,
    private ordersService: OrdersService,
    private tablesService: TablesService,
    private spinnerService: SpinnerService,
    private global: GlobalService,
    private stickerDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private matIconRegistry: MatIconRegistry,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private authService: AuthService
  ) { 
    this.matIconRegistry.addSvgIcon(
      "cocina",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/images/cocina.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "print", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/images/print.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "trash", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/images/trash.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "invoice", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/images/invoice.svg")
    );
  }

  ordenForm = this.fb.group({
    ordenId: 0,
    totalTax: 0,
    totalAmount: 0,
    tableNo: ['',[Validators.required]],
    tableId: 0,
    status: 0,
    orderDate: '',
    totalDiscount: 0,
    sequence: '',
    tiempoReal: 0,
    detail: this.fb.array([])
  });

  get fOrden(){ 
    return this.ordenForm.controls;
  }

  fDetalle() {
    return this.ordenForm.get('detail') as FormArray;
  }

  addLineaGroup(){
    return this.fb.group({
      lineNo: [''],
      productId: [''],
      qty: [''],
      unitPrice: [''],
      amount: [''],
      taxId: [''],
      status: [''],
      product: [''],
      itemNuevo: [''],
      discount: [''],
      tax: ['']
    })
  }

  ngOnInit(): void {
    this.currency = this.authService.getUserProfile().currency;
    this.companyId = this.authService.getUserProfile().companyId;
    this.locationId = this.authService.getUserProfile().locationId;
    // this.orderId = Number(this.route.snapshot.params.orderId);
    // this.mesaId = Number(this.route.snapshot.params.tableId);
    // this.global.setOrder({orderId: this.orderId});
    // this.global.setTable({tableId: this.mesaId});
    this.loadMesas();
  }

  loadMesas(){
    this.mesas$ = this.tablesService.getTables(this.companyId, this.locationId).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.mesas = res.Data;
          return res.Data;
        }
      })
    );
  }

  loadOrder(orderId: any, tableId: any){
    var spinnerRef = this.spinnerService.start();
    this.orden$ = this.ordersService.getOrder(orderId, tableId).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.ordenForm.patchValue({
            ordenId: res.Data.orderId,
            totalTax: res.Data.totalTax,
            totalAmount: res.Data.totalAmount,
            tableNo: res.Data.tableNo,
            tableId: res.Data.tableId,
            status: res.Data.status,
            orderDate: res.Data.orderDate,
            totalDiscount: res.Data.totalDiscount,
            sequence: res.Data.sequence,
            tiempoReal: res.Data.tiempoReal
          });
          this.orderId = res.Data.orderId;
          // this.mesaId = res.Data.tableId;
          // this.mesaText = res.Data.tableNo;
          this.total = res.Data.totalAmount;
          this.fDetalle().clear();
          this.setDetalle(res.Data.detail);
          return res.Data;
        } {
          this.orderId = 0;
        }
      })
    );
  }

  setDetalle(lineas: any){
    const formArray = <any>[];
    lineas.forEach((det: any) => {
        this.fDetalle().push(this.fb.group({
          lineNo: det.lineNo,
          productId: det.productId,
          qty: det.qty,
          unitPrice: det.unitPrice,
          amount: det.amount,
          taxId: det.taxId,
          status: det.status,
          product: det.product,
          itemNuevo: det.itemNuevo,
          discount: det.discount,
          tax: det.tax
          })
        );
    });
  }

  eliminarLinea(i: number, det: any){
    const ordenId = this.ordenForm.value.ordenId;
    if (ordenId == 0 || det.itemNuevo == 1){
      this.fDetalle().removeAt(i);
      this.calcularTotal();
    } else {
      this.delLinea$ = this.ordersService.delLinea(ordenId, det.lineNo).pipe(
        map((res: any) => {
          if (res.Status == 200){
            this.ordenForm.patchValue({
              ordenId: res.OrderId,
              totalTax: res.TotalTax,
              totalAmount: res.TotalAmount,
            })
            this.fDetalle().removeAt(i);
            this.calcularTotal();
            return res.Data;
          }
        })
      );
    }
  }

  agregarProducto(event:any){
    if (this.mesaId == 0) {return;}
    this.fDetalle().push(this.fb.group({
      lineNo: 0,
      productId: event.PRODUCT_ID,
      qty: event.CANTIDAD,
      unitPrice: event.UNIT_PRICE,
      amount: 0,
      taxId: event.TAX_ID,
      status: 0,
      product: event.NAME,
      itemNuevo: 1,
      discount: 0,
      tax: event.TAX
      }
    ));
    this.pendings.push({
      lineNo: 0,
      productId: event.PRODUCT_ID,
      qty: event.CANTIDAD,
      unitPrice: event.UNIT_PRICE,
      amount: 0,
      taxId: event.TAX_ID,
      status: 0,
      product: event.NAME,
      itemNuevo: 1,
      discount: 0,
      tax: event.TAX
    });
    this.calcularTotal();
  }

  getNewItems(){
    let total: number = 0;
    this.fDetalle().value.forEach((res: any) => {
      total += res.itemNuevo;
    });
    if (total > 0) {return true;} else {return false;}
  }

  calcularTotal(){
    this.total = 0;
    this.fDetalle().value.forEach(e => {
      var baseAmount = (+e.qty * +e.unitPrice);
      var amount = +baseAmount * +e.tax;
      this.total += baseAmount + amount;
    });
  }

  scrollLeft(el: Element) {
    const animTimeMs = 400;
    const pixelsToMove = 326;
    const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
    interval(animTimeMs / 8)
      .pipe(
        takeWhile(value => value < 8),
        tap(value => el.scrollLeft -= (pixelsToMove * stepArray[value])),
      )
      .subscribe();
  }

  scrollRight(el: Element) {
    const animTimeMs = 400;
    const pixelsToMove = 326;
    const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
    interval(animTimeMs / 8)
      .pipe(
        takeWhile(value => value < 8),
        tap(value => el.scrollLeft += (pixelsToMove * stepArray[value])),
      )
      .subscribe();
  }

  seleccionarMesa(mesa: any){
    this.mesaId = mesa.TABLE_ID;
    this.mesaText = mesa.DESCRIPTION;
    if(mesa.STATUS == 0){
      this.loadOrder(0, this.mesaId);
    }else{
      this.total = 0;
      this.orderId = 0;
      this.fDetalle().clear();
      this.ordenForm.patchValue({
        tableNo: this.mesaText,
        tableId: this.mesaId,
        ordenId: 0,
        totalTax: 0,
        totalAmount: 0,
        status: 0,
        orderDate: '',
        totalDiscount: 0,
        sequence: '',
        tiempoReal: 0
      })
    }
  }

  getHora(orderDate:any){
    if(orderDate != ''){
      var seconds = parseInt(orderDate);
      var hour = Math.floor(seconds / 3600);
      hour = (hour < 10)? 0 + hour : hour;
      var minute = Math.floor((seconds / 60) % 60);
      minute = (minute < 10)? 0 + minute : minute;
      var second = seconds % 60;
      second = (second < 10)? 0 + second : second;
      return hour + 'hr ' + minute + 'min ';
    }else{
      return '0hr 0min';
    }
  }

  delOrder(ordId: any){
    this.delOrden$ = this.ordersService.delOrder(ordId, {}).pipe(
      map((res: any)=>{
        var mesa = this.mesas.filter((x:any)=> x.TABLE_ID == this.ordenForm.value.tableId);
        console.log(mesa);
        if (mesa.length > 0){
          console.log('Ingreso', mesa);
          mesa[0].STATUS = 1;
        }
        this.fDetalle().clear();
        this.total = 0;
        this.orderId = 0;
        this.ordenForm.patchValue({
          tableId: this.mesaId,
          tableNo: this.mesaText.toString(),
          ordenId: 0,
          totalTax: 0,
          totalAmount: 0,
          status: 0,
          orderDate: '',
          totalDiscount: 0,
          sequence: '',
          tiempoReal: 0
        });
        
      })
    )
  }

  envioCocina(){
    let ordId: number;
    if (!this.ordenForm.valid) { return; }
    ordId = this.ordenForm.value.ordenId;
    var detalle = [];
    this.fDetalle().value.forEach((res: any) => {
      let data = {
        productId: res.productId,
        qty: res.qty,
        unitPrice: res.unitPrice,
        taxId: res.taxId,
        comments: '',
        status: res.status,
        newItem: res.itemNuevo
      }
      detalle.push(data);
    });
    let frmData = {
      orderId: ordId,
      table: this.ordenForm.value.tableId,
      status: this.ordenForm.value.status,
      companyId: this.companyId,
      detail: detalle
    }
    this.ord$ = this.ordersService.postOrder(frmData).pipe(
      map((res: any) => {
        if (res.Status == 200){
          const mesa = this.mesas.filter(x => x.TABLE_ID == this.ordenForm.value.tableId);
          if (mesa.length > 0){
            mesa[0].STATUS = 0;
            // this.mesaId = mesa[0].TABLE_ID;
            // this.mesaText = mesa[0].DESCRIPTION;
          }
          this.ordenForm.patchValue({
            ordenId: res.OrderId,
            totalTax: res.TotalTax,
            totalAmount: res.TotalAmount,
            sequence: res.Sequence,
            status: 1
          });
          this.orderId = res.OrderId;
          this.fDetalle().controls.forEach((res: any) => {
            res.patchValue({
              itemNuevo: 0
            });
          });
          location.href="print://Cocina/"+this.orderId;
          this._snackBar.open('Orden creada con éxito', 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 4000,
            panelClass: ['success']
          });
          return res.Data;
        }
      })
    );
  }

  formatNumber(num:string){
    return Number.parseFloat(num).toFixed(2);
  }

  printRec(){
    if(this.ordenForm.value.ordenId != 0){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.data = {orderId: this.ordenForm.value.ordenId, recibo: 1};
      dialogConfig.width = '100%';
      dialogConfig.maxWidth = '100%';
      dialogConfig.height = '100%';
      const dialogRef = this.stickerDialog.open(PrintComponent, dialogConfig);
      this.dialog$ = dialogRef.afterClosed().pipe(
        map((res: any) => {
          this.fDetalle().value.forEach((res: any, i:number) =>{
            const detalle = (<FormArray>this.ordenForm.get("detail")).at(i);
            detalle.patchValue({
              status: 0
            })
          });
          this.ordenForm.patchValue({status: 3});
        })
      );
    }
  }

  genInvoice(){
    if(this.ordenForm.value.ordenId != 0){
      // const mesa = this.mesas.filter(x => x.TABLE_ID == this.ordenForm.value.tableId);
      // if (mesa.length > 0){
      //   this.mesaText = mesa[0].DESCRIPTION;
      //   this.mesaId = mesa[0].TABLE_ID;
      // }
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.data = {orderId: this.ordenForm.value.ordenId, tableId: this.mesaId};
      dialogConfig.width = '100%';
      dialogConfig.maxWidth = '100%';
      dialogConfig.height = '100%';
      dialogConfig.minHeight = '100%';
      dialogConfig.maxHeight = '100%';
      const dialogRef = this.stickerDialog.open(InvoiceComponent, dialogConfig);
      this.dialog$ = dialogRef.afterClosed().pipe(
        map((res: any) => {
          if (res != undefined){
            if (res.result == 200){
              this.total = 0;
              this.fDetalle().clear();
              this.ordenForm.patchValue({
                tableNo: '',
                tableId: 0,
                ordenId: 0,
                totalTax: 0,
                totalAmount: 0,
                status: 0,
                orderDate: '',
                totalDiscount: 0,
                sequence: '',
                tiempoReal: 0,
                detail: []
              });
              this.orderId = 0;
              this.mesaId = 0;
              this.mesaText = '00';
              this.loadMesas();
            }else{
              this.loadOrder(this.orderId, this.mesaId);
            }
          }
        })
      );
    }
  }

  deleteOrder(){
    if(this.ordenForm.value.ordenId != 0){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.data = {orderId: this.ordenForm.value.ordenId};
      dialogConfig.width = '400px';
      dialogConfig.maxWidth = '400px';
      dialogConfig.height = '270px';
      const dialogRef = this.stickerDialog.open(PermisoAdminComponent, dialogConfig);
      this.dialog$ = dialogRef.afterClosed().pipe(
        map((res: any) => {
          if(res != undefined){
            if(res.isAdmin == 1){
                this.delOrder(this.ordenForm.value.ordenId);
            }
          }
        })
      );
    }
  }

}