import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@app/core/services';
import { SearchService } from '@app/services';
import { SpinnerService } from '@app/shared';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface SearchData{
  titles: any[];
  cols: any[];
  objeto: string;
  delete: boolean;
  dataDB: any[];
  value: string;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort = new MatSort;

  shared$: Observable<any>;
  delete$: Observable<any>;

  onError: string = '';
  companyId: string = '';

  search: string = '';
  delete: boolean = this.dataSearch.delete;

  length: number = -1;
  pageSize: number = 5;
  _page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  displayedColumns = this.dataSearch.cols;
  titles = this.dataSearch.titles;
  obj = this.dataSearch.objeto;

  constructor(
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private searchService: SearchService,
    public dialogRef: MatDialogRef<SearchComponent>,
    @Inject(MAT_DIALOG_DATA) public dataSearch: SearchData
  ) { }

  ngOnInit(): void {
    this.companyId = this.authService.getCompanyId();
    this._page = 0;
    this.loadData(this._page);
  }

  onDelete(row: any){
    // let id: string ='';
    // if (this.obj == 'dummy'){
    //   id = row.dummyId;
    // }
    // this.delete$ = this.searchService.delItem(this.obj, id, {status: '0'}).pipe(
    //   map((res: any) => {
    //     if (res.Code == 200){
    //       let dataR = this.dataSource.data;
    //       if (this.obj == 'dummy'){
    //         let result = dataR.findIndex(x=>x.dummyId == id);
    //         dataR.splice(result,1);
    //         }
    //       this.dataSource.data = dataR;
    //       this.length = this.length -1;
    //       return res.Data;
    //     }
    //   })
    // );
  }

  loadData(curPage: number){
    this.onError = '';
    var spinnerRef = this.spinnerService.start();
    this.shared$ = this.searchService.getSearch(this.obj, this.companyId, (this.search == '' ? '0' : this.search), curPage, this.pageSize, 1).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.length = res.Total;
          this.dataSource.data = res.Data;
          this.dataSource.sort = this.sort;
          return res.Data;
        }
      }),
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this.onError = err.Message;
        return this.onError;
      })
    );
  }
  
  sendFilter(){
    this._page = 0;
    this.loadData(this._page);
  }

  onSelect(row: any){
    this.dialogRef.close(row);
  }

  goToPage(page: number, elements: number): void{
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    this.loadData((this._page == 0 ? 0 : this._page*this.pageSize));
  }

  trackRow(index: number, item: any){
    return item;
  }

}
