<div class="container-fluid px-5">
    <div class="row justify-content-center">
        <div class="card-title mt-2 px-0 col-md-10 w-100">
            <mat-icon class="icon-title">
                store
            </mat-icon>
            <span class="text-title">Compañía</span>
        </div>
        <mat-card class="mt-0 col-md-10 w-100">
            <mat-card-content>
                <mat-tab-group dynamicHeight mat-align-tabs="start" (selectedTabChange)="onChangeTab($event);">
                    <mat-tab label="Compañía" class="d-flex justify-content-start">
                        <ng-container *ngIf="company$ | async"></ng-container>
                        <ng-container *ngIf="locations$ | async"></ng-container>
                        <form [formGroup]="companyForm">
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline"  class="first-item item">
                                        <mat-label>Razón Social</mat-label>
                                        <input matInput placeholder="Razon Social" name="name"formControlName="Name">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline"  class="item">
                                        <mat-label>Nombre Comercial</mat-label>
                                        <input matInput placeholder="Razon Social" name="businessName"formControlName="BusinessName">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline"  class="item">
                                        <mat-label>Dirección</mat-label>
                                        <input matInput placeholder="Dirección" name="address" formControlName="Address">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline"  class="item">
                                        <mat-label>Correo electrónico</mat-label>
                                        <input matInput placeholder="Correo electrónico" name="email" formControlName="Email">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="outline"  class="col-md-6">
                                    <mat-label>Código Impuesto</mat-label>
                                    <input matInput placeholder="Código Impuesto" name="tax" formControlName="Tax">
                                </mat-form-field>
                                <mat-form-field appearance="outline"  class="col-md-2">
                                    <mat-label>Moneda</mat-label>
                                    <input matInput placeholder="Moneda" name="currency" formControlName="Currency">
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="outline"  class="col-md-4">
                                    <mat-label>Descuento Adulto Mayor</mat-label>
                                    <input matInput placeholder="Adulto Mayor" name="adultoMayor" formControlName="AdultoMayor">
                                    <span matTextSuffix>%</span>
                                </mat-form-field>
                                <mat-form-field appearance="outline"  class="col-md-4">
                                    <mat-label>Impresora Mesero</mat-label>
                                    <input matInput placeholder="Impresora Mesero" name="impMesero" formControlName="PrinterMesero">
                                </mat-form-field>
                                <mat-form-field appearance="outline"  class="col-md-4">
                                    <mat-label>Impresora Cocina</mat-label>
                                    <input matInput placeholder="Impresora Cocina" name="impCocina" formControlName="PrinterCocina">
                                </mat-form-field>
                            </div>
                        </form>
                        <button mat-flat-button class="btn" id="save" type="submit" color="primary" text-center w-100 (click)="updateData()">Actualizar</button>
                    </mat-tab>
                    <mat-tab label="Localidades" class="d-flex justify-content-start">
                        <ng-container *ngIf="length > 0; else noLoc">
                            <div class="row">
                                <div class="list-locs col-md-12">
                                    <mat-card class="card-location me-3 mt-2 p-0" *ngFor="let loc of locations; let i = index" (click)="changeSelected(loc)" [ngClass]="{'selected-card': loc.SEL == 1, 'border-card': loc.SEL == 0}">
                                        <mat-card-content class="display-card">
                                          <mat-icon class="me-2">near_me</mat-icon>
                                          <div class="text-loc">{{loc.DESCRIPTION}}</div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-paginator 
                                        [length]="length"
                                        [pageSize]="pageSize"
                                        [pageSizeOptions]="[3]"
                                        [hidePageSize]="true"
                                        (page)="goToPage($event.pageIndex, $event.pageSize)">
                                    </mat-paginator>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noLoc>
                            <ng-container *ngIf="length == 0">
                                <div class="noLoc text-center">Pendiente la configuración de Localidades para esta Compañia, <br> ingrese la información de una Localidad en el formulario</div>
                            </ng-container>
                        </ng-template>
                        <form [formGroup]="locationForm" class="p-4 border">
                            <div class="row">
                                <div class="col-md-8 col-lg-4">
                                    <mat-form-field appearance="outline"  class="first-item item">
                                        <mat-label #des>Descripción</mat-label>
                                        <input matInput placeholder="Descripción" name="description" formControlName="Description" [errorStateMatcher]="confirmValidParentMatcher" required maxlength="100" minlength="3">
                                        <mat-error *ngIf="location.Description.invalid && location.Description.touched">
                                            {{ 'Error: ' + getErrorMessage(locationForm, 'Description', des, 3, 100, 0, 0)}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 my-auto" *ngIf="location.LocationId.value != ''">
                                    <mat-checkbox formControlName="Status">Estatus {{location.Status.value == true ? 'Activo' : 'Inactivo'}}</mat-checkbox>
                                </div>
                            </div>                        
                            <div class="row">
                                <div class="col-md-12 col-lg-6">
                                    <mat-form-field appearance="outline"  class="item">
                                        <mat-label #dir>Dirección</mat-label>
                                        <input matInput placeholder="Dirección" name="address"formControlName="Address" [errorStateMatcher]="confirmValidParentMatcher" required maxlength="150" minlength="3">
                                        <mat-error *ngIf="location.Address.invalid && location.Address.touched">
                                            {{ 'Error: ' + getErrorMessage(locationForm, 'Address', dir, 3, 150, 0, 0)}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                        <button mat-flat-button class="btn" id="save" type="submit" color="primary" text-center w-100 (click)="saveLocation()">Guardar</button>
                        <button mat-flat-button class="btn ms-3" id="cancel" type="button" color="accent" text-center w-100 (click)="clearLocation()">{{ location.LocationId.value == '' ? 'Nuevo' : 'Cancelar'}}</button>
                    </mat-tab>
                    <mat-tab label="Documentos" class="d-flex justify-content-start">
                        <ng-container *ngIf="doctos$ | async"></ng-container>
                        <ng-container *ngIf="lengthDocto > 0; else noDoctos">
                            <mat-card class="card-table py-0">
                                <mat-table matSort [dataSource]="dataSource">
                                    <ng-container matColumnDef="DESCRIPTION" sticky>
                                        <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> DESCRIPCION </mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="content-cell"> {{row.DESCRIPTION}} </mat-cell>
                                    </ng-container>
                                
                                    <ng-container matColumnDef="LOCATION" sticky>
                                        <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> LOCALIDAD </mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="content-cell"> {{row.LOCATION}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="TYPE_ID">
                                        <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> TIPO </mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="content-cell"> {{row.TYPE_ID}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="STATUS">
                                        <mat-header-cell mat-sort-header *matHeaderCellDef class="title-cell header-cell"> ESTATUS </mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="content-cell"><span [ngClass]="{'active': row.STATUS == true, 'inactive': row.STATUS == false}">{{row.STATUS == false ? 'Inactivo' : 'Activo'}}</span></mat-cell>
                                    </ng-container>
                                
                                    <ng-container matColumnDef="ACTIONS">
                                        <mat-header-cell *matHeaderCellDef class="title-cell header-cell">ACCIONES</mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="content-cell">
                                            <button mat-icon-button matTooltip="Ver Documento" (click)="changeSelectedDocto(row)"><mat-icon>dvr</mat-icon></button>
                                            <button *ngIf="row.STATUS == '1'" matTooltip="Desactivar" mat-icon-button (click)="delDocumento(row.DOCUMENT_ID)" ><mat-icon color="warn" class="icon-usuario">delete_forever</mat-icon></button>
                                        </mat-cell>
                                    </ng-container> 
                                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                </mat-table>
                                <mat-paginator #paginator class="mat-paginator-sticky"
                                    [length]="lengthDocto"
                                    [pageSize]="pageSizeDocto"
                                    [pageSizeOptions]="pageSizeOptionsDocto"
                                    (page)="goToPageDocto($event.pageIndex, $event.pageSize)">
                                </mat-paginator>
                            </mat-card>
                        </ng-container>
                        <ng-template #noDoctos>
                            <ng-container *ngIf="lengthDocto == 0">
                                <div class="noLoc text-center">Pendiente la configuración de Tipo de Documento para esta Compañia, <br> ingrese la información de una tipo de documento en el formulario</div>
                            </ng-container>
                        </ng-template>
                        <form [formGroup]="doctosForm" class="p-4 border">
                            <div class="row">
                                <mat-form-field appearance="outline" class="col-md-6 col-lg-4 first-item">
                                    <mat-label #loc>Localidad</mat-label>
                                    <mat-select formControlName="LocationId" [errorStateMatcher]="confirmValidParentMatcher" required>
                                      <mat-option *ngFor="let loc of locationsDoctos; let i = index" [value]="loc.LOCATION_ID">
                                        {{loc.DESCRIPTION}}
                                      </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="documentos.LocationId.invalid && documentos.LocationId.touched">
                                        {{ 'Error: ' + getErrorMessage(doctosForm, 'LocationId', loc, 0, 0, 0, 0)}}</mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-md-6 col-lg-3 first-item">
                                    <mat-label #tp>Tipo de Documento</mat-label>
                                    <mat-select formControlName="Type" [errorStateMatcher]="confirmValidParentMatcher" required>
                                      <mat-option *ngFor="let type of types; let i = index" [value]="type">
                                        {{type}}
                                      </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="documentos.Type.invalid && documentos.Type.touched">
                                        {{ 'Error: ' + getErrorMessage(doctosForm, 'Type', tp, 0, 0, 0, 0)}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-7">
                                    <mat-form-field appearance="outline">
                                        <mat-label #des01>Descripción</mat-label>
                                        <input matInput placeholder="Descripción" name="description" formControlName="Description" [errorStateMatcher]="confirmValidParentMatcher" required maxlength="45" minlength="3">
                                        <mat-error *ngIf="documentos.Description.invalid && documentos.Description.touched">
                                            {{ 'Error: ' + getErrorMessage(doctosForm, 'Description', des01, 3, 45, 0, 0)}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 my-0" *ngIf="documentos.DocumentId.value != ''">
                                    <mat-checkbox formControlName="Status">Estatus {{documentos.Status.value == true ? 'Activo' : 'Inactivo'}}</mat-checkbox>
                                </div>
                            </div>                        
                            <div class="row">
                                <div class="col-md-2 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Prefijo</mat-label>
                                        <input matInput placeholder="Prefijo" name="prefix" formControlName="Prefix">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label #sig>Siguiente Número</mat-label>
                                        <input matInput placeholder="Siguiente Número" name="nextNumber" formControlName="NextNumber" [errorStateMatcher]="confirmValidParentMatcher" required max="999999999" min="1">
                                        <mat-error *ngIf="documentos.NextNumber.invalid && documentos.NextNumber.touched">
                                            {{ 'Error: ' + getErrorMessage(doctosForm, 'NextNumber', sig, 0, 0, 1, 999999999)}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Sufijo</mat-label>
                                        <input matInput placeholder="Sufijo" name="sufix" formControlName="Suffix">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Número Inicial</mat-label>
                                        <input matInput placeholder="Número Inicial" name="startNumber" formControlName="StartNumber">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Número Final</mat-label>
                                        <input matInput placeholder="Número Final" name="endNumber" formControlName="EndNumber">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Dígitos</mat-label>
                                        <input matInput placeholder="Dígitos" name="digits" formControlName="Digits">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-lg-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Autorización</mat-label>
                                        <input matInput placeholder="Autorización" name="auth" formControlName="Auth">
                                    </mat-form-field>
                                </div>
                                <mat-form-field appearance="outline"  class="col-md-4 col-lg-3">
                                    <mat-label>Fecha Inicial</mat-label>
                                    <input matInput name="dateIni" formControlName="StartDate" [min]="minDate" [matDatepicker]="initPicker">
                                    <mat-datepicker-toggle matSuffix [for]="initPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #initPicker></mat-datepicker>
                                    <!-- <mat-error *ngIf="invalidDateIni == 1">Error: fecha incorrecta</mat-error> -->
                                </mat-form-field>
                                <mat-form-field appearance="outline"  class="col-md-4 col-lg-3">
                                    <mat-label>Fecha Final</mat-label>
                                    <input matInput name="dateFin" formControlName="EndDate" [min]="minDate" [matDatepicker]="finPicker">
                                    <mat-datepicker-toggle matSuffix [for]="finPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #finPicker></mat-datepicker>
                                    <!-- <mat-error *ngIf="invalidDateFin == 1">Error: fecha incorrecta</mat-error> -->
                                </mat-form-field>
                            </div>
                        </form>
                        <button mat-flat-button class="btn" id="save" type="submit" color="primary" text-center w-100 (click)="saveDocumento()">Guardar</button>
                        <button mat-flat-button class="btn ms-3" id="cancel" type="button" color="accent" text-center w-100 (click)="clearDocumento()">{{ documentos.DocumentId.value == '' ? 'Nuevo' : 'Cancelar'}}</button>
                    </mat-tab>
                    <mat-tab label="Impuestos" class="d-flex justify-content-start">
                        <ng-container *ngIf="taxes$ | async"></ng-container>
                        <ng-container *ngIf="lengthTaxes > 0; else noTaxes">
                            <mat-card class="card-table py-0">
                                <mat-table matSort [dataSource]="dataSourceTaxes">
                                    <ng-container matColumnDef="DESCRIPTION" sticky>
                                        <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-3 title-cell"> DESCRIPCION </mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="col-md-3"> {{row.DESCRIPTION}} </mat-cell>
                                    </ng-container>
                                
                                    <ng-container matColumnDef="AMOUNT" sticky>
                                        <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-3 title-cell w-valor"> VALOR </mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="col-md-3 w-valor"> {{(row.AMOUNT | number: '1.0-0') + ' %'}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="TAX_INCLUDED">
                                        <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-2 title-cell"> IMPUESTO INCLUIDO </mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="col-md-2"> {{row.TAX_INCLUDED == true ? 'Si' : 'No'}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="STATUS">
                                        <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-2 title-cell"> ESTATUS </mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="col-md-2"><span [ngClass]="{'active': row.STATUS == true, 'inactive': row.STATUS == false}">{{row.STATUS == false ? 'Inactivo' : 'Activo'}}</span></mat-cell>
                                    </ng-container>
                                
                                    <ng-container matColumnDef="ACTIONS">
                                        <mat-header-cell *matHeaderCellDef class="col-md-2 title-cell">ACCIONES</mat-header-cell>
                                        <mat-cell *matCellDef="let row" class="col-md-2">
                                            <button mat-icon-button matTooltip="Ver Impuesto" class="icon-view" (click)="changeSelectedTax(row)"><mat-icon>dvr</mat-icon></button>
                                            <button *ngIf="row.STATUS == '1'" matTooltip="Desactivar" mat-icon-button (click)="delTax(row.TAX_ID)" ><mat-icon color="warn" class="icon-usuario">delete_forever</mat-icon></button>
                                        </mat-cell>
                                    </ng-container> 
                                    <mat-header-row *matHeaderRowDef="displayedColumnsTaxes; sticky:true"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsTaxes;"></mat-row>
                                </mat-table>
                                <mat-paginator #paginator class="col-md-12 mat-paginator-sticky"
                                    [length]="lengthTaxes"
                                    [pageSize]="pageSizeTaxes"
                                    [pageSizeOptions]="pageSizeOptionsTaxes"
                                    (page)="goToPageTaxes($event.pageIndex, $event.pageSize)">
                                </mat-paginator>
                            </mat-card>
                        </ng-container>
                        <ng-template #noTaxes>
                            <ng-container *ngIf="lengthTaxes == 0">
                                <div class="noLoc text-center">Pendiente la configuración de Impuestos para esta Compañia, <br> ingrese la información de los impuestos en el formulario</div>
                            </ng-container>
                        </ng-template>
                        <form [formGroup]="taxesForm" class="p-4 border">
                            <div class="row">
                                <div class="col-md-9 col-lg-5">
                                    <mat-form-field appearance="outline">
                                        <mat-label #des03>Descripción</mat-label>
                                        <input matInput placeholder="Descripción" name="description" formControlName="Description" [errorStateMatcher]="confirmValidParentMatcher" required max="50" min="3">
                                        <mat-error *ngIf="taxes.Description.invalid && taxes.Description.touched">
                                            {{ 'Error: ' + getErrorMessage(taxesForm, 'Description', des03, 3, 50, 0, 0)}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 my-0" *ngIf="taxes.TaxId.value != ''">
                                    <mat-checkbox formControlName="Status">Estatus {{taxes.Status.value == true ? 'Activo' : 'Inactivo'}}</mat-checkbox>
                                </div>
                            </div>                        
                            <div class="row">
                                <div class="col-md-4 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label #val>Valor</mat-label>
                                        <input matInput placeholder="Valor" name="amount" formControlName="Amount" [errorStateMatcher]="confirmValidParentMatcher" required max="100" min="1">
                                        <span matTextSuffix>%</span>
                                        <mat-error *ngIf="taxes.Amount.invalid && taxes.Amount.touched">
                                            {{ 'Error: ' + getErrorMessage(taxesForm, 'Amount', val, 0, 0, 1, 100)}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-3">
                                    <mat-label>Impuestos incluidos</mat-label>
                                    <mat-checkbox formControlName="TaxIncluded"></mat-checkbox>
                                </div>
                            </div>
                        </form>
                        <button mat-flat-button class="btn" id="save" type="submit" color="primary" text-center w-100 (click)="saveTax()">Guardar</button>
                        <button mat-flat-button class="btn ms-3" id="cancel" type="button" color="accent" text-center w-100 (click)="clearTax()">{{ taxes.TaxId.value == '' ? 'Nuevo' : 'Cancelar'}}</button>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
</div>