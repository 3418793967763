import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  readonly apiURL = environment.apiUrl;
  constructor(private http: HttpClient) 
  { }

  delLinea(orderId:any, lineaNo:any){
    return this.http.delete(this.apiURL + 'order/line/'+ orderId + '/' + lineaNo, {})
                    .pipe(catchError(this.errorHandler));
  }

  delOrder(orderId: any, frmData: any){
    return this.http.delete(this.apiURL + 'order/' + orderId, frmData)
                    .pipe(catchError(this.errorHandler));
  }

  getOrder(orderId: any, tableId: any){
    return this.http.get(this.apiURL + 'order/'+ orderId + '/'+ tableId)
                    .pipe(catchError(this.errorHandler));
  }

  postOrder(frmData:any){
    return this.http.post(this.apiURL + 'order',frmData)
                    .pipe(catchError(this.errorHandler));
  }

  getOrders(texto:any, offset:any, limit:any){
    return this.http.get(this.apiURL + 'orders/'+ texto + '/' + offset + '/' + limit)
                    .pipe(catchError(this.errorHandler));
  }
  
  postDiscount(orderId:any, discount:any){
    return this.http.post(this.apiURL + 'order/discount/'+ orderId + '/' + discount, '')
                    .pipe(catchError(this.errorHandler));
  }

  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
}
