<ng-container *ngIf="orden$ | async"></ng-container>
<ng-container *ngIf="dialog$ | async"></ng-container>
<ng-container *ngIf="invoice$ | async"></ng-container>
<ng-container *ngIf="discount$ | async"></ng-container>

<div (click)="closedMod()" class="closeX">X Cerrar</div>
<h4 mat-dialog-title class="mb-0 col-md-6">Emitir Documento</h4>
<mat-dialog-content class="container-fluid h-content pb-0">
    <div class="row">
        <div class="col-md-6 border-end py-3">
            <div class="row mb-2 justify-content-between">
                <div class="col-md-6 fw-bold">
                    {{'Mesa: ' + tableText}}
                </div>
                <div class="col-md-6 text-end fw-bold">
                    {{'Orden: ' + (orderId | number : '6.0-0').replace(',', '')}}
                </div>
            </div>
            
            <div class="row border-bottom my-1 pt-0 pb-1 text-center fw-bold">
                <div class="col-md-1 linea-det ps-0">
                    Cant.
                </div>
                <div class="col-md-5 linea-det text-center ps-0 fw-bold">
                    Descripción
                </div>
                <div class="col-md-auto linea-det px-0 text-center fw-bold">
                    Precio
                </div>
                <div class="col-md-auto linea-det px-0 text-end fw-bold">
                    Total
                </div>
            </div>
            <section #scrollDiv class="card-content">
                <form [formGroup]="invoiceForm">
                    <div formArrayName="detail"  *ngFor="let det of fDetalle().controls; let i = index" >
                        <div [formGroupName]="i" class="row linea-det my-1 py-0 p-1">
                            <div class="col-md-1 linea-det ps-0">
                                {{fDetalle().controls[i].get('qty').value}}
                            </div>
                            <div class="col-md-5 linea-det   ps-0">
                                {{fDetalle().controls[i].get('product').value}}
                            </div>
                            <div class="col-md-auto linea-det px-0">
                                {{fDetalle().controls[i].get('unitPrice').value | number:'1.2-2'}}
                            </div>
                            <div class="col-md-auto linea-det px-0">
                                {{fDetalle().controls[i].get('amount').value | number:'1.2-2'}}
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <form [formGroup]="invoiceForm">
                <div class="row">
                    <div class="col-md-8 fw-bold text-end">
                        Descuento:  
                        <mat-radio-group [(ngModel)]="tipoDisc" (change)="recalcularDisc()" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button value="1">Precio</mat-radio-button>
                            <mat-radio-button value="2">%</mat-radio-button>
                          </mat-radio-group>
                    </div>
                    <div class="col-md-4 text-end">
                        <input matInput type="text" class="text-end" (blur)="recalcularDisc()" formControlName="totalDisc">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 fw-bold text-end">
                        Descuento Adulto Mayor: 
                        <mat-checkbox [(ngModel)]="discAM" (click)="setDiscVal()" [ngModelOptions]="{standalone: true}">Si</mat-checkbox>
                    </div>
                    <div class="col-md-4 text-end">
                        <input matInput type="text" class="text-end" (blur)="recalcularDisc()" formControlName="totalDiscAM">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 fw-bold text-end">
                        Descuento Total: 
                    </div>
                    <div class="col-md-4 text-end">
                        {{currency + ' ' + (fOrden.totalDiscount.value | number:'1.2-2')}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 fw-bold text-end">
                        SubTotal:
                    </div>
                    <div class="col-md-4 text-end">
                        {{currency + ' ' + (subTotal | number:'1.2-2')}}
                    </div>
                </div>
                <div class="row" *ngFor="let tax of taxes">
                    <div class="col-md-8 fw-bold text-end">
                        {{tax.descripcion}}:
                    </div>
                    <div class="col-md-4 text-end">
                        {{currency + ' ' + (tax.taxAmount | number:'1.2-2')}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 fw-bold text-end">
                        Total: 
                    </div>
                    <div class="col-md-4 text-end">
                        {{currency + ' ' + (fOrden.totalAmount.value | number:'1.2-2')}}
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6 py-3">
            <div class="row">
                <div class="col-md-6">
                    <div class="text-center">
                        <mat-icon class="color-g mx-3" (click)="tipoPago = 0" [ngClass]="{'icon-color-disable': tipoPago == 1}">monetization_on</mat-icon>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-center">
                        <mat-icon class="color-b mx-3" (click)="tipoPago = 1" [ngClass]="{'icon-color-disable': tipoPago == 0}">credit_card</mat-icon>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field>
                        <mat-label>Tipo de Documento</mat-label>
                        <mat-select [(ngModel)]="documentId" name="document">
                            <mat-option *ngFor="let docto of documents" [value]="docto.DOCUMENT_ID">{{docto.DESCRIPTION}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <form [formGroup]="invoiceForm" class="container">
                        <div class="row">
                            <mat-form-field class="col-md-12" appearance="outline">
                                <mat-label>RTN:</mat-label>
                                <input #rtnVal matInput formControlName="taxId" [matAutocomplete]="auto">
                                <mat-spinner matSuffix *ngIf="loading" [diameter]="24" class="spinner-text"></mat-spinner>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option class="pb-2" *ngFor="let customer of customers$ | async" [value]="customer.TAX_ID">
                                        <div>RTN: {{customer.TAX_ID}}<br>NOMBRE: {{customer.NAME}}</div>
                                        <div class="line-option"></div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-12" appearance="outline">
                                <mat-label>Nombre:</mat-label>
                                <input matInput formControlName="nombre">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-12" appearance="outline">
                                <mat-label>Dirección:</mat-label>
                                <input matInput formControlName="direccion">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-12" appearance="outline">
                                <mat-label>Propina:</mat-label>
                                <input matInput formControlName="propina" min="0" max="1000000">
                            </mat-form-field>
                        </div>
                        <div class="row" *ngIf="tipoPago == 1">
                            <mat-form-field class="col-md-12" appearance="outline">
                                <mat-label>Autorización:</mat-label>
                                <input matInput formControlName="autorizacion">
                            </mat-form-field>
                        </div>
                        <div class="row" *ngIf="tipoPago == 1">
                            <mat-form-field class="col-md-12" appearance="outline">
                                <mat-label>Digitos TC:</mat-label>
                                <input matInput formControlName="ldigits" maxlength="4" minlength="4">
                            </mat-form-field>
                        </div>
                        <div class="row mb-3" *ngIf="tipoPago != 1">
                            <div class="col-md-8 fw-bold text-end fs-20">
                                Efectivo
                            </div>
                            <div class="col-md-4 text-end fs-20">
                                {{currency + ' ' + (pagoValue | number:'1.2-2')}}
                            </div>
                        </div>
                        <div class="row" *ngIf="tipoPago != 1">
                            <div class="col-md-8 fw-bold text-end fs-20">
                                Total
                            </div>
                            <div class="col-md-4 text-end fs-20">
                                {{currency + ' ' + (fOrden.totalAmount.value | number:'1.2-2')}}
                            </div>
                        </div>
                        <div class="row" *ngIf="tipoPago != 1">
                            <div class="col-md-8 fw-bold text-end fs-20">
                                Cambio
                            </div>
                            <div class="col-md-4 text-end fs-20 color-warn">
                                {{currency + ' ' + ((pagoValue-fOrden.totalAmount.value) | number:'1.2-2')}}
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center">
                            <div class="calculator" *ngIf="tipoPago != 1">
                                <!-- <div class="row">
                                    <mat-form-field appearance="outline" class="text-calc">
                                        <input class="text-end text-pago" matInput readonly [(ngModel)]="pago">
                                    </mat-form-field>
                                </div> -->
                                <div class="row">
                                    <div class="col-md-auto">
                                        <button color="primary" class="calc-btn" (click)="addDigit(7)" mat-flat-button>7</button>
                                        <button color="primary" class="calc-btn mx-2" (click)="addDigit(8)" mat-flat-button>8</button>
                                        <button color="primary" class="calc-btn me-2" (click)="addDigit(9)" mat-flat-button>9</button>
                                        <button color="accent" class="calc-btn" (click)="pago = '0'; pagoValue = 0; valTemp = 0;" mat-flat-button>AC</button>
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col-md-auto">
                                        <button color="primary" class="calc-btn" (click)="addDigit(4)" mat-flat-button>4</button>
                                        <button color="primary" class="calc-btn mx-2" (click)="addDigit(5)" mat-flat-button>5</button>
                                        <button color="primary" class="calc-btn me-2" (click)="addDigit(6)" mat-flat-button>6</button>
                                        <button color="accent" class="calc-btn" (click)="minus()" mat-flat-button>-</button>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-auto">
                                        <button color="primary" class="calc-btn" (click)="addDigit(1)" mat-flat-button>1</button>
                                        <button color="primary" class="calc-btn mx-2" (click)="addDigit(2)" mat-flat-button>2</button>
                                        <button color="primary" class="calc-btn me-2" (click)="addDigit(3)" mat-flat-button>3</button>
                                        <button color="accent" class="calc-btn" (click)="add()" mat-flat-button>+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-auto">
                                        <button color="primary" class="calc-dbl-btn" (click)="addDigit(0)" mat-flat-button>0</button>
                                        <button color="primary" class="calc-btn mx-2" (click)="addDot()" mat-flat-button>.</button>
                                        <button color="accent" class="calc-btn" (click)="result()" mat-flat-button>=</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="me-2" *ngIf="imprimir == 0" mat-flat-button color="primary" (click)="onSubmit()">Facturar</button>
</mat-dialog-actions>