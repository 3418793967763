import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomersService } from '@app/services';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from '@app/shared';
import { AuthService } from '@app/core/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfirmValidParentMatcher } from '@app/validators';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit{
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;

  customer$: Observable<any>;
  delCustomer$: Observable<any>;
  companyId: string = '';
  searchText: string = '';

  length: number = -1;
  pageSize: number = 5;
  _page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  displayedColumns: string[] = ['NAME', 'TAX_ID', 'EMAIL', 'STATUS', 'ACTIONS'];

  confirmValidParentMatcher = new ConfirmValidParentMatcher();
  
  constructor(
    private customersService: CustomersService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private spinnerService: SpinnerService
  ) { }

  customerForm = this.fb.group({
    customerId: [''],
    name: ['', [Validators.required, Validators.maxLength(150), Validators.minLength(3)]],
    taxId: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]],
    address: ['', [Validators.maxLength(200), Validators.minLength(3)]],
    email: ['', [Validators.maxLength(150), Validators.minLength(3)]],
    status: [true],
    companyId: ['']
  })

  get cust(){
    return this.customerForm.controls;
  }

  ngOnInit(): void {
    this.companyId = this.authService.getCompanyId();

    this._page = 0;
    this.loadCustomers(this._page);
  }

  delCustomer(customerId: any){
    this.delCustomer$ = this.customersService.delCustomer(customerId, {status: 0}).pipe(
      map((res:any)=>{
        if (res.Status = 200){
          var cust = this.dataSource.data.filter(x => x.CUSTOMER_ID == customerId);
          if (cust.length >0){
            cust[0].STATUS = 'Inactivo';
          }
        }
      })
    )
  }

  save(){
    this.customerForm.markAllAsTouched();
    if(!this.customerForm.valid) { return; }
    let custId = (this.customerForm.value.customerId == '' ? '0' : this.customerForm.value.customerId)
    let formData = {
      customerId: (this.customerForm.value.customerId == '' ? 0 : this.customerForm.value.customerId),
      name: this.customerForm.value.name,
      taxId: this.customerForm.value.taxId,
      address: this.customerForm.value.address,
      email: this.customerForm.value.email,
      status: this.customerForm.value.status,
      companyId: this.companyId
    }
    let exito: string = 'Cliente '+(this.customerForm.value.customerId == '' ? 'creado' : 'actualizado')+' con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.customer$ = this.customersService.postCustomer(custId, formData).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          if(this.customerForm.value.customerId == ''){
            this.length = this.length+1;
            this.customerForm.patchValue({customerId: res.Data.CUSTOMER_ID});
            let dt: any = this.dataSource.data;
            dt.push(res.Data);
            this.dataSource.data = null;
            this.dataSource.data = dt;
            this.dataSource.sort = this.sort;
          } else {
            let dt: any = this.dataSource.data;
            let result = dt.filter(x => x.CUSTOMER_ID == this.customerForm.value.customerId);
            if(result.length > 0){
              result[0].NAME = res.Data.NAME;
              result[0].TAX_ID = res.Data.TAX_ID;
              result[0].ADDRESS = res.Data.ADDRESS;
              result[0].EMAIL = res.Data.EMAIL;
              result[0].STATUS = res.Data.STATUS;
            }
          }
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
        return res.Data;
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    );
  }

  changeSelected(cat: any){
    this.customerForm.patchValue({
      customerId: cat.CUSTOMER_ID,
      name: cat.NAME,
      taxId: cat.TAX_ID,
      address: cat.ADDRESS,
      email: cat.EMAIL,
      status: (cat.STATUS == 'Activo' ? true : false)
    });
  }

  clear(){
    this.customerForm.reset({customerId: '', name: '', taxId: '', address: '', email: '', status: true, companyId: ''});
  }

  loadCustomers(curPage: number){
    var spinnerRef = this.spinnerService.start();
    this.customer$ = this.customersService.getCustomers(this.companyId, (this.searchText == '' ? '0' : this.searchText), curPage, this.pageSize).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.dataSource.data = res.Data;
          this.dataSource.sort = this.sort;
          if(res.Data.length > 0){
            this.length = res.Data[0].TOTAL;
          } else {
            this.length = 0;
          }
          return res.Data;
        }
        if (res.Status == 401){
          this.length = 0;
          this.dataSource.data = null;
        }
      }),
      catchError(err => {
        console.log(err);
        return err;
      })
    );
  }

  goToPage(page: number, elements: number){
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    this.loadCustomers((this._page == 0 ? 0 : this._page*this.pageSize));
  }

  getErrorMessage(frm: FormGroup, component: string, lbl: HTMLElement, minlength: number, maxlength: number, min: number, max: number){
    let label: string = lbl.innerText;
    let requerido = ' es requerido';
    let minLen = 'Largo mínimo requerido ';
    let maxLen = 'Largo máximo requerido ';
    let minVal = 'Valor mínimo requerido ';
    let maxVal = 'Valor máximo requerido ';
    let invAddr = 'La dirección no es válida';
    let email = 'Formato de correo electrónico inválido';
    return frm.controls[component].hasError('required') ? label + requerido: 
      frm.controls[component].hasError('minlength') ? minLen + minlength:
        frm.controls[component].hasError('maxlength') ? maxLen + maxlength:
          frm.controls[component].hasError('min') ? minVal + min:
            frm.controls[component].hasError('max') ? maxVal + max:
              frm.controls[component].hasError('email') ? email:
                frm.controls[component].hasError('invalidAddress') ? invAddr:
                  '';
  }
}
