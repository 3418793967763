import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '@core/services';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { UsersService } from '@app/services/users.service';
import { GlobalService } from '@app/services';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  accesos$!: Observable<any>;
  monitor$!: Observable<any>;

  screenWidth: number;
  hideBar: boolean = false;
  expandend: boolean = false;
  expandendO: boolean = false;
  expandendP: boolean = false;
  expandendG: boolean = false;
  expandendR: boolean = false;
  expandendS: boolean = false;
  productId: number = 0;
  orderId: number = 0;
  tableId: number = 0;
  userId: number = 0;
  rolId: number = 0;
  accesos: any[]= [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    public usersService: UsersService,
    private globalService: GlobalService,
    private router: Router,
  ) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.TabletLandscape, Breakpoints.Medium, Breakpoints.TabletPortrait, Breakpoints.HandsetLandscape, Breakpoints.Handset, Breakpoints.HandsetPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  ngOnInit(): void {
    this.monitor$ = this.globalService.selectedUser$.pipe(
      map((res: any) => {
        if ('userId' in res) {
          this.userId = res.userId;
        } else {
          this.userId = 0;
        }
      }),
      switchMap(x => this.globalService.selectedOrder$.pipe(
        map((res: any) => {
          if ('orderId' in res) {
            this.orderId = res.orderId;
          } else {
            this.orderId = 0;
          }
        })
      )),
      switchMap(x => this.globalService.selectedTable$.pipe(
        map((res: any) => {
          if ('tableId' in res) {
            this.tableId = res.tableId;
          } else {
            this.tableId = 0;
          }
        })
      ))
    );
   
    this.rolId = this.authService.getRolId();
    this.loadUsuario();
  }

  loadUsuario() {
    this.accesos$ = this.usersService.getAccesos(this.rolId).pipe(
      map((res:any) =>{
        if(res.Status == 200){
          this.accesos = res.Data;
        }
      })
    )
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  getAccesos(opcion: number): boolean{
    let res = this.accesos.filter(x => x.SCREEN_ID == opcion);
    if (res.length > 0){
      if (res[0].PERMISO == 1){
        return true;
      }
    }
    return false;
  }

}