<ng-container *ngIf="orden$ | async"></ng-container>
<ng-container *ngIf="dialog$ | async"></ng-container>
<ng-container *ngIf="mesas$ | async"></ng-container>
<div class="container-fluid order-color">
    <div class="row mb-2 align-items-center">
        <div class="col-md-9">
            <button mat-button class="slider-buttons my-2 btn-left" matTooltip="Atrasar" (click)="scrollLeft(list)">
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
            <div class="mx-2 my-2 col-mesas col-md-10" #list>
                <div class="btn-mesa me-2" *ngFor="let mesa of mesas" (click)="seleccionarMesa(mesa)" [ngClass]="{'mesa-ocupada': mesa.STATUS==0, 'mesa-sel': mesa.TABLE_ID == mesaId}">
                    {{mesa.DESCRIPTION}}
                </div>
            </div>
            <button mat-button class="slider-buttons my-2 float-right" matTooltip="Adelantar" (click)="scrollRight(list)">
                <mat-icon>arrow_forward_ios</mat-icon>
            </button>
        </div>
        <div class="col-md action-closed">
            <div class="row justify-content-end">
                <button mat-button class="btn-refresh col-md-auto" (click)="loadMesas()" matTooltip="Refrescar"><mat-icon class="me-2">refresh</mat-icon> Actualizar</button>
                <button mat-button class="btn-back col-md-auto me-3" matTooltip="Regresar a Menú" [routerLink]="['/welcome']"><mat-icon class="me-2">close</mat-icon> Cerrar</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-4 col-md-4">
            <div class="card-det p-2">
                <form [formGroup]="ordenForm">
                    <div class="title-products pt-2">
                        <div class="row mb-1">
                            <div class="col-md-5">
                                <div class="info-orden">{{'Mesa: ' + mesaText}}</div>
                            </div>
                            <div class="col-md-7">
                                <div class="info-orden text-end">{{'Orden:  ' + (fOrden.ordenId.value != 0 ? (fOrden.ordenId.value | number : '8.0-0').split(',').join('') : '00000000')}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                                <div class="ord-hora">Tiempo de Orden: {{getHora(fOrden.tiempoReal.value)}}</div>
                            </div>
                            <div class="col-md-3">
                                <div class="ord-hora text-end">{{(fOrden.sequence.value != '' ? (fOrden.sequence.value | number : '6.0-0').split(',').join('') : '000000')}}</div>
                            </div>
                        </div>
                        <div class="spacer my-2"></div>
                    </div>
                    <div class="container-products mb-0">
                        <ng-container *ngIf="delLinea$ | async"></ng-container>
                        <section #scrollDiv class="card-content container">
                            <div formArrayName="detail" *ngFor="let det of fDetalle().controls; let i = index">
                                <div [formGroupName]="i" class="row justify-content-between">
                                    <div class="col-md-1 linea-det pe-0">
                                        {{fDetalle().controls[i].get('qty').value}}
                                    </div>
                                    <div class="col-md-6 linea-det product-text">
                                        {{fDetalle().controls[i].get('product').value}}
                                    </div>
                                    <div class="col-md-auto linea-det price pe-0">
                                        {{currency + ' ' + (fDetalle().controls[i].get('unitPrice').value | number:'1.2-2')}}
                                    </div>
                                    <div class="col-md-auto pe-0">
                                        <button mat-icon-button [disabled]="fDetalle().controls[i].get('status').value == 1" (click)="eliminarLinea(i, det.value)" matTooltip="Eliminar" color="warn">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="spacer"></div>
                    <div class="actions-products">
                        <ng-container *ngIf="ord$ | async"></ng-container>
                        <ng-container *ngIf="delOrden$ | async"></ng-container>
                        <div class="row info-orden pt-2">
                            <div class="col-md-12 total">
                                Total {{currency + ' ' + (total | number: '1.2-2')}}
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-auto">
                                <button (click)="deleteOrder()" *ngIf="orderId != 0" class="me-2"  mat-icon-button color="warn" matTooltip="Eliminar">
                                    <mat-icon class="icon-cancelar" svgIcon="trash">
                                    </mat-icon>
                                </button>
                                <button (click)="printRec()" *ngIf="orderId != 0" class="me-2"  mat-icon-button color="primary" matTooltip="Imprimir Recibo">
                                    <mat-icon class="icon-cancelar" svgIcon="print">
                                    </mat-icon>
                                </button>
                                <button (click)="genInvoice()" *ngIf="orderId != 0 && fOrden.status.value == 3" class="me-2" mat-icon-button color="primary" matTooltip="Facturar">
                                    <mat-icon class="icon-invoice" svgIcon="invoice">
                                    </mat-icon>
                                </button>
                                <button (click)="envioCocina()" *ngIf="getNewItems()" mat-icon-button matTooltip="Enviar a Cocina" class="btn-cocina">
                                    <mat-icon class="icon-cocina" svgIcon="cocina">
                                    </mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-8 col-md-8 ps-0">
            <app-product-chip (product)="agregarProducto($event)"></app-product-chip>
        </div>
    </div>
</div>
