<div class="container-fluid px-5">
    <ng-container *ngIf="products$ | async"></ng-container>
    <ng-container *ngIf="product$ | async"></ng-container>
    <ng-container *ngIf="abc$ | async"></ng-container>
    <div class="row justify-content-center">
        <div class="card-title mt-2 px-0 col-md-10 w-100">
            <mat-icon class="icon-title">
                grid_view
            </mat-icon>
            <span class="text-title">Producto</span>
        </div>
        <mat-card class="mt-0 col-md-10 w-100">
            <mat-card-content>
                <ng-container *ngIf="length > 0; else noInfo">
                    <div class="row">
                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>Buscar</mat-label>
                            <input matInput placeholder="Buscar" [(ngModel)]="texto" name="buscar">
                        </mat-form-field>
                        
                        <button mat-flat-button color="primary" class="col-md-auto h-40 me-3" (click)="searchData()">
                            <mat-icon class="btn-search" matSuffix>search</mat-icon>
                            Buscar
                        </button>
                        <button mat-icon-button matTooltip="Actualizar" (click)="refreshSearch()">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                    <ng-container *ngIf="delProduct$ | async"></ng-container>
                    <mat-card class="card-table py-0">
                        <mat-table matSort [dataSource]="dataSource">
                            <ng-container matColumnDef="NAME">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-3 title-cell"> PRODUCTO </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="col-md-3"> {{row.NAME}} </mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="IMPUESTO">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-2 title-cell"> IMPUESTO </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="col-md-2"> {{row.IMPUESTO}} </mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="CATEGORIA">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-3 title-cell"> CATEGORIA </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="col-md-3"> {{row.CATEGORIA}} </mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="STATUS">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-2 title-cell"> STATUS </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="col-md-2"><span [ngClass]="{'active': row.STATUS == 'Activo', 'inactive': row.STATUS == 'Inactivo'}">{{row.STATUS}}</span></mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="ACTIONS">
                                <mat-header-cell *matHeaderCellDef class="col-md-2 title-cell">ACCIONES</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="col-md-2">
                                    <button mat-icon-button matTooltip="Ver Producto" (click)="selectProduct(row.PRODUCT_ID)"><mat-icon>dvr</mat-icon></button>
                                    <button *ngIf="row.STATUS == 'Activo'" matTooltip="Desactivar" mat-icon-button (click)="delProducto(row.PRODUCT_ID)" ><mat-icon color="warn" class="icon-usuario">delete_forever</mat-icon></button>
                                </mat-cell>
                            </ng-container> 
                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <mat-paginator #paginator class="col-md-12 mat-paginator-sticky"
                            [length]="length"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="goToPage($event.pageIndex, $event.pageSize)">
                        </mat-paginator>
                    </mat-card>
                </ng-container>
                <ng-template #noInfo>
                    <ng-container *ngIf="length == 0">
                        <div class="col-md-12 no-data my-5 text-center" >No existen registros en su búsqueda<br/>cambie los datos de su filtro</div>
                    </ng-container>
                </ng-template>
                <ng-container *ngIf="image$ | async"></ng-container>
                <mat-tab-group dynamicHeight mat-align-tabs="start" [selectedIndex]="iTabs" (selectedTabChange)="onChangeTab($event)">
                    <mat-tab label="Información" class="d-flex justify-content-start">
                        <form [formGroup]="productForm">
                            <div class="row">
                                <div class="col-md-7 col-lg-4">
                                    <mat-form-field appearance="outline" class="first-item">
                                        <mat-label #nombre>Nombre</mat-label>
                                        <input matInput placeholder="Nombre" formControlName="name" maxlength="70" minlength="3" [errorStateMatcher]="confirmValidParentMatcher" required>
                                        <mat-error *ngIf="f.name.invalid && f.name.touched">
                                            {{ 'Error: ' + getErrorMessage(productForm, 'name', nombre, 3, 70, 0, 0)}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-5 col-lg-2 checkbox-item" *ngIf="f.productId.value != '0'">
                                    <mat-checkbox formControlName="status">Estatus {{f.status.value == true ? 'Activo' : 'Inactivo'}}</mat-checkbox>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-7">
                                    <mat-form-field appearance="outline">
                                        <mat-label #des>Descripción</mat-label>
                                        <input matInput placeholder="Descripción" formControlName="description" maxlength="120" minlength="3" [errorStateMatcher]="confirmValidParentMatcher" required>
                                        <mat-error *ngIf="f.description.invalid && f.description.touched">
                                            {{ 'Error: ' + getErrorMessage(productForm, 'description', des, 3, 120, 0, 0)}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-7">
                                    <mat-form-field appearance="outline">
                                        <mat-label #obser>Observaciones</mat-label>
                                        <textarea matInput placeholder="Observaciones" formControlName="obs" maxlength="500" minlength="3" [errorStateMatcher]="confirmValidParentMatcher"></textarea>
                                        <mat-error *ngIf="f.obs.invalid && f.obs.touched">
                                            {{ 'Error: ' + getErrorMessage(productForm, 'obs', obser, 3, 500, 0, 0)}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label #precio>Precio Unitario</mat-label>
                                        <input matInput placeholder="Precio Unitario" formControlName="unitPrice" [errorStateMatcher]="confirmValidParentMatcher" required>
                                        <mat-error *ngIf="f.unitPrice.invalid && f.unitPrice.touched">
                                            {{ 'Error: ' + getErrorMessage(productForm, 'unitPrice', precio, 0, 0, 0, 0)}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label #catego>Categoría</mat-label>
                                        <mat-select formControlName="categoryId" required>
                                            <mat-option *ngFor="let cat of cats" [value]="cat.CATEGORY_ID">
                                            {{cat.DESCRIPTION}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="f.categoryId.invalid && f.categoryId.touched">
                                            {{ 'Error: ' + getErrorMessage(productForm, 'categoryId', catego, 0, 0, 0, 0)}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label #impuesto>Impuesto</mat-label>
                                        <mat-select formControlName="taxId" required>
                                            <mat-option *ngFor="let tax of taxes" [value]="tax.TAX_ID">
                                            {{tax.DESCRIPTION}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="f.taxId.invalid && f.taxId.touched">
                                            {{ 'Error: ' + getErrorMessage(productForm, 'taxId', impuesto, 0, 0, 0, 0)}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-lg-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label #vari>Variable ROBOT</mat-label>
                                        <input matInput placeholder="Variable ROBOT" formControlName="variable" maxlength="5" minlength="3" [errorStateMatcher]="confirmValidParentMatcher">
                                        <mat-error *ngIf="f.variable.invalid && f.variable.touched">
                                            {{ 'Error: ' + getErrorMessage(productForm, 'variable', vari, 3, 5, 0, 0)}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 col-lg-2">
                                    <mat-checkbox formControlName="inventory">Inventario</mat-checkbox>
                                </div>
                                <div class="col-md-5 col-lg-2">
                                    <mat-checkbox formControlName="mesero">Despacho Bar</mat-checkbox>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <mat-checkbox formControlName="souvenier">Souvenier</mat-checkbox>
                                </div>
                            </div>
                        </form>
                        <div class="row">
                            <div class="col-md-10 col-lg-3">
                                <button mat-flat-button class="btn" id="save" type="submit" color="primary" text-center w-100 (click)="onSubmit()">Guardar</button>
                                <button mat-flat-button class="btn ms-3" id="cancel" type="button" color="accent" text-center w-100 (click)="newProduct()">{{ f.productId.value == '0' ? 'Nuevo' : 'Cancelar'}}</button>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Fotografía" class="d-flex justify-content-start">
                        <form [formGroup]="imageForm" text-center autocomplete="off">
                            <h5 class="display-learn">Imagen</h5>
                            <div class="sector">
                                <div class="img-frame">
                                    <img *ngIf="f.imagen.value && fileString == undefined" class="image-st" [src]="imgPath+f.imagen.value">
                                    <img *ngIf="fileString != undefined" [src]="fileString" class="image-st">
                                </div>
                                <div class="default-overlay">
                                    <button type="button" mat-icon-button class="camera-avatar" (click)="onSearchImage()"><mat-icon>add_a_photo</mat-icon>
                                    </button>
                                    <input id="fileUpload" name="fileUpload" type="file" accept="image/*" style="display:none;" />
                                </div>
                            </div>
                        </form>
                        <div class="row">
                            <div class="col-md-10 col-lg-3">
                                <button *ngIf="f.productId.value == '0'" mat-flat-button class="btn" id="save" type="submit" color="primary" text-center w-100 (click)="onSubmit()">Guardar</button>
                                <button *ngIf="f.productId.value != '0'" mat-flat-button class="btn" id="save" type="submit" color="primary" text-center w-100 (click)="onSubmitImage(f.productId.value)">Guardar</button>
                                <button mat-flat-button class="btn ms-3" id="cancel" type="button" color="accent" text-center w-100 (click)="newProduct()">{{ f.productId.value == '0' ? 'Nuevo' : 'Cancelar'}}</button>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                
            </mat-card-content>
        </mat-card>
    </div>
</div>