<ng-container *ngIf="isAdmin$ | async"></ng-container>
<div mat-dialog-content class="p-3">
    <div class="row mb-1">
        <div class="col-md-12 text-center">
            <div class="title">Ingrese Usuario Admin</div>
        </div>
    </div>
    <div class="row">
        <form [formGroup]="userForm">
            <mat-form-field class="col-md-12" appearance="standard">
                <mat-label>Usuario:</mat-label>
                <input matInput formControlName="usuario">
            </mat-form-field>
            <mat-form-field  class="col-md-12" appearance="standard">
                <mat-label>Password:</mat-label>
                <input matInput type="password" formControlName="password">
            </mat-form-field>
        </form>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="warn" (click)="close()">
        Cancelar
    </button>
    <button mat-flat-button color="primary" (click)="onSubmit()">
        Procesar
    </button>
</div>
