import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/core/services';
import { GlobalService, InvoicesService, OrdersService } from '@app/services';
import { Observable } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

export interface printData {
  orderId: string;
  recibo: number;
}
@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  base: printData;
  order: string = '';
  rec: number;

  print$: Observable<any>;
  invoice$: Observable<any>;
  order$: Observable<any>;
  factura: [] = [];
  taxes: [] = [];

  orden: number = 0;
  businessName: string;
  cAddress: string;
  cTelefono: string = "Teléfono: 3272-6679";
  cai: string;
  cRTN: string;
  currency: string;
  startInv: string;
  endInv: string;
  invoiceId: string;
  dueDate: Date = new Date();
  invoiceDate: Date = new Date();
  totalAmount: number;
  totalTax: string;
  tip: string;
  customer: string;
  taxId: string;
  address: string;
  subTotal: number;
  descuento: number;
  mesaNo: number;
  user:any;

  constructor(
    private global: GlobalService,
    private ordersService: OrdersService,
    private invoicesService: InvoicesService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<PrintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: printData
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserProfile();
    this.businessName = this.user.businessName;
    this.cAddress = this.user.address;
    this.cRTN = this.user.taxId;
    this.currency = this.user.currency;
    this.order = this.data.orderId;
    this.rec = this.data.recibo;
    if(this.rec == 0){
      this.getInvoice(this.order);
    }else{
      this.getData(this.order);
    }
  }

  getData(ord: any){
    this.order$ = this.ordersService.getOrder(ord, 0).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.orden = res.Data.sequence;
          this.mesaNo = res.Data.tableNo;
          this.subTotal = (res.Data.totalAmount-res.Data.totalTax);
          this.totalAmount = res.Data.totalAmount;
          this.totalTax = res.Data.totalTax;
          this.descuento = res.Data.totalDiscount;
          this.invoiceDate = new Date(res.Data.orderDate.replace('T', ' ').replace('Z', ''));
          this.cai = 'NULL';
          this.factura = res.Data.detail;
          this.taxes = res.Data.taxes;
          this.generateUnderlines();
          return res.Data;
        }
      }),
      switchMap((x: any) => x =  this.global.printOrder(ord)),
      delay(1000),
      map((res: any)=> {
        if (res.Status == 200){
          window.print();
          this.dialogRef.close();
          return res.Data;
        }
      }) 
    );
  }

  getInvoice(invId:any){
    this.invoice$ = this.invoicesService.getInvoice(invId).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.subTotal = (res.Data.totalAmount-res.Data.totalTax);
          this.totalAmount = (res.Data.totalAmount);
          this.totalTax = res.Data.totalTax;
          this.descuento = res.Data.totalDiscount;
          this.invoiceDate = new Date(res.Data.invoiceDate.replace('T', ' ').replace('Z', ''));
          this.taxId = res.Data.taxId,
          this.customer = res.Data.nombre,
          this.address = res.Data.direccion,
          this.invoiceId = res.Data.numeroInvoice
          this.factura = res.Data.detail;
          this.tip = res.Data.tip;
          this.taxes = res.Data.taxes;

          this.cai = res.Data.cai;
          this.startInv = res.Data.startInvoice;
          this.endInv = res.Data.endInvoice;
          this.dueDate = (res.Data.dueDate == null ? null : new Date(res.Data.dueDate.replace('T', ' ').replace('Z', '')));
          this.generateUnderlines();
          return res.Data;
        }
      }),
      switchMap((x: any) => x =  this.invoicesService.printInvoice(invId)),
      delay(1000),
      map((res: any)=> {
        if (res.Status == 200){
          window.print();
          this.dialogRef.close();
        }
        return res.Data;
      })
    );
  }

  generateUnderlines() {
    let width = window.innerWidth;
    let numUnderlines = Math.floor(width / 10);
    let underlines = "_".repeat(numUnderlines);
    let underlinesElements = document.getElementsByClassName("underlines");
    for (var i = 0; i < underlinesElements.length; i++) {
      underlinesElements[i].textContent = underlines;
    }
  }
}
