<div class="container-fluid px-5">
    <div class="row justify-content-center">
        <div class="card-title mt-2 px-0 col-md-10 w-100">
            <mat-icon class="icon-title">
                category
            </mat-icon>
            <span class="text-title">Categorías</span>
        </div>
        <mat-card class="mt-0 col-md-10 w-100">
            <mat-card-content>
                <ng-container *ngIf="cats$ | async"></ng-container>
                <ng-container *ngIf="length > 0; else noCats">
                    <mat-card class="card-table py-0">
                        <mat-table matSort [dataSource]="dataSource">
                            <ng-container matColumnDef="DESCRIPTION">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-5 title-cell"> DESCRIPCION </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="col-md-5"> {{row.DESCRIPTION}} </mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="ORDER_CAT">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-3 title-cell"> ORDER CAT </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="col-md-3"> {{row.ORDER_CAT}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="STATUS">
                                <mat-header-cell mat-sort-header *matHeaderCellDef class="col-md-2 title-cell"> ESTATUS </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="col-md-2"><span [ngClass]="{'active': row.STATUS == true, 'inactive': row.STATUS == false}">{{row.STATUS == false ? 'Inactivo' : 'Activo'}}</span></mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="ACTIONS">
                                <mat-header-cell *matHeaderCellDef class="col-md-2 title-cell">ACCIONES</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="col-md-2">
                                    <button mat-icon-button matTooltip="Ver Categoría" class="icon-view" (click)="changeSelected(row)"><mat-icon>dvr</mat-icon></button>
                                    <button *ngIf="row.STATUS == '1'" matTooltip="Desactivar" mat-icon-button (click)="delCat(row.CATEGORY_ID)" ><mat-icon color="warn" class="icon-usuario">delete_forever</mat-icon></button>
                                </mat-cell>
                            </ng-container> 
                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <mat-paginator #paginator class="col-md-12 mat-paginator-sticky"
                            [length]="length"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="goToPage($event.pageIndex, $event.pageSize)">
                        </mat-paginator>
                    </mat-card>
                </ng-container>
                <ng-template #noCats>
                    <ng-container *ngIf="length == 0">
                        <div class="noData text-center">Pendiente la configuración de las Categorías de productos para esta Compañia, <br> ingrese la información de las categorías en el formulario</div>
                    </ng-container>
                </ng-template>
                <form [formGroup]="catsForm" class="p-4 border">
                    <div class="row">
                        <div class="col-md-8 col-lg-6">
                            <mat-form-field appearance="outline">
                                <mat-label #des03>Descripción</mat-label>
                                <input matInput placeholder="Descripción" name="description" formControlName="description" [errorStateMatcher]="confirmValidParentMatcher" required max="100" min="3">
                                <mat-error *ngIf="cats.description.invalid && cats.description.touched">
                                    {{ 'Error: ' + getErrorMessage(catsForm, 'description', des03, 3, 100, 0, 0)}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-lg-2 my-0" *ngIf="cats.categoryId.value != ''">
                            <mat-checkbox formControlName="status">Estatus {{cats.status.value == true ? 'Activo' : 'Inactivo'}}</mat-checkbox>
                        </div>
                    </div>                        
                    <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <mat-form-field appearance="outline">
                                <mat-label #val>Categoría Orden</mat-label>
                                <input matInput placeholder="Categoría Orden" name="orderCat" formControlName="orderCat" [errorStateMatcher]="confirmValidParentMatcher" max="999" min="1">
                                <mat-error *ngIf="cats.orderCat.invalid && cats.orderCat.touched">
                                    {{ 'Error: ' + getErrorMessage(catsForm, 'orderCat', val, 0, 0, 1, 100)}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 col-lg-2">
                            <mat-form-field appearance="outline">
                                <mat-label #col>Color</mat-label>
                                <input type="color" matInput placeholder="Color" name="color" formControlName="color">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-lg-2">
                            <mat-label>Acepta Descuentos</mat-label>
                            <mat-checkbox formControlName="allowDiscount"></mat-checkbox>
                        </div>
                    </div>
                </form>
                <button mat-flat-button class="btn" id="save" type="submit" color="primary" text-center w-100 (click)="save()">Guardar</button>
                <button mat-flat-button class="btn ms-3" id="cancel" type="button" color="accent" text-center w-100 (click)="clear()">{{ cats.categoryId.value == '' ? 'Nuevo' : 'Cancelar'}}</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>