import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '@app/services';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PrintComponent } from '@app/shared';
import { DatePipe, formatNumber } from '@angular/common';

@Component({
  selector: 'app-cierre',
  templateUrl: './cierre.component.html',
  styleUrls: ['./cierre.component.scss']
})
export class CierreComponent implements OnInit {
  invoices: any[]=[];
  result : any[]=[];
  headers : any[]=[];
  cierre$: Observable<any>;
  dateCierre: any;
  tipo: number = 0;
  pageEvent: PageEvent;
  pageSize: number = 5;
  length: number = 0;
  lengthTabla: number = 0;
  _page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  
  loading: boolean = false;
  isRateLimitReached: boolean = false;
  dateStart: string;
  dateEnd: string;
  displayedColumns:string[] =[];
  displayedColumnsI: string[]= ['NUMBER','DATE','TOTAL_TAX','TOTAL_AMOUNT','TIP','NAME','RTN','PAYMENT_TYPE','AUTORIZACION'];
  displayedColumnsP: any[] = ['NAME_ESP', 'UNIT_PRICE', 'INVENTORY', 'QTY', 'IMPUESTO', 'CATEGORIA', 'ORDENES'];
  displayedColumnsO: any[] = ['TOTAL_TAX', 'TOTAL_AMOUNT', 'TABLE_NO', 'STATUS', 'ORDER_DATE', 'TOTAL_DISCOUNT', 'TIEMPO_REAL'];

  headersI: any[] = [
    {codigo:'NUMBER', header: 'Factura'}, 
    {codigo:'DATE', header: 'Fecha'}, {codigo:'TOTAL_TAX', header: 'Imp. Total'}, 
    {codigo:'TOTAL_AMOUNT', header: 'Total'}, {codigo:'TIP', header:'Servicio'}, 
    {codigo:'NAME', header: 'Cliente'}, {codigo:'RTN', header: 'RTN'},
    {codigo:'PAYMENT_TYPE', header: 'Tipo Pago'}, {codigo:'AUTORIZACION', header: 'Autorizacion'}];
  headersP: any[] = [
    {codigo:'NAME_ESP', header:'Producto'}, {codigo:'UNIT_PRICE', header:'Precio'}, 
    {codigo:'INVENTORY', header:'Inventario'}, {codigo:'QTY', header:'Cantidad'}, {codigo:'IMPUESTO', header:'Impuesto'}, 
    {codigo:'CATEGORIA', header:'Categoria'}, {codigo:'ORDENES', header:'Cant. Ordenes'}];
  headersO: any[]= [{codigo:'TOTAL_TAX', header: 'Imp. Total'}, {codigo:'TOTAL_AMOUNT', header: 'Total'}, 
    {codigo:'TABLE_NO', header: 'Mesa No.'}, {codigo:'STATUS', header: 'Estado'}, {codigo:'ORDER_DATE', header:'Fecha'}, 
    {codigo:'TOTAL_DISCOUNT', header: 'Total Desc.'}, {codigo:'TIEMPO_REAL', header: 'Tiempo Real'}];
  dataSource = new MatTableDataSource<any>(this.result);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private global: GlobalService,
    private fb: UntypedFormBuilder,
    private stickerDialog: MatDialog,

  ) { }

  ngOnInit(): void {
    this.headers = this.headersP;
    this.displayedColumns = this.displayedColumnsP;
    this.autocomplete();
  }
  reportForm = this.fb.group({
    rtn: '',
  });

  searchRecords(curPage:number){
    if (this.dateCierre == undefined || this.dateCierre == '') { return; }
    this.loading = true;
    this.cierre$ = this.global.GetCierre(this.tipo, this.dateStart, this.dateEnd, this.pageSize, curPage).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.result = res.Data;
          this.length = res.Data[0].TOTAL;
          this.dataSource.data = this.result;
          this.dataSource.sort = this.sort;
          this.loading = false;
        }
      }),
      catchError((err: any) => {
        this.loading = false;
        this.isRateLimitReached = true;
        return err;
      })
    );
    return event;
  }

  goToPage(page: number, elements: number){
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    this.searchRecords((this._page == 0 ? 0 : this._page*this.pageSize));
  }

  setFechas(fecha:any){
    this.dateEnd = String(new Date(fecha).getFullYear())+"-" +String(new Date(fecha).getMonth()+ 1 ) + "-" + String(new Date(fecha).getDate());
    this.dateStart = String(new Date(fecha).getFullYear())+"-" +String(new Date(fecha).getMonth()+ 1 ) + "-1";
  }

  changeDateP(event:Date){
    this.setFechas(event.toISOString());
    if(this.tipo ==0){
      this.headers = this.headersP;
      this.displayedColumns = this.displayedColumnsP;
    }
    if(this.tipo ==1){
      this.headers = this.headersI;
      this.displayedColumns = this.displayedColumnsI;
    }
    if(this.tipo ==2){
      this.headers = this.headersO;
      this.displayedColumns = this.displayedColumnsO;
    }
    this.searchRecords(0);
  }

  exportexcel(): void {
    if (this.dateCierre == undefined || this.dateCierre == '') { return; }
    
    let dateApp: Date = this.dateCierre;
    let year = dateApp.getFullYear().toString();
    let month = (dateApp.getMonth()+1).toString().padStart(2, '0'); 
    let day = dateApp.getDate().toString().padStart(2, '0');
    let valFecha = year + '-' + month + '-' + day;

    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Cierre Diario');
 
    /* save to file */  
    XLSX.writeFile(wb, "Cierre-"+valFecha+".xlsx");
  }

  autocomplete(){
    // this.reportForm.get('rtn').valueChanges
    //   .pipe(
    //     distinctUntilChanged(),
    //     debounceTime(1000),
    //     tap(() => {
    //       this.invoices = [];
    //     }),
    //     switchMap(value => this.global.GetReportInvoice(this.reportForm.get('rtn').value)
    //       .pipe(
    //         finalize(() => {
    //         }),
    //       )
    //     )
    //   )
    //   .subscribe((res: any) => {
    //     if (res.Status == 200) {
    //       this.invoices = res.Data;
    //     } else {
    //       this.invoices = [];
    //     }
    //   });
  }

  getInvoice(event:any){
    console.log(event)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      orderId: event.INVOICE_ID,
      recibo: 1
    };
    dialogConfig.width ='100%';
    dialogConfig.minWidth = '100%';
    dialogConfig.maxWidth = '100%';
    dialogConfig.height ='100%';
    dialogConfig.minHeight ='100%';
    this.stickerDialog.open(PrintComponent, dialogConfig);
  }

  displayFn(invoice: any){
    return invoice && invoice.RTN ? invoice.RTN : '';
  }

  loadData(tipo:any){
    this.tipo =tipo;
    if(this.tipo ==0){
      this.headers = this.headersP;
      this.displayedColumns = this.displayedColumnsP;
    }
    if(this.tipo ==1){
      this.headers = this.headersI;
      this.displayedColumns = this.displayedColumnsI;
    }
    if(this.tipo ==2){
      this.headers = this.headersO;
      this.displayedColumns = this.displayedColumnsO;
    }
    this.searchRecords(0);
  }

  trackRow(index: number, item: any){
    return item;
  }

  convertirTexto(tipo:any, texto:any){
    if(tipo == 'STATUS'){
      if(texto == 0){
        return "Pendiente";
      }
      if(texto == 1){
        return "En Proceso";
      }
      if(texto == 2){
        return "Despachada";
      }
      if(texto == 3){
        return "Impresa";
      }
      if(texto == 4){
        return "Cancelada";
      }
      if(texto == 5){
        return "Finalizada";
      }
    }
    if(tipo == "INVENTORY"){
      if(texto == true){
        return 'SI';
      }else{
        return 'NO';
      }
    }
  if(tipo == "DATE" || tipo == 'ORDER_DATE'){
    const datepipe: DatePipe = new DatePipe('en-US');
    let formattedDate = datepipe.transform(texto, 'dd MMM yyyy');
    return formattedDate;
  }
  if(tipo == "PAYMENT_TYPE"){
    if(texto == false){
      return 'Efectivo';
    }else{
      return 'Tarjeta';
    }
  }
  if(tipo == "TOTAL_TAX" || tipo == "TIP" || tipo == "TOTAL_AMOUNT" || tipo == "TOTAL_DISCOUNT" || tipo =="UNIT_PRICE"){
    return formatNumber(texto, 'en-US', '1.2-2');
  }
  if(tipo == "TIEMPO_REAL"){
    var seconds = parseInt(texto);
    var hour = Math.floor(seconds / 3600);
    hour = (hour < 10)? 0 + hour : hour;
    var minute = Math.floor((seconds / 60) % 60);
    minute = (minute < 10)? 0 + minute : minute;
    var second = seconds % 60;
    second = (second < 10)? 0 + second : second;
    return hour + 'hr ' + minute + 'min ';
  }
    return texto
  }

}
