import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface DetailData {
  orderId: string;
}

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  order$: Observable<any>;
  orderDisc$: Observable<any>;
  delLine$: Observable<any>;
  orderId: string;
  orders!: any[];
  total: number = 0;
  totalTemp: number = 0;
  descuento: string = '0';
  porc_desc: string = '1';
  valDescuento: number = 0;
  tempDescuento: number = 0;
  totTax: number = 0;
  totTax01: number = 0;
  totTax02: number = 0;
  totAmount: number = 0;
  tip: number = 0;
  loading: boolean = false;
  orderDet: any[] = [];
  tipVal: boolean = true;

  constructor(
    private global: GlobalService,
    private dialogRef: MatDialogRef<DetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DetailData
  ) { }

  ngOnInit(): void {
    this.orderId = this.data.orderId;
    this.loadOrder();
  }

  onNoClick(){
    this.dialogRef.close();
  }

  updateTotal(){
    if (this.tipVal == false) {
      this.tip = 0;
    }
    this.totalTemp = this.orders.map(a => a.AMOUNT).reduce(function(a, b){
      return (a || 0) + (b || 0);
    });
    if (this.porc_desc == '1') {
      if (+this.descuento == 0){
        this.valDescuento = 0;
        this.total = this.totalTemp;
      } else {
        this.valDescuento = (+this.descuento*.25);
        this.total = this.totalTemp - (+this.descuento*.25);
      }
    } else {
      if (+this.descuento.toString().split('*').length < 2){
        this.valDescuento = 0;
        this.total = this.totalTemp;
      } else {
        this.valDescuento = +this.descuento.toString().split('*')[0]*(+this.descuento.toString().split('*')[1]/100);
        this.total = this.totalTemp - (+this.descuento.toString().split('*')[0]*(+this.descuento.toString().split('*')[1]/100));
      }
    }
    if (this.tempDescuento > 0){
      this.valDescuento = this.tempDescuento;
      this.total = this.totalTemp - this.valDescuento;
    }

    this.totTax01 = this.orderDet
      .map((a: any) => {
        if (a.TAX_ID == 2) { 
          return a.TAX*a.AMOUNT; 
        }
      }).reduce(function(a, b)
      {
        return (a || 0) + (b || 0);
      });

    let res: boolean =false;
    this.totTax02 = this.orderDet
      .map((a: any) => {
        if (a.TAX_ID == 1) {
          if (res == false) {
            if (a.AMOUNT > this.valDescuento){
              res = true;
              return a.TAX*(a.AMOUNT-this.valDescuento); 
            } else {
              return a.TAX*a.AMOUNT; 
            }
          } else {
            return a.TAX*a.AMOUNT; 
          }
        }
      }).reduce(function(a, b)
      {
        return (a || 0) + (b || 0);
      });

    this.totTax = (this.totTax01 || 0) + (this.totTax02 || 0);
    if (this.tipVal == true){
      this.tip = Number(this.total)*Number(0.1);
    }
    this.totAmount = Number(this.total)+Number(this.totTax)+Number(this.tip);
  }

  removeItem(i: number, order: any){
    // this.delLine$ = this.global.RemoveLine(this.orderId, order.LINE_NO).pipe(
    //   map((res: any) => {
    //     this.loading = false;
    //     if (res.Status == 200){
    //       this.orders.splice(i, 1);
    //       this.updateTotal();
    //       return res.Data;
    //     }
    //   })
    // );
  }

  processDiscount(){
    // if (this.valDescuento == 0) { this.dialogRef.close(); }
    // this.loading = true;
    // this.orderDisc$ = this.global.PostDiscount(this.orderId, this.valDescuento, this.tip).pipe(
    //   map((res: any) => {
    //     this.loading = false;
    //     if (res.Status == 200){
    //       this.dialogRef.close();
    //       // return res.Data;
    //     }
    //   })
    // );
  }

  loadOrder(){
    // this.order$ = this.global.GetOrder(this.orderId).pipe(
    //   map((res: any) => {
    //     if (res.Status == 200){
    //       if (res.Data.length > 0){
    //         this.tip = res.Data[0].TIP;
    //         this.valDescuento = res.Data[0].TOTAL_DISCOUNT;
    //         this.tempDescuento = res.Data[0].TOTAL_DISCOUNT;
    //         this.total = res.Data.map(a => a.AMOUNT).reduce(function(a, b)
    //         {
    //           return a + b;
    //         });
    //         this.totalTemp = res.Data.map(a => a.AMOUNT).reduce(function(a, b)
    //         {
    //           return a + b;
    //         });
    //         this.orderDet = res.Data;
    //         this.totTax01 = this.orderDet
    //           .map((a: any) => {
    //             if (a.TAX_ID == 2) { 
    //               return a.TAX*a.AMOUNT; 
    //             }
    //           }).reduce(function(a, b)
    //           {
    //             return (a || 0) + (b || 0);
    //           });
            
    //         let resB: boolean =false;
    //         this.totTax02 = this.orderDet
    //           .map((a: any) => {
    //             if (a.TAX_ID == 1) {
    //               if (resB == false) {
    //                 if (a.AMOUNT > this.valDescuento){
    //                   resB = true;
    //                   return a.TAX*(a.AMOUNT-this.valDescuento); 
    //                 } else {
    //                   return a.TAX*a.AMOUNT; 
    //                 }
    //               } else {
    //                 return a.TAX*a.AMOUNT; 
    //               }
    //             }
    //           }).reduce(function(a, b)
    //           {
    //             return (a || 0) + (b || 0);
    //           });

    //         this.totTax = (this.totTax01 || 0) + (this.totTax02 || 0);
    //         // this.tip = (this.tip == 0 ? +this.total*.10 : this.tip);
    //         this.tipVal = (this.tip == 0 ? false : true);
    //         this.totAmount = this.total-this.valDescuento+this.totTax+this.tip;
    //         this.orders = res.Data;
    //         return res.Data;
    //       }
    //     }
    //   })
    // );
  }

}
