<ng-container *ngIf="invoice$ | async"></ng-container>
<ng-container *ngIf="print$ | async"></ng-container>
<ng-container *ngIf="order$ | async"></ng-container>

<div class="non-print container-fluid example-screen">
    <div class="col-12 center mb-2 numorden" *ngIf="rec == 1">{{orden.toString().padStart(5, '0')}}</div>
    <div class="col-12 pb-2 center" *ngIf="rec == 0">
        <img src="/assets/images/logo.svg" class="img-02">
    </div>
    <div class="col-12 center">{{businessName}}</div>
    <div class="col-12 center">{{cAddress}}</div>
    <div class="col-12 center">{{cTelefono}}</div>
    <div class="col-12 center pb-1" *ngIf="rec == 0">{{'RTN: ' +cRTN}}</div>
    <div class="spacer"></div>
    <ng-container *ngIf="cai !== 'NULL'">
        <div class="col-12 center pt-1" *ngIf="rec == 0">{{'CAI: '+cai}}</div>
        <div class="row" *ngIf="rec == 0">
            <div class="col-3">Rango Autorizado </div>
            <div class="col-3">{{startInv}}</div>
            <div class="col-3">A</div>
            <div class="col-3">{{endInv}}</div>
        </div>
        <div class="col-12 pb-1" *ngIf="rec == 0">{{'Fecha Limite Emision ' + dueDate | date:'medium'}}</div>
        <div class="spacer" *ngIf="rec == 0"></div>
    </ng-container>
    <div class="col-12 left pt-1" *ngIf="rec == 0">{{'Factura: ' + invoiceId}}</div>
    <div class="col-12 left pb-1">{{'Fecha: ' + invoiceDate | date:'medium'}}</div>
    <div class="col-12 left pb-1" *ngIf="rec == 1">{{'Mesa #' + mesaNo}}</div>
    <div class="spacer"></div>
    <div class="col-12 pt-2"></div>
    <div *ngFor="let item of factura">
        <div class="row">
            <div class="col-9 left">{{item.qty + ' ' + item.product}}</div>
            <div class="col-3 right">{{currency}}{{item.amount | number:'1.2-2'}}</div>
        </div>
    </div>
    <div class="col-12 pb-2"></div>
    <div class="row">
        <div class="col-6 left">Descuento </div>
        <div class="col-6 right">{{currency}}{{descuento | number:'1.2-2'}}</div>
    </div>
    <div class="row">
        <div class="col-6 left">Sub-Total </div>
        <div class="col-6 right">{{currency}}{{subTotal | number:'1.2-2'}}</div>
    </div>
    <div class="col-6 left"> Servicio:</div>
    <div class="col-6 right" *ngIf="rec == 0">{{tip}}</div>
    <div class="col-12 pb-2"></div>
        <div class="row" *ngFor="let tax of taxes">
            <div class="col-6 left">{{tax.descripcion}}</div>
            <div class="col-6 right">{{currency}}{{tax.taxAmount | number:'1.2-2'}}</div>
        </div>
    <div class="col-12 pb-2"></div>
    <div class="row mb-2">
        <div class="col-6 left">Total a Pagar</div>
        <div class="col-6 right">{{currency}}{{totalAmount | number:'1.2-2'}}</div>
    </div>
    <div class="spacer mb-2"></div>
    <ng-container *ngIf="rec == 0">
        <div class="col-12 repeating-characters">
            <span class="text-previo">No. Correlativo exento: </span>
            <span class="underlines"></span>
        </div>
        <div class="col-12 repeating-characters">
            <span class="text-previo">No. Correlativo exonerado: </span>
            <span class="underlines"></span>
        </div>
        <div class="col-12 repeating-characters">
            <span class="text-previo">No. Registro S.A.G: </span>
            <span class="underlines"></span>
        </div>
        <div class="col-12 pb-2"></div>
        <div class="col-12 left">ORIGINAL: CLIENTE</div>
        <div class="col-12 left">COPIA: Obligado Tributario Emisor</div>
    </ng-container>
    <ng-container *ngIf="rec == 1">
        <div class="col-12 pb-2"></div>
        <div class="col-12 left"> RTN: {{taxId}}</div>
        <div class="spacer-datos mb-2"></div>
        <div class="col-12 left"> NOMBRE: {{customer}}</div>
        <div class="spacer-datos mb-2"></div>
        <div class="col-12 left"> DIRECCION: {{address}}</div>
        <div class="spacer-datos mb-2"></div>
    </ng-container>
    <div class="col-12 center">Agredecemos su Visita</div>
</div>
<div class="print-area container-fluid example-print" id="printeableArea">
    <div *ngIf="rec == 1" class="col-12 center mb-2 numorden">{{orden.toString().padStart(5, '0')}}</div>
    <div *ngIf="rec == 0" class="col-12 pb-2 center">
        <img src="/assets/images/logo.svg" class="img-02">
    </div>
    <div class="col-12 center">{{businessName}}</div>
    <div class="col-12 center">{{cAddress}}</div>
    <div class="col-12 center">{{cTelefono}}</div>
    <div class="col-12 center pb-1" *ngIf="rec == 0">{{'RTN: ' +cRTN}}</div>
    <div class="spacer"></div>
    <ng-container *ngIf="cai !== 'NULL'">
        <div class="col-12 center pt-1" *ngIf="rec == 0">{{'CAI: '+cai}}</div>
        <div class="row" *ngIf="rec == 0">
            <div class="col-3">Rango Autorizado </div>
            <div class="col-3">{{startInv}}</div>
            <div class="col-3">A</div>
            <div class="col-3">{{endInv}}</div>
        </div>
        <div class="col-12 pb-1" *ngIf="rec == 0">{{'Fecha Limite Emision ' + dueDate | date:'medium'}}</div>
        <div class="spacer"></div>
    </ng-container>
    <div class="col-12 left pt-1" *ngIf="rec == 0">{{'Factura: ' + invoiceId}}</div>
    <div class="col-12 left pb-1">{{'Fecha: ' + invoiceDate | date:'medium'}}</div>
    <div class="col-12 left pb-1" *ngIf="rec == 1">{{'Mesa #' + mesaNo}}</div>
    <div class="spacer"></div>
    <div class="col-12" *ngIf="rec == 0">{{'RTN ' + taxId}}</div>
    <div class="col-12" *ngIf="rec == 0">{{'NOMBRE ' + customer}}</div>
    <div class="col-12 pb-1" *ngIf="rec == 0">{{'Dirección ' + address}}</div>
    <div class="spacer"></div>
    <div *ngFor="let item of factura">
        <div class="row">
            <div class="col-8 left">{{item.qty + ' ' + item.product}}</div>
            <div class="col-4 right">{{currency}}{{item.amount | number:'1.2-2'}}</div>
        </div>
    </div>
    <div class="col-12 pb-2"></div>
    <div class="row">
        <div class="col-6 left">Descuento </div>
        <div class="col-6 right">{{currency}}{{descuento | number:'1.2-2'}}</div>
    </div>
    <div class="row">
        <div class="col-6 left">Sub-Total </div>
        <div class="col-6 right">{{currency}}{{subTotal | number:'1.2-2'}}</div>
    </div>
    <div class="col-6 left"> Servicio:</div>
    <div class="col-6 right" *ngIf="rec == 0">{{tip}}</div>
    <div class="spacer mb-2"></div>
    <div class="col-12 pb-2"></div>
        <div class="row" *ngFor="let tax of taxes">
            <div class="col-6 left">{{tax.descripcion}}</div>
            <div class="col-6 right">{{currency}}{{tax.taxAmount | number:'1.2-2'}}</div>
        </div>
    <div class="col-12 pb-2"></div>
    <div class="row">
        <div class="col-6 left">Total a Pagar</div>
        <div class="col-6 right">{{currency}}{{totalAmount | number:'1.2-2'}}</div>
    </div>
    <div class="spacer mb-2"></div>
    <ng-container *ngIf="rec == 0">
        <div class="col-12 repeating-characters">
            <span class="text-previo">No. Correlativo exento: </span>
            <span class="underlines"></span>
        </div>
        <div class="col-12 repeating-characters">
            <span class="text-previo">No. Correlativo exonerado: </span>
            <span class="underlines"></span>
        </div>
        <div class="col-12 repeating-characters">
            <span class="text-previo">No. Registro S.A.G: </span>
            <span class="underlines"></span>
        </div>
        <div class="col-12 pb-2"></div>
        <div class="col-12 left">ORIGINAL: CLIENTE</div>
        <div class="col-12 left">COPIA: Obligado Tributario Emisor</div>
    </ng-container>
    <ng-container *ngIf="rec == 1">
        <div class="col-12 pb-2"></div>
        <div class="col-12 left"> RTN: {{taxId}}</div>
        <div class="spacer-datos mb-2"></div>
        <div class="col-12 left"> NOMBRE: {{customer}}</div>
        <div class="spacer-datos mb-2"></div>
        <div class="col-12 left"> DIRECCION: {{address}}</div>
        <div class="spacer-datos mb-2"></div>
    </ng-container>
    <div class="col-12 center">Agredecemos su Visita</div>
</div>