<ng-container *ngIf="shared$ | async"></ng-container>
<ng-container *ngIf="delete$ | async"></ng-container>
<div mat-dialog-content class="dialog-content pb-0">
    <!-- <div class="row">
        <div class="col-md-8"> -->
            <mat-form-field appearance="outline">
                <mat-label>Buscar</mat-label>
                <input matInput placeholder="Buscar" [(ngModel)]="search" cdkFocusInitial>
                <mat-icon matSuffix class="search-icon" (click)="sendFilter()">search</mat-icon>
            </mat-form-field>
        <!-- </div>
    </div> -->
    <mat-card class="card-table py-0">
        <mat-table matSort [dataSource]="dataSource" [trackBy]="trackRow">
            <ng-container *ngFor="let item of titles; let i = index">
                <ng-container *ngIf="i > 0; else action">
                    <ng-container [matColumnDef]="displayedColumns[i]" [sticky]="i == 1">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="title-cell header-cell">{{titles[i]}}</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="content-cell">{{row[displayedColumns[i]]}}</mat-cell>
                    </ng-container>
                </ng-container>
                <ng-template #action>
                    <ng-container [matColumnDef]="displayedColumns[i]" sticky>
                        <mat-header-cell *matHeaderCellDef class="title-cell max-w-actions">{{titles[i]}}</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="max-w-actions">
                            <button mat-icon-button (click)="onSelect(row)" class="color-done" matTooltip="Seleccionar"><mat-icon>done</mat-icon></button>
                            <button *ngIf="delete" mat-icon-button (click)="onDelete(row)" ><mat-icon>delete_forever</mat-icon></button>
                        </mat-cell>
                    </ng-container>
                </ng-template>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div class="no-data my-5" *ngIf="length == 0">No existen registros en su búsqueda<br/>cambie los datos de su filtro</div>
        <mat-paginator #paginator class="mat-paginator-sticky"
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="goToPage($event.pageIndex, $event.pageSize)">
        </mat-paginator>
    </mat-card>
</div>
<mat-dialog-actions align="end">
    <button mat-button color="warn" [mat-dialog-close]="" class="action-closed">Cerrar</button>
</mat-dialog-actions>