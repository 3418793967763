import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompaniesService } from '@app/services';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from '@app/shared';
import { AuthService } from '@app/core/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfirmValidParentMatcher } from '@app/validators';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;

  cats$: Observable<any>;
  delCat$: Observable<any>;
  companyId: string = '';

  length: number = -1;
  pageSize: number = 5;
  _page: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  displayedColumns: string[] = ['DESCRIPTION', 'ORDER_CAT', 'STATUS', 'ACTIONS'];

  confirmValidParentMatcher = new ConfirmValidParentMatcher();
  
  constructor(
    private companiesService: CompaniesService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private spinnerService: SpinnerService
  ) { }

  catsForm = this.fb.group({
    categoryId: [''],
    description: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]],
    orderCat: ['', [Validators.max(999), Validators.min(1)]],
    color: ['#000000'],
    allowDiscount: [''],
    status: [true],
    companyId: ['']
  })

  get cats(){
    return this.catsForm.controls;
  }

  ngOnInit(): void {
    this.companyId = this.authService.getCompanyId();

    this._page = 0;
    this.loadCategories(this._page);
  }

  delCat(row: any){

  }

  save(){
    this.catsForm.markAllAsTouched();
    if(!this.catsForm.valid) { return; }
    let formData = {
      categoryId: (this.catsForm.value.categoryId == '' ? 0 : this.catsForm.value.categoryId),
      description: this.catsForm.value.description,
      orderCat: this.catsForm.value.orderCat,
      color: this.catsForm.value.color,
      allowDiscount: this.catsForm.value.allowDiscount,
      status: this.catsForm.value.status,
      companyId: this.companyId
    }
    let exito: string = 'Categoría actualizada con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.cats$ = this.companiesService.postCategory(formData).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          if(this.catsForm.value.categoryId == ''){
            this.length = this.length+1;
            this.catsForm.patchValue({categoryId: res.Data.CATEGORY_ID});
            let dt: any = this.dataSource.data;
            dt.push(res.Data);
            this.dataSource.data = null;
            this.dataSource.data = dt;
            this.dataSource.sort = this.sort;
          } else {
            let dt: any = this.dataSource.data;
            let result = dt.filter(x => x.CATEGORY_ID == this.catsForm.value.categoryId);
            if(result.length > 0){
              result[0].DESCRIPTION = res.Data.DESCRIPTION;
              result[0].ORDER_CAT = res.Data.ORDER_CAT;
              result[0].COLOR = res.Data.COLOR;
              result[0].ALLOW_DISCOUNT = res.Data.ALLOW_DISCOUNT;
              result[0].STATUS = res.Data.STATUS;
            }
          }
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
        } else {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
        return res.Data;
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    );
  }

  changeSelected(cat: any){
    this.catsForm.patchValue({
      categoryId: cat.CATEGORY_ID,
      description: cat.DESCRIPTION,
      orderCat: cat.ORDER_CAT,
      color: cat.COLOR,
      allowDiscount: cat.ALLOW_DISCOUNT,
      status: cat.STATUS
    });
  }

  clear(){
    this.catsForm.reset({categoryId: '', description: '', orderCat: '', color: '#000000', allowDiscount: '', status: true, companyId: ''});
  }

  loadCategories(curPage: number){
    var spinnerRef = this.spinnerService.start();
    this.cats$ = this.companiesService.getCategories(this.companyId, curPage, this.pageSize).pipe(
      map((res: any) => {
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.dataSource.data = res.Data;
          this.dataSource.sort = this.sort;
          if(res.Data.length > 0){
            this.length = res.Data[0].TOTAL;
          } else {
            this.length = 0;
          }
          return res.Data;
        }
      }),
      catchError(err => {
        console.log(err);
        return err;
      })
    );
  }

  goToPage(page: number, elements: number){
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    this.loadCategories((this._page == 0 ? 0 : this._page*this.pageSize));
  }

  getErrorMessage(frm: FormGroup, component: string, lbl: HTMLElement, minlength: number, maxlength: number, min: number, max: number){
    let label: string = lbl.innerText;
    let requerido = ' es requerido';
    let minLen = 'Largo mínimo requerido ';
    let maxLen = 'Largo máximo requerido ';
    let minVal = 'Valor mínimo requerido ';
    let maxVal = 'Valor máximo requerido ';
    let invAddr = 'La dirección no es válida';
    let email = 'Formato de correo electrónico inválido';
    return frm.controls[component].hasError('required') ? label + requerido: 
      frm.controls[component].hasError('minlength') ? minLen + minlength:
        frm.controls[component].hasError('maxlength') ? maxLen + maxlength:
          frm.controls[component].hasError('min') ? minVal + min:
            frm.controls[component].hasError('max') ? maxVal + max:
              frm.controls[component].hasError('email') ? email:
                frm.controls[component].hasError('invalidAddress') ? invAddr:
                  '';
  }

}
