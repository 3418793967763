import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly apiURL = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getUser(usuarioId:any){
    return this.http.get(this.apiURL + 'user/' + usuarioId)
                    .pipe(catchError(this.errorHandler));
  }

  delUser(usuarioId:any, frmData:any){
    return this.http.post(this.apiURL + 'user/del/' + usuarioId, frmData)
                    .pipe(catchError(this.errorHandler));
  }

  getUsers(companyId:any, offset:any, limit:any, texto:any){
    return this.http.get(this.apiURL + 'users/' + companyId + '/' + offset + '/'+ limit + '/' + texto)
                    .pipe(catchError(this.errorHandler));
  }

  postUser(usuarioId:any, frmData:any){
    return this.http.post(this.apiURL + 'user/' + usuarioId, frmData)
                    .pipe(catchError(this.errorHandler));
  }

  getAccesos(rolId:any){
    return this.http.get(this.apiURL + 'access/' + rolId)
                    .pipe(catchError(this.errorHandler));
  }

  getRoles(){
    return this.http.get(this.apiURL + 'roles')
                    .pipe(catchError(this.errorHandler));
  }

  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
  
}
