<ng-container *ngIf="productos$ | async"></ng-container>
<ng-container *ngIf="dialog$ | async"></ng-container>
<div class="card-productos">
    <div class="row ms-1">
        <section class="sticky-num">
            <div class="col-md-auto mx-2 p-0">
                <div class="row h-cant" *ngFor="let cant of cantidad">
                    <div class="col-md-12 mb-1 btn-cant" (click)="cantSel = cant" [ngStyle]="{'background-color': cant == cantSel ? '#d7d7d7': '#fff' }">
                        {{cant}}
                    </div>
                </div>
                <div class="row h-cant">
                    <div class="col-md-12 mb-1 btn-cant" (click)="openDialog()" [ngStyle]="{'background-color': cantSel >= 10 ? '#d7d7d7': '#fff' }">
                        {{(cantSel >= 10 ? cantSel : '...')}}
                    </div>
                </div>
            </div>
        </section>
        <div class="col-md">
            <section class="sticky-prods px-2">
                <ng-container *ngFor="let cat of productos$ | async">
                    <div class="row my-2">
                        <span class="col-md-12 ps-2 info-orden">{{cat.categoria}}</span>
                    </div>
                    <div class="row">
                        <div class="col-md-auto mb-3 ps-3 pe-1" (click)="agregarProducto(producto)" *ngFor="let producto of cat.productos">
                            <div class="p-1 card-producto" [ngClass]="{'disabled-card': getDisabled(producto.INVENTORY, producto.QTY)}" [ngStyle]="{'box-shadow': !getDisabled(producto.INVENTORY, producto.QTY)? '-7px 0px 0px 0px ' + cat.color: '-7px 0px 0px 0px #d7d7d7'}">
                                <div class="product-content p-1 mb-0">
                                    <div class="nombre-prod">
                                        {{producto.NAME}}
                                    </div>
                                </div>
                                <div class="product-actions pe-1 float-end">
                                    <mat-icon *ngIf="producto.VARIABLE != ''" class="icon-producto ms-1" matTooltip="Robot" svgIcon="robot"></mat-icon>
                                    <div *ngIf="producto.INVENTORY && producto.QTY > 0" [ngClass]="{'back-enabled': !getDisabled(producto.INVENTORY, producto.QTY), 'back-disabled': getDisabled(producto.INVENTORY, producto.QTY)}" class="inventario ms-1" matTooltip="Inventario">
                                        <span class="inv-cant">{{producto.QTY}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </section>
        </div>
    </div>
</div>