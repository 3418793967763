import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/core/services';
import { GlobalService, CompaniesService, InvoicesService, OrdersService } from '@app/services';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface InvoiceData{
  orderId: string;
  recibo: number;
}

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.scss']
})
export class PrintInvoiceComponent implements OnInit {
  order: string = '';
  rec: number;

  print$: Observable<any>;
  invoice$: Observable<any>;
  factura: [] = [];
  taxes: [] = [];

  orden: number = 0;
  businessName: string;
  cAddress: string;
  cai: string;
  cRTN: string;
  currency: string;
  startInv: string;
  endInv: string;
  invoiceId: string;
  startDate: string;
  dueDate: string;
  invoiceDate: Date = new Date();
  totalAmount: number;
  totalTax: string;
  tip: string;
  customer: string;
  rtn: string;
  address: string;
  subTotal: number;
  descuento: number;
  mesaNo: number;
  user:any;
  constructor(
    private global: GlobalService,
    private ordersService: OrdersService,
    private invoicesService: InvoicesService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<PrintInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvoiceData
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserProfile();
    this.businessName = this.user.BUSINESS_NAME;
    this.cAddress = this.user.ADDRESS;
    this.cai = this.user.CAI;
    this.cRTN = this.user.RTN;
    this.currency = this.user.currency;
    this.startInv = this.user.START_INVOICE;
    this.endInv = this.user.END_INVOICE;
    this.startDate = this.user.START_DATE;
    this.dueDate = this.user.DUE_DATE;

    if(this.data.recibo == 0){
      this.getInvoice(this.data.orderId);
    }else{
      this.getData(this.data.orderId);
    }
  }

  getData(ord: any){
    this.invoice$ = this.ordersService.getOrder(ord, 0).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.orden = res.Data.sequence;
          this.mesaNo = res.Data.tableNo;
          this.subTotal = (res.Data.totalAmount-res.Data.totalTax);
          this.totalAmount = (res.Data.totalAmount - res.Data.totalDiscount);
          this.totalTax = res.Data.totalTax;
          this.descuento = res.Data.totalDiscount;
          this.invoiceDate = new Date(res.Data.orderDate.replace('T', ' ').replace('Z', ''));
          this.factura = res.Data.detail;
          return res.Data;
        }
      }),
      switchMap((x: any) => x =  this.global.printOrder(ord)),
      map((res: any)=> {
        if (res.Status == 200){
          window.print();
          return res.Data;
        }
      }) 
    );
  }

  getInvoice(invId:any){
    this.invoice$ = this.invoicesService.getInvoice(invId).pipe(
      map((res: any) => {
        if (res.Status == 200){
          this.subTotal = (res.Data.totalAmount-res.Data.totalTax);
          this.totalAmount = (res.Data.totalAmount - res.Data.totalDiscount);
          this.totalTax = res.Data.totalTax;
          this.descuento = res.Data.totalDiscount;
          this.invoiceDate = new Date(res.Data.invoiceDate.replace('T', ' ').replace('Z', ''));
          this.rtn = res.Data.rtn,
          this.customer = res.Data.nombre,
          this.address = res.Data.direccion,
          this.invoiceId = res.Data.numeroInvoice
          this.factura = res.Data.detail;
          return res.Data;
        }
      }),
      switchMap((x: any) => x =  this.invoicesService.printInvoice(invId)),
      map((res: any)=> {
        if (res.Status == 200){
          var Count = 1;
          while (Count <= 2){
            window.print();
            Count++;
          }
          return res.Data;
        }
      })
    );
  }
  
  doPrint() {
    this.print$ = this.invoicesService.printInvoice(this.order).pipe(
      map((res: any)=> {
        if (res.Status == 200){
          var Count = 1;
          while (Count <= 2){
            window.print();
            Count++;
          }
          return res.Data;
        }
      })
    );
  }

}
