import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-permiso-admin',
  templateUrl: './permiso-admin.component.html',
  styleUrls: ['./permiso-admin.component.scss']
})
export class PermisoAdminComponent implements OnInit {
  isAdmin$: Observable<any>;
  constructor(
    private fb: FormBuilder,
    private global: GlobalService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<PermisoAdminComponent>
  ) { }

  userForm = this.fb.group({
    usuario: '',
    password: ''
  });

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  onSubmit(){
    let frmData = {
      user: this.userForm.get('usuario')?.value,
      password: this.userForm.get('password')?.value
    }
    this.isAdmin$ = this.global.getAdmin(frmData).pipe(
      map((res:any)=>{
        if(res.Status ==200){
          if (res.Data.IS_ADMIN == 1){
            this.dialogRef.close({isAdmin: res.Data.IS_ADMIN});
            this._snackBar.open('Administrador Correcto', 'OK', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
              panelClass: ['success']
            });
          } else {
            this._snackBar.open('Usuario Incorrecto', 'OK', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
              panelClass: ['cancel']
            });
          }
          return res.Data;
        }else{
          this._snackBar.open('Usuario Incorrecto', 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['cancel']
          });
        }
      })
    )
  }
}
