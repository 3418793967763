import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/services';
import { GlobalService } from '@app/services';
import { UsersService } from '@app/services/users.service';
import { SpinnerService } from '@app/shared';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  roles$!: Observable<any>;
  user$!: Observable<any>;
  userNew$!: Observable<any>;
  
  usuarioId: number = 0;
  companyId!: number;
  reqPswd: boolean = true;

  userForm = this.fb.group({
    usuarioId: '',
    nombre: '',
    apellido: '',
    usuario: ['',[Validators.required]],
    password: [''],
    confPassword: [''],
    rolId: ['',[Validators.required]],
    rol: '',
    status: true
  });

  constructor(
    private fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private global: GlobalService,
    private usersService: UsersService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.usuarioId = Number(this.route.snapshot.params.userId);
    this.companyId = this.authService.getUserProfile().companyId;
    this.global.setUser({userId: this.usuarioId});

    if(this.usuarioId != 0){
      this.loadData(this.usuarioId);
    }
    this.loadRoles();
  }

  get fUser(){ 
    return this.userForm.controls;
  }

  loadRoles(){
    this.roles$ = this.usersService.getRoles().pipe(
      map((res:any)=> {
        if(res.Status = 200){
          return res.Data;
        }
      })
    )
  }

  newUser(){
    this.usuarioId = 0;
    this.global.setUser({userId: this.usuarioId});
    this.userForm.reset();
  }

  loadData(usuarioId:number){
    var spinnerRef = this.spinnerService.start();
    this.user$ = this.usersService.getUser(usuarioId).pipe(
      map((res:any)=> {
        this.spinnerService.stop(spinnerRef);
        if(res.Status = 200){
          this.userForm.patchValue({
            usuarioId: res.Data.USER_ID,
            nombre: res.Data.NOMBRE,
            apellido: res.Data.APELLIDO,
            usuario: res.Data.USERNAME,
            rolId: res.Data.ROL_ID,
            rol: res.Data.DESCRIPCION,
            status: res.Data.STATUS
          });
          this.reqPswd = false;
          return res.Data;
        }
      })
    )
  }

  onSubmit(){
    if (!this.userForm.valid){
      return;
    }

    let frmData = {
      usuario: this.userForm.value.usuario,
      nombre: this.userForm.value.nombre,
      apellido: this.userForm.value.apellido,
      password: this.userForm.value.password,
      rolId: this.userForm.value.rolId,
      status: this.userForm.value.status == false ? 0 : 1,
      companyId: this.companyId
    }
    let exito: string = 'Usuario ' + (this.usuarioId == 0 ? 'creado' : 'actualizado') + ' con éxito';
    let fallo: string = 'Ocurrió un error intente de nuevo';
    var spinnerRef = this.spinnerService.start('P');
    this.userNew$ = this.usersService.postUser(this.usuarioId, frmData).pipe(
      map((res:any)=> {
        this.spinnerService.stop(spinnerRef);
        if(res.Status == 200){
          this.userForm.patchValue({
            usuarioId: res.Data
          });
          this._snackBar.open(exito, 'OK', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: ['success']
          });
        }
        if (res.Status == 402){
          this._snackBar.open('Usuario ya existe', 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        } 
        if (res.Status != 402 && res.Status != 200) {
          this._snackBar.open(fallo, 'Cancelar', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 7000,
            panelClass: ['cancel']
          });
        }
        return res.Data;
      }), 
      catchError(err => {
        this.spinnerService.stop(spinnerRef);
        this._snackBar.open(fallo, 'Cancelar', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 7000,
          panelClass: ['cancel']
        });
        return err;
      })
    );
  }

  getErrorMessage(component: string, label: string, minlength: number, maxlength: number, min: number, max: number){
    return this.userForm.get(component)?.hasError('required') ? label + ' es requerido' : 
      this.userForm.get(component)?.hasError('minlength') ? 'Largo mínimo requerido ' + minlength:
        this.userForm.get(component)?.hasError('maxlength') ? 'Largo máximo requerido ' + maxlength:
          this.userForm.get(component)?.hasError('min') ? 'Valor mínimo requerido ' + min:
            this.userForm.get(component)?.hasError('max') ? 'Valor máximo requerido ' + max:
              this.userForm.get(component)?.hasError('email') ? 'Formato de correo electrónico inválido':
                '';
  }

}
