import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  readonly apiURL = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getProductABC(prodId: string){
    return this.http.get(this.apiURL + 'product/'+prodId)
                    .pipe(catchError(this.errorHandler));
  }

  getDailySales(companyId: number, startDate: string, endDate: string, limit: number, offset: number){
    return this.http.get(this.apiURL + 'report/sales/'+companyId+'/'+startDate+'/'+endDate+'/'+limit+'/'+offset)
                    .pipe(catchError(this.errorHandler));
  }

  errorHandler(error) {
    return throwError(error || 'Server Error');
  }
  
}
