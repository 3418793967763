<div class="container">
    <ng-container *ngIf="entrega$ | async"></ng-container>
    <ng-container *ngIf="entregado$ | async"></ng-container>
    <div class="row h-20 pb-2">
        <div class="main-inactive">
            <div *ngIf="lstTable != ''">Ultima Mesa - {{lstTable}}</div>
        </div>
    </div>
    <div class="row h-30 pb-2">
        <div class="main col-6 h-100 d-table align-middle mr-3 mb-3">
            <div class="label-hora">Orden Lista</div>
            <div class="table">Mesa - {{table}}</div>
            <div class="table">Orden - {{orden.toString().padStart(5, '0')}}</div>
            <div class="table">Cliente - {{cust}}</div>
            <div class="entrega-btn" *ngIf="table">
                <button mat-flat-button class="btn-w" id="save" color="accent" type="submit" text-center w-100 (click)="setEntrega(order, table)">LISTA</button>
            </div>
        </div>
        <div class="main col-6 h-100 d-table align-middle mb-3" *ngIf="order2 > 0">
            <div class="label-hora">Orden Lista</div>
            <div class="table">Mesa - {{table2}}</div>
            <div class="table">Orden - {{orden2.toString().padStart(5, '0')}}</div>
            <div class="table">Cliente - {{cust2}}</div>
            <div class="entrega-btn">
                <button mat-flat-button class="btn-w" id="save" color="accent" type="submit" text-center w-100 (click)="setEntrega(order2, table2)">LISTA</button>
            </div>
        </div>
    </div>
    <div class="row h-30 pb-2">
        <div class="main col-6 h-100 d-table align-middle mr-3 mt-3" *ngIf="order3 > 0">
            <div class="label-hora">Orden Lista</div>
            <div class="table">Mesa - {{table3}}</div>
            <div class="table">Orden - {{orden3.toString().padStart(5, '0')}}</div>
            <div class="table">Cliente - {{cust3}}</div>
            <div class="entrega-btn">
                <button mat-flat-button class="btn-w" id="save" color="accent" type="submit" text-center w-100 (click)="setEntrega(order3, table3)">LISTA</button>
            </div>
        </div>
        <div class="main col-6 h-100 d-table align-middle mt-3" *ngIf="order4 > 0">
            <div class="label-hora">Orden Lista</div>
            <div class="table">Mesa - {{table4}}</div>
            <div class="table">Orden - {{orden4.toString().padStart(5, '0')}}</div>
            <div class="table">Cliente - {{cust4}}</div>
            <div class="entrega-btn">
                <button mat-flat-button class="btn-w" id="save" color="accent" type="submit" text-center w-100 (click)="setEntrega(order4, table4)">LISTA</button>
            </div>
        </div>
    </div>
    <div class="row h-20 pb-2"></div>
</div>