<ng-container *ngIf="invoice$ | async"></ng-container>
<ng-container *ngIf="print$ | async"></ng-container>
<div class="non-print container-fluid">
    <div class="col-12 center mb-2 numorden" *ngIf="rec !=1">{{orden.toString().padStart(5, '0')}}</div>
    <div class="col-12 pb-2 center" *ngIf="rec ==1">
        <img src="/assets/images/logo.svg" class="img-02">
    </div>
    <div class="col-12 center">{{businessName}}</div>
    <div class="col-12 center">{{cAddress}}</div>
    <div class="col-12 center pb-1">{{'RTN: ' +cRTN}}</div>
    <div class="spacer"></div>
    <div class="col-12 center pt-1" *ngIf="rec ==1">{{'CAI: '+cai}}</div>
    <div class="row" *ngIf="rec ==1">
        <div class="col-6 left">Rango Autorizado </div>
        <div class="col-6">{{startInv}}</div>
    </div>
    <div class="row" *ngIf="rec ==1">
        <div class="col-6 right">A</div>
        <div class="col-6">{{endInv}}</div>
    </div>
    <div class="col-12 pb-1" *ngIf="rec ==1">{{'Fecha Limite Emision ' + dueDate}}</div>
    <div class="spacer" *ngIf="rec ==1"></div>
    <div class="col-12 left pt-1" *ngIf="rec ==1">{{'Factura: ' + invoiceId}}</div>
    <div class="col-12 left pb-1">{{'Fecha: ' + invoiceDate | date:'medium'}}</div>
    <div class="col-12 left pb-1" *ngIf="rec !=1">{{'Mesa #' + mesaNo}}</div>
    <div class="spacer"></div>
    <div class="col-12 pt-2"></div>
    <div *ngFor="let item of factura">
        <div class="row">
            <div class="col-9 left">{{item.qty + ' ' + item.product}}</div>
            <div class="col-3 right">{{currency}}{{item.amount | number:'1.2-2'}}</div>
        </div>
    </div>
    <div class="col-12 pb-2"></div>
    <div class="row">
        <div class="col-6 left">Sub-Total </div>
        <div class="col-6 right">{{currency}}{{subTotal | number:'1.2-2'}}</div>
    </div>
    <div class="row">
        <div class="col-6 left">Descuento </div>
        <div class="col-6 right">{{currency}}{{descuento | number:'1.2-2'}}</div>
    </div>
    <div class="col-12 pb-2"></div>
    <ng-container>
        <div *ngFor="let tax of taxes">
            <div class="row">
                <div class="col-6 left">{{tax.DESCRIPTION_GRAV}}</div>
                <div class="col-6 right">{{currency}}{{tax.BASE_AMOUNT | number:'1.2-2'}}</div>
            </div>
        </div>
        <div *ngFor="let tax of taxes">
            <div class="row">
                <div class="col-6 left">{{tax.DESCRIPTION_ESP}}</div>
                <div class="col-6 right">{{currency}}{{tax.TAX_AMOUNT | number:'1.2-2'}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 left">Total Impuesto</div>
            <div class="col-6 right">{{currency}}{{totalTax | number:'1.2-2'}}</div>
        </div>
    </ng-container>
    <div class="col-12 pb-2"></div>
    <div class="row mb-2">
        <div class="col-6 left">Total a Pagar</div>
        <div class="col-6 right">{{currency}}{{totalAmount | number:'1.2-2'}}</div>
    </div>
    <div class="col-12 left"> PROPINA:</div>
    <div class="spacer-datos mb-2"></div>
    <div class="col-12 pb-2"></div>
    <div class="col-12 left" *ngIf="rec ==1">ORIGINAL: CLIENTE
    </div>
    <div class="col-12 left" *ngIf="rec ==1">COPIA: Obligado Tributario Emisor</div>
    <div class="col-12 left"> RTN: {{rtn}}</div>
    <div class="spacer-datos mb-2"></div>
    <div class="col-12 left"> NOMBRE: {{customer}}</div>
    <div class="spacer-datos mb-2"></div>
    <div class="col-12 left"> DIRECCION: {{address}}</div>
    <div class="spacer-datos mb-2"></div>
    <div class="col-12 center">Agredecemos su Visita</div>
</div>
<div class="print-area container-fluid">
    <div class="col-12 center mb-2 numorden">{{orden.toString().padStart(5, '0')}}</div>
    <!-- <div class="col-12 pb-2 center">
        <img src="/assets/images/logo.svg" class="img-02">
    </div> -->
    <div class="col-12 center">{{businessName}}</div>
    <div class="col-12 center">{{cAddress}}</div>
    <div class="col-12 center pb-1">{{'RTN: ' +cRTN}}</div>
    <div class="spacer"></div>
    <div class="col-12 center pt-1" *ngIf="rec ==1">{{'CAI: '+cai}}</div>
    <div class="row" *ngIf="rec ==1">
        <div class="col-6 left">Rango Autorizado </div>
        <div class="col-6">{{startInv}}</div>
    </div>
    <div class="row" *ngIf="rec ==1">
        <div class="col-6 right">A</div>
        <div class="col-6">{{endInv}}</div>
    </div>
    <div class="col-12 pb-1" *ngIf="rec ==1">{{'Fecha Limite Emision ' + dueDate}}</div>
    <div class="spacer"></div>
    <div class="col-12 left pt-1" *ngIf="rec ==1">{{'Factura: ' + invoiceId}}</div>
    <div class="col-12 left pb-1">{{'Fecha: ' + invoiceDate | date:'medium'}}</div>
    <div class="spacer"></div>
    <div class="col-12" *ngIf="rec ==1">{{'RTN ' + rtn}}</div>
    <div class="col-12 pb-1" *ngIf="rec ==1">{{'Dirección ' + address}}</div>
    <div class="spacer"></div>
    <div *ngFor="let item of factura">
        <div class="row">
            <div class="col-8 left">{{item.qty + ' ' + item.product}}</div>
            <div class="col-4 right">{{currency}}{{item.amount | number:'1.2-2'}}</div>
        </div>
    </div>
    <div class="col-12 pb-2"></div>
    <div class="row">
        <div class="col-6 left">Sub-Total </div>
        <div class="col-6 right">{{currency}}{{subTotal | number:'1.2-2'}}</div>
    </div>
    <div class="row">
        <div class="col-6 left">Descuento </div>
        <div class="col-6 right">{{currency}}{{descuento | number:'1.2-2'}}</div>
    </div>
    <div class="col-12 left"> PROPINA:</div>
    <div class="spacer mb-2"></div>
    <div class="col-12 pb-2"></div>
    <ng-container *ngIf="rec ==1">
        <div *ngFor="let tax of taxes">
            <div class="row">
                <div class="col-6 left">{{tax.DESCRIPTION_GRAV}}</div>
                <div class="col-6 right">{{currency}}{{tax.BASE_AMOUNT | number:'1.2-2'}}</div>
            </div>
        </div>
        <div *ngFor="let tax of taxes">
            <div class="row">
                <div class="col-6 left">{{tax.DESCRIPTION_ESP}}</div>
                <div class="col-6 right">{{currency}}{{tax.TAX_AMOUNT | number:'1.2-2'}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 left">Total Impuesto</div>
            <div class="col-6 right">{{currency}}{{totalTax | number:'1.2-2'}}</div>
        </div>
    </ng-container>
    <div class="col-12 pb-2"></div>
    <div class="row">
        <div class="col-6 left">Servicio</div>
        <div class="col-6 right">{{tip | number:'1.2-2'}}</div>
    </div>
    <div class="row">
        <div class="col-6 left">Total a Pagar</div>
        <div class="col-6 right">{{currency}}{{totalAmount | number:'1.2-2'}}</div>
    </div>
    <div class="col-12 pb-2"></div>
    <div class="col-12 left" *ngIf="rec ==1">ORIGINAL: CLIENTE
    </div>
    <div class="col-12 left" *ngIf="rec ==1">COPIA: Obligado Tributario Emisor</div>
    <div class="col-12 pb-2"></div>
    <div class="col-12 left"> RTN:</div>
    <div class="spacer"></div>
    <div class="col-12 left"> NOMBRE:</div>
    <div class="spacer"></div>
    <div class="col-12 left"> DIRECCION:</div>
    <div class="spacer"></div>
    <div class="col-12 center">Agredecemos su Visita</div>
</div>
<div mat-dialog-actions align="end" class="non-print">
    <button mat-flat-button color="primary" (click)="doPrint()">Print</button>
</div>