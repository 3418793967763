import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@app/core/services';
import { UsersService } from '@app/services/users.service';
import { SpinnerService } from '@app/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UserslistComponent implements OnInit {
  users$!: Observable<any>;
  delUser$!: Observable<any>;

  companyId!: number;

  dataSource = new MatTableDataSource<any>();
  lengthTabla: number = 0;
  pageSize: number = 5;
  _page: number = 0;
  texto: string = "";
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  displayedColumns: string[] = ['usuario', 'nombre', 'rol', 'status', 'actions'];

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    this.companyId = this.authService.getUserProfile().companyId;
    this.loadData(0,0);
  }

  loadData(curPage:number, texto:any){
    var spinnerRef = this.spinnerService.start();
    this.users$ = this.usersService.getUsers(this.companyId, curPage, this.pageSize, texto).pipe(
      map((res:any) =>{
        this.spinnerService.stop(spinnerRef);
        if (res.Status == 200){
          this.dataSource.data = res.Data;
          this.lengthTabla = res.Data[0].TOTAL;
        }
      })
    )
  }

  goToPage(page: number, elements: number){
    if (this.pageSize != elements){
      this.pageSize = elements;
    } 
    this._page = page;
    this.loadData((this._page == 0 ? 0 : this._page*this.pageSize), 0);
  }

  searchData(){
    this._page = 0;
    if(this.texto != ''){
      this.loadData(this._page, this.texto);
    }else{
      this.loadData(this._page, '0');
    }
  }

  refreshSearch(){
    this._page = 0;
    this.texto = '';
    this.loadData(this._page, '0');
  }

  delUsuario(usuarioId:any, status:number){
    let frmData = {
      status: status
    }
    this.delUser$ = this.usersService.delUser(usuarioId, frmData).pipe(
      map((res: any) =>{
        if (res.Status == 200){
          let usuario = this.dataSource.data.filter(x => x.USER_ID == usuarioId);
          if(usuario.length >0){
            usuario[0].STATUS = status;
          }
        }
      })
    )
  }

}
